import React, { useEffect, useState, useCallback } from 'react'
import 'react-toastify/dist/ReactToastify.css';
import logo from '../../assets/images/Ed.svg';
import {
  Switch,
  Route,
  Link
} from "react-router-dom";
import {Menu, Image, Segment, Container, Grid, Icon, Sidebar, Modal, Button, Dropdown} from 'semantic-ui-react';
import ContactPage from './Contact';
import HomePage from './Home';
import "../../style.scss";
import { ToastContainer } from 'react-toastify';
import {PrivacyPolicy} from '../../components/PrivacyPolicy'
import {FAQ} from '../../components/FAQ';
import {TOC} from '../../components/TOC';
import ScrollToTop from '../../components/ScrollToTop';
import LastOneStandingPage from './1ls/LastOneStanding';
import LastOneStandingJourney from './1ls/LastOneStandingJourney';
import NotFound from '../../components/NotFound';
import EventPage from './Event';
import ReactGA from 'react-ga'
import { useLocation } from 'react-router-dom';
import SELCPage from './selc/season4/SELC';
import SELCRegisterPage from './selc/season4/SELCRegister';
import RegisterPage from './Register';
import SELC5Page from './selc/season5/SELC5';
import SELC5LeaderboardPage from './selc/season5/SELC5_Leaderboard';

ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);

function usePageViews() {
  let location = useLocation();
  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [location]);
}

function App() {
  const [y, setY] = useState(window.scrollY);
  const [showGoTop, setShowGoTop] = useState(false)

  const handleNavigation = useCallback(
    e => {
      const window = e.currentTarget;
      if (y > window.scrollY) {
        if(window.scrollY > 500){
          setShowGoTop(true)
        }else{
          setShowGoTop(false)
        }
      } else if (y < window.scrollY) {
        if(window.scrollY > 500){
          setShowGoTop(true)
        }else{
          setShowGoTop(false)
        }
      }
      setY(window.scrollY);
    }, [y]
  );

  useEffect(() => {
    let path = window.location.pathname.split('/')
    if(path.length > 2){
      setPath(path[1]+'_'+path[2])
    }else{
      setPath(path[1] === "" ? "home" : path[1])
    }
    
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);
    return () => {
      window.removeEventListener("scroll", handleNavigation)
    };
  }, [handleNavigation])

  const [visible, setVisible] = useState(false)
  const [path, setPath] = useState('home')
  const [staticModal, setStaticModal] = useState(false)
  const [staticType, setStaticType] = useState('')

  

  const disableScroll = (param, path="") =>{
    if(path !== ""){
      setPath(path)
    }
    setVisible(param)
    if(param){
      document.body.style.overflow = 'hidden';
    }else{
      document.body.style.overflow = 'auto';
    }
  }

  const openStatic = (param, e) =>{
    setStaticType(param)
    setStaticModal(true)
  }

  usePageViews();

  return (
    <>
      {/* desktop menu list */}
      <Menu stackable inverted size="massive" fixed="top" id="menu_dekstop" borderless>
        <Link className="item" to="/" onClick={()=>disableScroll(false, 'home')}>
          <Image src={logo} size='mini'/> Home
        </Link>
        <Link className={path === "events" ? "item active" : "item"} to="/events" onClick={()=>disableScroll(false, 'events')}>Events</Link>
        <Dropdown simple item text='Challenges'>
          <Dropdown.Menu>
            <Dropdown.Item>
              <i className="right dropdown icon"></i>
              <span className='text'>Main Page</span>
              <Dropdown.Menu>
                <Link className={path === "lastonestanding" ? "item active" : "item"} to="/lastonestanding" onClick={()=>disableScroll(false, 'lastonestanding')}>Last One Standing</Link>
                <Link className={path === "selc5" ? "item active" : "item"} to="/selc5" onClick={()=>disableScroll(false, 'selc5')}>SELC</Link>
              </Dropdown.Menu>
            </Dropdown.Item>
            <Dropdown.Item>
              <i className="right dropdown icon"></i>
              <span className='text'>Leaderboard</span>
              <Dropdown.Menu>
                <Link className={path === "lastonestanding" ? "item active" : "item"} to="/lastonestanding" onClick={()=>disableScroll(false, 'lastonestanding')}>Last One Standing</Link>
                <Link className={path === "selc" ? "item active" : "item"} to="/selc" onClick={()=>disableScroll(false, 'selc')}>SELC 4</Link>
                <Link className={path === "leaderboard_selc5" ? "item active" : "item"} to="/leaderboard/selc5" onClick={()=>disableScroll(false, 'leaderboard_selc5')}>SELC 5</Link>
              </Dropdown.Menu>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <a rel="noreferrer" className="item" href="https://solveeducation.org/" target="_blank">About Solve Education!</a>
        <Link className={path === "contactus" ? "item active" : "item"} to="/contactus" onClick={()=>disableScroll(false, 'contactus')}>Contact Us</Link>
      </Menu>
      {/* end of desktop menu list */}

      {/* mobile menu burger */}
      <Menu inverted fixed="top" id="menu_mobile" style={{display: 'none'}}>
      <Link className="item" to="/"><Image src={logo}/></Link>
        <Menu.Item position="right">
          <Icon name="bars" link size="large" onClick={()=>disableScroll(true)}/>
        </Menu.Item>
      </Menu>
      {/* end of mobile menu burger */}

      <Sidebar.Pushable className="menu_m">
        {/* mobile nav menu */}
          <Sidebar
            as={Menu}
            animation='overlay'
            inverted
            onHide={() => disableScroll(false)}
            vertical
            visible={visible}
            direction="top"
          >
            <Icon name="times" link size="large" onClick={()=>disableScroll(false)}/>
            <Menu.Item><Image src={logo}/></Menu.Item>
            <Link className={path === "home" ? "item active" : "item"} to="/"onClick={()=>disableScroll(false, 'home')}>Home</Link>
            <Link className={path === "events" ? "item active" : "item"} to="/events"onClick={()=>disableScroll(false, 'events')}>Events</Link>
            <Menu.Item>
              <Menu.Header>Challenges</Menu.Header>
              <Menu.Menu>
                <Menu.Item>
                  <Menu.Header>Main Page</Menu.Header>
                  <Menu.Menu>
                    <Link className={path === "lastonestanding" ? "item active" : "item"} to="/lastonestanding" onClick={()=>disableScroll(false, 'lastonestanding')}>Last One Standing</Link>
                    <Link className={path === "selc5" ? "item active" : "item"} to="/selc5" onClick={()=>disableScroll(false, 'selc5')}>SELC</Link>
                  </Menu.Menu>
                </Menu.Item>
                <Menu.Item>
                  <Menu.Header>Leaderboard</Menu.Header>
                  <Menu.Menu>
                    <Link className={path === "lastonestanding" ? "item active" : "item"} to="/lastonestanding" onClick={()=>disableScroll(false, 'lastonestanding')}>Last One Standing</Link>
                    <Link className={path === "selc" ? "item active" : "item"} to="/selc" onClick={()=>disableScroll(false, 'selc')}>SELC</Link>
                    <Link className={path === "leaderboard_selc5" ? "item active" : "item"} to="/leaderboard/selc5" onClick={()=>disableScroll(false, 'leaderboard_selc5')}>SELC 5</Link>
                  </Menu.Menu>
                </Menu.Item>
              </Menu.Menu>
            </Menu.Item>
            <a rel="noreferrer" className="item" href="https://solveeducation.org/" target="_blank">About Solve Education!</a>
            <Link className={path === "contactus" ? "item active" : "item"} to="/contactus" onClick={()=>disableScroll(false, 'contactus')}>Contact Us</Link>
          </Sidebar>
          {/* end of mobile nav menu */}

          <Sidebar.Pusher dimmed={visible}>

            <ScrollToTop/>
            {/* internal router */}
            <Switch>
              <Route exact path="/events">
                <EventPage/>
              </Route>
              <Route exact path="/lastonestanding">
                <LastOneStandingPage/>
              </Route>
              <Route exact path="/lastonestandingjourney">
                <LastOneStandingJourney/>
              </Route>
              <Route exact path="/contactus">
                <ContactPage/>
              </Route>
              <Route exact path="/selc">
                <SELCPage/>
              </Route>
              <Route exact path="/selc/signup">
                <SELCRegisterPage/>
              </Route>
              <Route exact path="/register">
                <RegisterPage/>
              </Route>
              <Route exact path="/">
                <HomePage/>
              </Route>
              <Route exact path="/selc5">
                <SELC5Page/>
              </Route>
              <Route exact path="/leaderboard/:id">
                {path === 'leaderboard_selc5' ? 
                <SELC5LeaderboardPage/>
                : null}
              </Route>
              <Route path="*">
                <NotFound/>
              </Route>
          </Switch>
          {/* end of internal router */}

          {/* footer */}
          <Segment vertical className="footer1" textAlign="center">
            <Container>
              <Menu text>
                <Menu.Item onClick={openStatic.bind(null, 'privacy')}>Privacy Policy</Menu.Item>
                <Menu.Item onClick={openStatic.bind(null, 'toc')}>Terms and Condition</Menu.Item>
                <Menu.Item onClick={openStatic.bind(null, 'faq')}>FAQs</Menu.Item>
              </Menu>
            </Container>
          </Segment>
          <Segment inverted vertical className="footer2">
            <Container>
            <Grid columns={2}>
              <Grid.Column>
                <p className="copyright">
                  ©2021 Solve Education! | All Rights Reserved.
                </p>
              </Grid.Column>
              <Grid.Column textAlign="right">
                <Icon name="facebook" link size="big" onClick={()=>window.open("https://www.facebook.com/solveeducation/", "_blank")}/>
                <Icon name="instagram" link size="big" onClick={()=>window.open("https://www.instagram.com/solveeducation/", "_blank")}/>
                <Icon name="linkedin" link size="big" onClick={()=>window.open("https://www.linkedin.com/company/solve-education-", "_blank")}/>
              </Grid.Column>
            </Grid>
            </Container>
          </Segment>
          {/* end of rooter */}

          {showGoTop ? 
          <Button primary className="go_top" icon onClick={()=>{
            window.scroll({
              top: 0,
              behavior: 'smooth'
            });
          }}>
            <Icon name="arrow up"/>
          </Button>
          : null}
          
        </Sidebar.Pusher>
      </Sidebar.Pushable>

      <Modal open={staticModal} size="large" onClose={()=>setStaticModal(false)} closeIcon>
        <Modal.Content scrolling>
          <Segment basic>
            {
              staticType === 'faq' ? <FAQ path={path}/> : staticType === 'privacy' ? <PrivacyPolicy/> : <TOC/>
            }
          </Segment>
        </Modal.Content>
      </Modal>

      <ToastContainer
      position="bottom-left"
      autoClose={5000}
      hideProgressBar={false}
      closeOnClick={false}
      newestOnTop
      theme="colored"
      rtl={false}
      pauseOnFocusLoss={false}
      draggable={false}
      pauseOnHover={false}
      />
    </>
  );
}

export default App;
