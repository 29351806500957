import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { Dimmer, Header, Loader } from "semantic-ui-react"

const JoinPage = () =>{
    const [data, setData] = useState(null)
    const [counter, setCounter] = useState(3)
    var ct = 3
    const queryString = window.location.search
    let urlParam = new URLSearchParams(queryString);
    const referral = urlParam.get('referral')
    const source = urlParam.get('source')
    const ref = urlParam.get('ref')
    const cid = urlParam.get('cid')
    const pseudo_id = localStorage.getItem('pseudo_id')

    useEffect(()=>{
        getLink()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getLink = () =>{
        let urlParam = 'pseudo_id='+pseudo_id
        if(ref !== null){
            urlParam += '&ref='+ref
        }

        if(cid !== null){
            urlParam += '&cid='+cid
        }

        if(referral !== null){
            urlParam += '&referral='+referral
        }

        fetch(process.env.REACT_APP_API_URL+'edwebsite/getTeacherBot?'+urlParam)
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === 200){
                setData(res.data)
                goToLink(res.data)
            }
        })
        .catch((e)=>{
            toast.error('Failed fetching teacher link.')
        })
    }

    const goToLink = (param) =>{
        let time = setInterval(()=>{
            ct = ct-1
            setCounter(ct)
        }, 1000)

        
        setTimeout(()=>{
            clearInterval(time)
            let url = param.URL

            window.location.href = url
        }, 3000)
    }

    return(
        <Dimmer active id="joinLoader">
            {data !== null ? 
            <Header as="h1">You will be redirected to your Telegram teacher bot in {counter} second(s).</Header>
            : <Loader content={<p>Please Wait...</p>} size="massive"/>}
        </Dimmer>
    )
}

export default JoinPage