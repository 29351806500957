import React, {useEffect, useState, useRef} from 'react'
import { Button, Container, Dimmer, Divider, Dropdown, Grid, Header, Icon, Image, Loader, Modal, Segment, Table } from 'semantic-ui-react'
import { toast } from 'react-toastify';

function EventPage(){
    const [leaderboard, setLeaderboard] = useState([])
    const [leaderboardAll, setLeaderboardAll] = useState([])
    const [events, setEvents] = useState([])
    const [eventLoading, setEventLoading] = useState(true)
    const [leaderboardLoading, setLeaderboardLoading] = useState(true)
    const [isSeeAll, setIsSeeAll] = useState(false)
    const [selectedEvent, setSelectedEvent] = useState(0)
    const [eventData, setEventData] = useState([])
    const [timerComponents, setTimerComponents] = useState([])
    const launch = useRef();
    const currentEndDate = useRef()
    const loadingCounter = useRef()

    const startCountDown = () => {
        clearInterval(launch.current)
        launch.current = setInterval(calculateTimeLeft, 1000);
    };

    const pauseCountDown = () => {
        clearInterval(launch.current);
    };


    const calculateTimeLeft = () => {
        loadingCounter.current = false
        let difference = +currentEndDate.current - +new Date();
        let timeLeft = {};
    
        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };

            let tc = []

            Object.keys(timeLeft).forEach((interval) => {
                tc.push(
                    <Header as="h1" key={interval}>
                        {timeLeft[interval]}<span>{interval[0]}</span>
                    </Header>
                );
            });
            setTimerComponents(tc)
        }else{
            pauseCountDown()
            setTimerComponents([])
        }
    }
    
    useEffect(() => {
        loadEvents()
        return () => pauseCountDown()
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    const loadLeaderboard = (id) =>{
        setEventLoading(true)
        setLeaderboardLoading(true)
        fetch(process.env.REACT_APP_API_URL+'incentives/api/v1/event/'+id)
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            let data = res.data
            setLeaderboardAll(data.leaderboard)
            setLeaderboard(data.leaderboard.slice(0, 10))

            if(data.total_record > 10){
                setIsSeeAll(true)
            }else{
                setIsSeeAll(false)
            }
            setLeaderboardLoading(false)
            setEventLoading(false)
        })
        .catch((e)=>{
            toast.error('Failed fetching leaderboard data.')
        })
    }

    const loadEvents = () =>{
        loadingCounter.current = true
        fetch(process.env.REACT_APP_API_URL+'incentives/api/v1/events')
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            let data = res.data.events
            let event_dropdown = []
            data.forEach(x=>{
                let difference = +new Date(x.end*1000) - +new Date();
                let text = difference > 0 ? x.start_date+' - '+x.end_date+' (Active)' : x.start_date+' - '+x.end_date
                event_dropdown.push({ key: x.event_id, value: x.event_id, text: text })
            })

            setEventData(data)
            loadLeaderboard(event_dropdown[0].value)
            setEvents(event_dropdown)
            setSelectedEvent(event_dropdown[0].value)
            
            pauseCountDown()
            currentEndDate.current = new Date(data[0].end*1000)
            startCountDown()
        })
        .catch((e)=>{
            toast.error('Failed fetching event data.')
        })
    }

    const onChangeEvent = (e, data) =>{
        let eid = eventData.findIndex(x=>x.event_id === data.value)
        if(eid !== -1){
            pauseCountDown()
            currentEndDate.current = new Date(eventData[eid].end*1000)
            loadingCounter.current = true
            startCountDown()
            loadLeaderboard(data.value)
            setSelectedEvent(data.value)
        }else{
            toast.error('No event found.')
        }
    }

    return (
        <Container id="event_page">
            <section id="social_grant">
                <Segment basic textAlign="center">
                    <Header as="h1">
                    Ed Social Grant
                    </Header>
                    <Image src={require('../../assets/images/event_banner.svg').default} size="big" centered/>
                    <Header as="h3">Get paid for rallying votes and building soft skills. Over 4M invested in Nigerian youths so far! Take the PGL test today <a target={'_blank'} rel="noreferrer" href="https://ng.solveeducation.org/grantlead">here</a>!</Header>
                </Segment>
            </section>
            <section id="los">
                <Segment basic textAlign="center">
                    <Header as="h1">
                    Last One Standing (L1S)
                    </Header>
                    <Image src={require('../../assets/images/los_new.svg').default} size="big" centered/>
                    <Header as="h3">Be the LAST ONE STANDING & stand a chance of winning up to $1,000! Complete your L1S daily mission or you'll be eliminated from the event!</Header>
                    <Header as="h3">For more information, visit our <a href="/lastonestanding">Last One Standing page</a>.</Header>
                    <Header as="h3">Contact our <a href="https://t.me/Ed_Academy_Support">telegram support to sign up</a>!</Header>
                </Segment>
            </section>
            <section id="lucky_draw">
                <Segment basic textAlign="center">
                    <Header as="h1">
                    Lucky Draw
                    </Header>
                    <Image src={require('../../assets/images/Wheel.svg').default} size="big" centered/>
                    <Header as="h3">Play with Ed the learning bot to collect points! Use your points to redeem tickets and win exciting prizes weekly!</Header>
                    <Header as="h3">Join our <a href="http://t.me/learnnwin">telegram channel</a> to get started today!</Header>
                    <Divider hidden/>
                    <Divider hidden/>
                    <Divider hidden/>
                    <Image src={require('../../assets/images/lucky_draw_step.svg').default} size="big" centered/>
                </Segment>
            </section>
            <section id="charity">
                <Segment basic textAlign="center">
                    <Header as="h1">
                    Ed for Charity
                    </Header>
                    <Image src={require('../../assets/images/charity.svg').default} size="big" centered/>
                    <Header as="h3">Raise money for programs, social causes, and events that you're most passionate about by rallying votes!</Header>
                    <Header as="h3">For more information you can contact:</Header>
                    <Header as="h3">For Indonesia: Whatsapp to +62 889-7147-0590<br/>For Nigeria: contact this <a href="https://wa.me/message/S4AJLVGSNC4VK1">Whatsapp business account</a></Header>
                </Segment>
            </section>
            <section id="leaderboard">
                <Segment basic textAlign="center">
                    <Header as="h1">
                    Daily Leaderboard
                    </Header>
                    <Header as="h3">Top the leaderboard and be the best learner for the day!</Header>
                    <section id="ed_countdown">
                        <Container>
                            <Segment basic className="countDownWrapper">
                                <Header as="h1" className="countdownTitle"><Icon name='trophy'/> Event</Header>
                                <div className="countdown">
                                    {timerComponents.length ? timerComponents : <Header as="h2">Event has ended.</Header>}
                                </div>
                                <div className="countdown_mobile" style={{display: 'none'}}>
                                    {timerComponents.length ? timerComponents : <Header as="h2">Event has ended.</Header>}
                                </div>

                                <Dimmer active={eventLoading || loadingCounter.current} inverted>
                                    <Loader active={eventLoading || loadingCounter.current} size="large" content="Fetching Event..."/>
                                </Dimmer>
                            </Segment>
                        </Container>
                    </section>
                    <section id="ed_leaderboard">
                        <Container>
                            <Segment basic>
                                <Grid columns={1}>
                                    <Grid.Column className="ed_leaderboard" id="ed_lb_desktop">
                                        <Dropdown
                                        search
                                        selection
                                        onChange={onChangeEvent}
                                        value={selectedEvent}
                                        options={events}/>
                                    </Grid.Column>
                                    <Grid.Column id="ed_lb_mobile" style={{display: 'none'}}>
                                        <Dropdown
                                        fluid
                                        search
                                        selection
                                        onChange={onChangeEvent}
                                        value={selectedEvent}
                                        options={events}/>
                                    </Grid.Column>
                                </Grid>

                                {leaderboard.length > 0 ? 
                                <Table basic='very' className="ed_lb_table">
                                    <Table.Body>
                                        {leaderboard.map((v, k)=>
                                        <Table.Row key={k}>
                                            <Table.Cell><Header as="h3" className={v.rank>2 ? "plain": null}>{v.rank+1}</Header></Table.Cell>
                                            <Table.Cell><Header as="h3" className={v.rank>2 ? "plain": null}>{v.first_name}</Header></Table.Cell>
                                            <Table.Cell textAlign="right"><Header as="h3" className={v.rank>2 ? "plain": null}>{v.points} pts</Header></Table.Cell>
                                        </Table.Row>
                                        )}
                                        
                                    </Table.Body>
                                </Table>
                                : <Header as="h2">Be the first on the leaderboard by start playing!</Header>}
                                
                                {isSeeAll ? 
                                <Modal size="tiny" trigger={<Button id="see_all">
                                <Icon name="external"/> SEE FULL LIST
                                </Button>} closeIcon>
                                    <Modal.Header>Leaderboard</Modal.Header>
                                    <Modal.Content scrolling id="ed_lb_modal">
                                        <Table basic='very' className="ed_lb_table">
                                            <Table.Body>
                                                {leaderboardAll.map((v, k)=>
                                                <Table.Row key={k}>
                                                    <Table.Cell><Header as="h3" style={v.rank>2?{fontWeight: 500}:null}>{v.rank+1}</Header></Table.Cell>
                                                    <Table.Cell><Header as="h3" style={v.rank>2?{fontWeight: 500}:null}>{v.first_name}</Header></Table.Cell>
                                                    <Table.Cell><Header as="h3" style={v.rank>2?{fontWeight: 500}:null}>{v.points}</Header></Table.Cell>
                                                </Table.Row>
                                                )}
                                                
                                            </Table.Body>
                                        </Table>
                                    </Modal.Content>
                                </Modal>
                                : null}

                                <Dimmer active={leaderboardLoading} inverted>
                                    <Loader active={leaderboardLoading} size="large" content="Fetching Leaderboard..."/>
                                </Dimmer>
                            </Segment>
                        </Container>
                    </section>
                </Segment>
            </section>
        </Container>
    )
}

export default EventPage