import React from 'react'
import { toast } from 'react-toastify'
import { Container, Divider, Form, Header, Icon, Segment, Message, Popup, Modal, Loader, Grid } from 'semantic-ui-react'

class ContactPage extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            form:{
                email: '',
                name: '',
                feedback: ''
            },
            charlength:{
                email: 254,
                name: 64,
                feedback: 752
            },
            errorMessage: [],
            isLoading: true,
            loading: false
        }
    }

    componentDidMount(){
        this.setState({isLoading: false})
    }

    onChangeForm = (e, data) =>{
        let form = this.state.form
        switch (data.name) {
            case 'email':
                form.email = data.value
                break;
            case 'name':
                form.name = data.value
                break;
            default:
                form.feedback = data.value
                break;
        }

        this.setState({form: form})
    }

    submitFeedback = () =>{
        let form = this.state.form
        let msg = []
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(form.email === ""){
            msg.push("Email is required.")
        }else if(!re.test(String(form.email).toLowerCase())){
            msg.push("Email format is not correct.")
        }

        if(form.name === ""){
            msg.push("Name is required.")
        }
        if(form.feedback === ""){
            msg.push("Feedback is required.")
        }

        if(msg.length > 0){
            this.setState({errorMessage: msg})
            return false
        }

        this.setState({loading: true})

        fetch(process.env.REACT_APP_API_URL+'edwebsite/sendfeedback', {
            method: 'post',
            body: JSON.stringify(this.state.form),
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.success){
                toast.success('Feedback submitted successfully.')
                this.setState({form:{
                    email: '',
                    name: '',
                    feedback: ''
                }})
            }else{
                msg.push(res.message)
                this.setState({errorMessage: msg})
            }

            this.setState({loading: false})
        })
        .catch((e)=>{
            toast.error('Feedback cannot be submitted, please contact developer!')
        })
    }

    render() {
        return (
            <div id="contact_page">
                <Container>
                    <Segment basic>
                        <Header as="h1">
                            <Icon name="envelope" color="yellow"/> How can we help?
                        </Header>
                        <Grid columns={'equal'}>
                            <Grid.Column></Grid.Column>
                            <Grid.Column width={'8'}>
                                <Segment padded>
                                    {this.state.errorMessage.length>0?
                                    <Message size="large"
                                        error
                                        header='There was some errors with your submission'
                                        list={this.state.errorMessage}
                                    />
                                    :null}
                                    <Divider hidden/>

                                    <Form id="contact_form" size="huge" noValidate>
                                        <Popup trigger={<Form.Input placeholder="Enter your email" type="email" name="email" onChange={this.onChangeForm} value={this.state.form.email} maxLength={this.state.charlength.email}/>} 
                                        position="bottom right" content={this.state.charlength.email-this.state.form.email.length} on="focus"/>
                                        <Divider hidden/>
                                        <Popup trigger={<Form.Input placeholder="Enter your name" name="name" onChange={this.onChangeForm} value={this.state.form.name} maxLength={this.state.charlength.name}/>} 
                                        position="bottom right" content={this.state.charlength.name-this.state.form.name.length} on="focus"/>
                                        
                                        <Divider hidden/>
                                        <Popup trigger={<Form.TextArea placeholder="Enter your feedback" name="feedback" onChange={this.onChangeForm} value={this.state.form.feedback} maxLength={this.state.charlength.feedback}/>} 
                                        position="bottom right" content={this.state.charlength.feedback-this.state.form.feedback.length} on="focus"/>
                                        
                                        <Form.Button color="yellow" size="huge" content="Send Feedback" icon="paper plane" 
                                        disabled={this.state.loading} loading={this.state.loading} onClick={this.submitFeedback}/>
                                    </Form>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column></Grid.Column>
                        </Grid>

                        <Header as="h2">Give us a shout out at <a href="mailto:help@solveeducation.org">help@solveeducation.org</a> if you need further help.</Header>
                        <Header as="h2">You can also contact us on telegram by clicking here:</Header>
                        
                        <Header as="a" href="https://t.me/Ed_Academy_Support" target="_blank" textAlign="center">
                            <Icon name="telegram"/>
                            <Header.Content>Ed_Academy_Support</Header.Content>
                        </Header>
                    </Segment>
                </Container>

                <Modal basic open={this.state.isLoading}>
                    <Modal.Content>
                    <Loader active size="massive" className="ed_loading" content={<p className="breath">Please wait...</p>}/>
                    </Modal.Content>
                </Modal>
            </div>
        )
    }
}

export default ContactPage