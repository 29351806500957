import {
    Switch,
    Route
} from "react-router-dom";
import JoinPage from "./Join";
import TrackingPage from "./Tracking";
import ReactGA from 'react-ga';
import React from 'react';
import { useLocation } from 'react-router-dom';

ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);

function usePageViews() {
    let location = useLocation();
    React.useEffect(() => {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }, [location]);
}

const AppExternal = () =>{
    usePageViews();
    return(
        <Switch>
            <Route exact path="/join">
                <JoinPage/>
            </Route>
            <Route exact path="/tracking">
                <TrackingPage/>
            </Route>
        </Switch>
    )
}

export default AppExternal