import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from './pages/internal/App';
import reportWebVitals from './reportWebVitals';
import 'semantic-ui-css/semantic.min.css'
import { BrowserRouter } from 'react-router-dom';
import AppExternal from './pages/external/App';
require('dotenv').config()

// comment this on your local
Sentry.init({
  dsn: "https://7d4e905d10d94d96a2c7c80b6ade1dae@o280953.ingest.sentry.io/6194937",
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_ENV
});

let location = document.location.pathname
let externalList = ['/join', '/tracking']

if(localStorage.getItem('pseudo_id') === null){
  let pseudoID = Math.round((Math.pow(36, 10 + 1) - Math.random() * Math.pow(36, 10))).toString(36).slice(1)
  localStorage.setItem('pseudo_id', pseudoID)
}

ReactDOM.render(
  <BrowserRouter>
    {externalList.includes(location) ? <AppExternal/> : <App/>}
  </BrowserRouter>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
