import React, {useState, useEffect} from 'react'
import { Divider, Grid, Header, Icon, Image, Segment, Modal, Loader } from 'semantic-ui-react';
import banner from '../../assets/images/Header_Illustration.svg';
import phone from '../../assets/images/3_Phones.svg'
import whattodo from '../../assets/images/Fun.png'
import llustration_1 from '../../assets/images/Illustration_1.svg'
import Illustration_2 from '../../assets/images/Illustration_2.svg'
import Ed_Commands from '../../assets/images/Ed_Commands.svg'
import Classroom from '../../assets/images/Classroom.svg'
import Teacher from '../../assets/images/Teacher.svg'
import thumb from '../../assets/video/thumb.jpg'
import video from '../../assets/video/video.mp4'
import instagram from '../../assets/images/Instagram.svg'
import facebook from '../../assets/images/Facebook.svg'
import Ed_Love from '../../assets/images/Ed_Love.svg'
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';

function HomePage(){
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setIsLoading(false)
        return () => true
    }, [])

    return (
        <div id="home_page">
            <section id="banner">
                <Segment textAlign="center" basic>
                    <Image src={banner} size="large" centered/>
                    <Header as="h2">
                        Chatbot for learning English <br/>and employability skills
                    </Header>
                </Segment>
            </section>

            

            <Segment textAlign="center" basic>
                <Grid container columns={2} id="ed_findme_grid">
                    <Grid.Column>
                        <Image className="img-breath" src={Classroom} size="medium" centered href="https://t.me/solve_education" target="_blank"/>
                    </Grid.Column>
                    <Grid.Column>
                        <Image src={Teacher} size="medium" centered href="/join" target="_blank"/>
                    </Grid.Column>
                </Grid>
            </Segment>

            <Divider hidden/>
            <Divider hidden/>
            <Divider hidden/>
            <Divider hidden/>
            <Divider hidden/>

            <section id="ed_academy">
                <Segment textAlign="center" basic>
                    <Header as="h1">
                        Welcome to Ed Academy
                    </Header>
                    <Divider hidden/>
                    <Image src={phone} size="massive" centered/>
                </Segment>
            </section>

            <Divider hidden/>
            <Divider hidden/>
            <Divider hidden/>
            <Divider hidden/>
            <Divider hidden/>

            <section id="ed_whatdoyoudo">
                <Segment textAlign="center" basic>
                    <Header as="h2">
                        What do I do all day?
                    </Header>
                    <Divider hidden/>
                    <Image src={whattodo} size="big" centered/>
                </Segment>
            </section>

            <Divider hidden/>
            <Divider hidden/>
            <Divider hidden/>
            <Divider hidden/>
            <Divider hidden/>

            <section id="ed_task">
                <Segment textAlign="center" basic>
                    <Header as="h1">
                        I work so hard to give you exciting tasks
                    </Header>
                    <Grid container columns={2} id="ed_task_desktop">
                        <Grid.Row>
                            <Grid.Column>
                                <Header as="h2" textAlign="right">
                                    I wake up early in the morning to start a new challenge. I help you collect points as you play my games and be a champion.
                                </Header>
                            </Grid.Column>
                            <Grid.Column>
                                <Image src={llustration_1} size="medium"/>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Header as="h2" textAlign="right">
                                    You don’t have to wait for your birthday to get a gift. Just keep playing with me as I love to give gifts like mobile data, food tickets and much more to the champions.
                                </Header>
                            </Grid.Column>
                            <Grid.Column>
                                <Image src={Illustration_2} size="medium"/>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    {/* mobile  ed_task*/}
                    <Grid container columns={1} id="ed_task_mobile" style={{display: 'none'}}>
                        <Grid.Row>
                            <Grid.Column>
                                <Header as="h2" textAlign="right">
                                    I wake up early in the morning to start a new challenge. I help you collect points as you play my games and be a champion.
                                </Header>
                            </Grid.Column>
                            <Grid.Column>
                                <Image src={llustration_1} size="large"/>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Header as="h2" textAlign="right">
                                    You don’t have to wait for your birthday to get a gift. Just keep playing with me as I love to give gifts like mobile data, food tickets and much more to the champions.
                                </Header>
                            </Grid.Column>
                            <Grid.Column>
                                <Image src={Illustration_2} size="large"/>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </section>

            <Divider hidden/>
            <Divider hidden/>
            <Divider hidden/>
            <Divider hidden/>
            <Divider hidden/>

            <section id="ed_amazing">
                <Segment textAlign="center" basic>
                    <Header as="h1">
                        Some amazing traits about me:
                    </Header>
                    <Divider hidden/>
                    <Image src={Ed_Commands} size="big" centered/>
                </Segment>
            </section>

            <Divider hidden/>
            <Divider hidden/>
            <Divider hidden/>
            <Divider hidden/>
            <Divider hidden/>

            <section id="ed_findme">
                <Segment textAlign="center" basic>
                    <Header as="h1">
                        Where to find me?
                    </Header>
                    <Divider hidden/>
                    <Header as='h2' id="findme_desktop">
                        <Icon name="telegram" color="blue"/>
                        On the app Telegram downloaded on your smartphone or PC
                    </Header>
                    <Header as='h3' id="findme_mobile" style={{display: 'none'}}>
                        <Icon name="telegram" color="blue"/>
                        On the app Telegram downloaded on your smartphone or PC
                    </Header>
                    <Divider hidden/>
                    <Grid container columns={2} id="ed_findme_grid">
                        <Grid.Column>
                            <Image className="img-breath" src={Classroom} size="medium" centered href="https://t.me/solve_education" target="_blank"/>
                        </Grid.Column>
                        <Grid.Column>
                            <Image src={Teacher} size="medium" centered href="/join" target="_blank"/>
                        </Grid.Column>
                    </Grid>
                    
                </Segment>
            </section>
            <Divider hidden/>
            <Divider hidden/>
            <section id="ed_video">
                <Segment basic textAlign="center">
                    <Video
                        src={video}
                        poster={thumb}>
                    </Video>
                </Segment>
            </section>

            <Divider hidden/>
            <Divider hidden/>
            <Divider hidden/>
            <Divider hidden/>
            <Divider hidden/>

            <section id="ed_socialmedia">
                <Segment textAlign="center" basic>
                    <Header as="h1">
                        I am a social butterfly. Find me here
                    </Header>
                    <Divider hidden/>
                    <Grid container columns={2} id="ed_social_desktop">
                        <Grid.Column>
                            <div className="ed_social">
                                <Image src={instagram} size="tiny"/>
                                <div className="ed_social_text">
                                    <p onClick={()=>window.open("https://www.instagram.com/se_nigeria/", "_blank")}>@se_nigeria</p>
                                    <p onClick={()=>window.open("https://www.instagram.com/edthelearningbot/", "_blank")}>@edthelearningbot</p>
                                </div>
                            </div>
                        </Grid.Column>
                        <Grid.Column>
                            <div className="ed_social">
                                <Image src={facebook} size="tiny"/>
                                <div className="ed_social_text">
                                    <p onClick={()=>window.open("https://facebook.com/edthelearningbot", "_blank")}>@edthelearningbot</p>
                                    <p onClick={()=>window.open("https://facebook.com/solveeducationng", "_blank")}>@solveeducationng</p>
                                </div>
                            </div>
                        </Grid.Column>
                    </Grid>
                    <Grid container columns={2} id="ed_social_mobile" style={{display: 'none'}}>
                        <Grid.Column>
                            <div className="ed_social">
                                <Image src={instagram} size="mini"/>
                                <div className="ed_social_text">
                                    <p onClick={()=>window.open("https://www.instagram.com/se_nigeria/", "_blank")}>@se_nigeria</p>
                                    <p onClick={()=>window.open("https://www.instagram.com/edthelearningbot/", "_blank")}>@edthelearningbot</p>
                                </div>
                            </div>
                        </Grid.Column>
                        <Grid.Column>
                            <div className="ed_social">
                                <Image src={facebook} size="mini"/>
                                <div className="ed_social_text">
                                    <p onClick={()=>window.open("https://facebook.com/edthelearningbot", "_blank")}>@edthelearningbot</p>
                                    <p onClick={()=>window.open("https://facebook.com/solveeducationng", "_blank")}>@solveeducationng</p>
                                </div>
                            </div>
                        </Grid.Column>
                    </Grid>
                </Segment>
            </section>

            <Divider hidden/>
            <Divider hidden/>
            <Divider hidden/>
            <Divider hidden/>
            <Divider hidden/>
            <section id="ed_end">
                <Segment textAlign="center" basic>
                    <Image src={Ed_Love} size="small" centered id="ed_end_dektop"/>
                    <Image src={Ed_Love} size="tiny" centered id="ed_end_mobile" style={{display: 'none'}}/>
                </Segment>
            </section>

            <Divider hidden/>
            <Divider hidden/>
            <Divider hidden/>
            <div id="link_telegram">
                Join our Telegram channel:&nbsp;
                <a href="https://t.me/learnnwin" target="_blank">https://t.me/learnnwin</a>
            </div>

            <Modal basic open={isLoading}>
                <Modal.Content>
                <Loader active size="massive" className="ed_loading" content={<p className="breath">Please wait...</p>}/>
                </Modal.Content>
            </Modal>
        </div>
    )
}

export default HomePage