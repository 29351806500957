import React, {useState} from 'react'
import {Accordion, Header, Icon, Image, List} from 'semantic-ui-react'
import SS from '../assets/images/ssfaq.png'


export const FAQ = (props) =>{
    const [activeIndex, setActiveIndex] = useState(null)
    const handleClick = (e, data) =>{
        const newIndex = activeIndex === data.index ? -1 : data.index
        setActiveIndex(newIndex)
    }

    return(
        <div id="faq">
            <Header as="h1" dividing>
                <Icon name="chat" color="yellow"/> Frequently Asked Questions
            </Header>

            {props.path === 'lastonestanding' ? 
            <Accordion styled fluid>
                <Accordion.Title active={activeIndex === 0}
                index={0} onClick={handleClick}>
                    <Icon name='dropdown' />
                    How do I join the competition?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 0}>
                    <List bulleted>
                        <List.Item>Register manually by contacting <a target='_blank' rel="noreferrer" href='http://t.me/Ed_Academy_Support'>Ed Academy Support</a> and see rules on how to join.</List.Item>
                        <List.Item>Redeem a “join” ticket on the chatbot, use “/redeem” and choose <b>“Join last one standing by purchasing 1 ticket!”</b></List.Item>
                    </List>
                </Accordion.Content>
                
                <Accordion.Title active={activeIndex === 1}
                index={1} onClick={handleClick}>
                    <Icon name='dropdown' />
                    Can I purchase more than one ticket?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 1}>
                    The entry ticket can only be purchased once. Resurrection tickets can be purchased more than once.
                </Accordion.Content>

                <Accordion.Title active={activeIndex === 2}
                index={2} onClick={handleClick}>
                    <Icon name='dropdown' />
                    Why did I become a zombie?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 2}>
                    You become a zombie if you fail to complete the daily Last One Standing (L1S) missions.
                </Accordion.Content>

                <Accordion.Title active={activeIndex === 3}
                index={3} onClick={handleClick}>
                    <Icon name='dropdown' />
                    How do I become a survivor?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 3}>
                    You can join the event and complete the L1S missions every day, or you can resurrect after becoming a zombie by typing “/redeem” and choose “Resurrect yourself here!”
                </Accordion.Content>

                <Accordion.Title active={activeIndex === 4}
                index={4} onClick={handleClick}>
                    <Icon name='dropdown' />
                    How do I check my L1S mission?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 4}>
                    Make sure that you have already joined the L1S competition, and then type “/mission”. Only a user who has joined and is a survivor can see the L1S mission.
                </Accordion.Content>

                <Accordion.Title active={activeIndex === 5}
                index={5} onClick={handleClick}>
                    <Icon name='dropdown' />
                    Do I need to complete the other daily missions after completing the L1S mission?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 5}>
                    You can complete it to get 1000 points rewards! But don’t worry, you won’t turn into a zombie if you don’t complete it!
                </Accordion.Content>

                <Accordion.Title active={activeIndex === 6}
                index={6} onClick={handleClick}>
                    <Icon name='dropdown' />
                    How do I resurrect myself?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 6}>
                    You can resurrect using a resurrection ticket. You can redeem a resurrection ticket using points that you can collect by playing minigames or having your friends use your referral codes!
                </Accordion.Content>

                <Accordion.Title active={activeIndex === 7}
                index={7} onClick={handleClick}>
                    <Icon name='dropdown' />
                    How many resurrection tickets do I need to resurrect myself?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 7}>
                    <List bulleted>
                        <List.Item>You need 1 resurrection ticket for each day that you missed completing the L1S mission. For example, if you missed 4 days, you need 4 tickets to resurrect.</List.Item>
                        <List.Item>
                            You can check how many tickets you need by typing “/redeem” and selecting how many tickets you would like to redeem.
                            <Image src={SS} size="medium"/>
                        </List.Item>
                    </List>
                </Accordion.Content>

                <Accordion.Title active={activeIndex === 8}
                index={8} onClick={handleClick}>
                    <Icon name='dropdown' />
                    If I only need 2 tickets to resurrect, but I redeem 10 tickets for resurrection, what will happen to the other tickets?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 8}>
                    Your remaining tickets will become extra lifelines that you can use in the future, as long as they have not expired yet.
                </Accordion.Content>

                <Accordion.Title active={activeIndex === 9}
                index={9} onClick={handleClick}>
                    <Icon name='dropdown' />
                    Can resurrection tickets expire?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 9}>
                    Yes, they can. The resurrection tickets will expire when they cannot be exchanged anymore. Please type “/tickets” to check the remaining time to exchange the ticket!
                </Accordion.Content>

                <Accordion.Title active={activeIndex === 10}
                index={10} onClick={handleClick}>
                    <Icon name='dropdown' />
                    Do I get an extra vote if I complete the Last One Standing mission?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 10}>
                    Yes, you will get an extra vote. You can vote again as long as you don’t reach the limit of vote quota for the ongoing votes.
                </Accordion.Content>

                <Accordion.Title active={activeIndex === 11}
                index={11} onClick={handleClick}>
                    <Icon name='dropdown' />
                    Where do I see the rules of Last One Standing?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 11}>
                    You can check the rules on our <a target='_blank' rel="noreferrer" href='/lastonestanding?rule=true'>website</a>.
                </Accordion.Content>

                <Accordion.Title active={activeIndex === 12}
                index={12} onClick={handleClick}>
                    <Icon name='dropdown' />
                    How do I get a referral code?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 12}>
                    You can use either of the 3 methods below:
                    <List bulleted>
                        <List.Item>Join the L1S competition by contacting <a href='http://t.me/Ed_Academy_Support' target='_blank' rel='noreferrer'>Ed Academy Support to register</a>.</List.Item>
                        <List.Item>Input your friend’s referral code in our principal bot (@edthelearningbot), using the “referral code” button or go to the referral menu via the “/referral” command. The principal will give you your code afterwards.</List.Item>
                        <List.Item>Buy a ticket to join the last one standing event.</List.Item>
                    </List>
                </Accordion.Content>

                <Accordion.Title active={activeIndex === 13}
                index={13} onClick={handleClick}>
                    <Icon name='dropdown' />
                    Where can I check my referral code?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 13}>
                Type “/profile” to your teacher bot after joining the L1S competition.
                </Accordion.Content>

                <Accordion.Title active={activeIndex === 14}
                index={14} onClick={handleClick}>
                    <Icon name='dropdown' />
                    Where do I input the referral code?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 14}>
                Go to the principal bot (@edthelearningbot), then click on the “referral code” button or go to the referral menu via the “/referral” command.
                </Accordion.Content>

                <Accordion.Title active={activeIndex === 15}
                index={15} onClick={handleClick}>
                    <Icon name='dropdown' />
                    What do I get by sharing my referral code?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 15}>
                    <List bulleted>
                        <List.Item>You will get points if your friend enters your referral code on our chatbot.</List.Item>
                        <List.Item>You will get extra points if your referred friend joins the Last One Standing event!</List.Item>
                    </List>
                </Accordion.Content>

                <Accordion.Title active={activeIndex === 16}
                index={16} onClick={handleClick}>
                    <Icon name='dropdown' />
                    How many times can I input the referral code?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 16}>
                1 account can only input 1 referral code.
                </Accordion.Content>

                <Accordion.Title active={activeIndex === 17}
                index={17} onClick={handleClick}>
                    <Icon name='dropdown' />
                    Will the referral code for each event be different?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 17}>
                No, the referral code will be the same for every event.
                </Accordion.Content>

                <Accordion.Title active={activeIndex === 18}
                index={18} onClick={handleClick}>
                    <Icon name='dropdown' />
                    If I joined the Last One Standing first and then input my friend’s referral code afterwards in the principal bot, will I get the rewards?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 18}>
                You will still get the reward for inputting a referral code, however, you will not receive the extra points for entering the referral code.
                </Accordion.Content>
            </Accordion>

            :
            
            <Accordion styled fluid>
                <Accordion.Title active={activeIndex === 0}
                index={0} onClick={handleClick}>
                    <Icon name='dropdown' />
                    ED ACADEMY FEATURES
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 0}>
                    <p>Ed Academy is a learning bot that provides various subjects for you to play and learn. This learning bot or chatbot can be used to learn by yourself or play with your friends in a group. There are also various events on Ed Academy you can participate in to win prizes!</p>
                    <p>Ed Academy is completely FREE! No fees of any kind are required. If you ever receive a message from someone claiming to be part of Ed Academy asking you to pay, please ignore the message as it is most likely a scam.</p>
                </Accordion.Content>

                <Accordion.Title active={activeIndex === 1}
                index={1} onClick={handleClick}>
                    <Icon name='dropdown' />
                    ED /COMMANDS
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 1}>
                    <Header as="h4">List of commands</Header>
                    <List bulleted>
                        <List.Item><b>“Hi Ed”</b> : converse with Ed</List.Item>
                        <List.Item><b>/brainbattle</b> : play Brain Battle minigame</List.Item>
                        <List.Item><b>/commands</b> : show the list of commands</List.Item>
                        <List.Item><b>/country</b> : set your country</List.Item>
                        <List.Item><b>/dailycontent</b> : resend current day’s daily content</List.Item>
                        <List.Item><b>/events</b> : show a list of ongoing events</List.Item>
                        <List.Item><b>/fixthemix</b> : play Fix the Mix minigame</List.Item>
                        <List.Item><b>/goal</b> : change your daily learning goal settings</List.Item>
                        <List.Item><b>/groupcode</b> : enter Learnalytics code</List.Item>
                        <List.Item><b>/hellocafe</b> : play Hello Cafe minigame</List.Item>
                        <List.Item><b>/help</b> : if you need Ed’s help</List.Item>
                        <List.Item><b>/leaderboard</b> : show the leaderboard</List.Item>
                        <List.Item><b>/missions</b> : show the list of missions</List.Item>
                        <List.Item><b>/play</b> : start game</List.Item>
                        <List.Item><b>/points</b> : show your points</List.Item>
                        <List.Item><b>/profile</b> : show your last session detail and profile</List.Item>
                        <List.Item><b>/rank</b> : show full list of rank that you can achieve</List.Item>
                        <List.Item><b>/redeem</b> : used to redeem points for tickets</List.Item>
                        <List.Item><b>/rules</b> : display game rules</List.Item>
                        <List.Item><b>/seestuff</b> : play see Stuff minigame</List.Item>
                        <List.Item><b>/stop</b> : stop the current minigame play session</List.Item>
                        <List.Item><b>/subjects</b> : show a list of subjects available</List.Item>
                        <List.Item><b>/tickets</b> : check details of the lucky draw event and the ticket numbers owned</List.Item>
                        <List.Item><b>/typeticking</b> : play Type Ticking minigame</List.Item>
                        <List.Item><b>/vote</b> : show ongoing voting</List.Item>
                        <List.Item><b>/wordsnap</b> : play Word Snap minigame</List.Item>
                    </List>
                </Accordion.Content>

                <Accordion.Title active={activeIndex === 2}
                index={2} onClick={handleClick}>
                    <Icon name='dropdown' />
                    MY LEARNING
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 2}>
                    <Header as="h4">
                        HOW DO I LEARN?
                        <Header.Subheader>
                        <p>To start learning with your teacher, talk to the principal @edthelearningbot. You will see  a “Start” button on the chat screen, click it and click on “Start Learning” to be taken to your AgentBot as your personalized teacher.</p>
                        <p>Once you have your AgentBot, type ”/play” to learn.</p>
                        </Header.Subheader>
                    </Header>

                    <Header as="h4">
                    SUBJECTS
                    <Header.Subheader>
                    “Ed Academy offers several subjects for you to learn! Type /subject to see all available subjects.
                    </Header.Subheader>
                    </Header>

                    <Header as="h4">
                    MY LEARNING POINTS
                    <Header.Subheader>
                        <Image size="large" src={require('../assets/images/faq-2.png').default}/>
                    </Header.Subheader>
                    </Header>

                    <Header as="h4">
                    MY LEARNING PROGRESS
                    <Header.Subheader>
                        <Image size="large" src={require('../assets/images/faq-3.png').default}/>
                        <Image size="large" src={require('../assets/images/faq-1.png').default}/>
                    </Header.Subheader>
                    </Header>

                    <Header as="h4">
                    START LEARNING
                    <Header.Subheader>
                    Type /play or Ed Play to start learning
                    </Header.Subheader>
                    </Header>
                </Accordion.Content>

                <Accordion.Title active={activeIndex === 3}
                index={3} onClick={handleClick}>
                    <Icon name='dropdown' />
                    ED EVENTS
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 3}>
                    <p>Ed Academy offers many exciting events with interesting prizes! Type /event to know more.</p>
                    <List>
                        <List.Item>
                            <Header as="h4">LUCKY DRAW</Header>
                            <List.List>
                                <List.Item>
                                    <Header as="h5">&#62; EVENT DETAILS
                                    <Header.Subheader>
                                        <p>Lucky Draw is an event where one winner is selected to win rewards by earning points and exchanging them for tickets for the lucky draw.</p>
                                        <p>We provide different prizes in each period. It can be in the form of Shopee Pay balance, Amazon vouchers, data packages, mobile phone credit, or other types of vouchers. The value of the prize can also vary.</p>
                                    </Header.Subheader>
                                    </Header>
                                </List.Item>
                                <List.Item>
                                    <Header as="h5">&#62; JOIN EVENT
                                    <List.List bulleted>
                                        <List.Item>&#45; Collect as many points as possible!</List.Item>
                                        <List.Item>&#45; Exchange your points to redeem your Lucky Draw tickets by typing “/redeem”</List.Item>
                                        <List.Item>&#45; Lucky Draw ticket prices may vary depending on the prize.</List.Item>
                                        <List.Item>&#45; The admin will announce 5 potential winner candidates and the first person to type and send “Solve Education” in the <a rel='noreferrer' target={'_blank'} href='https://t.me/learnnwin'>https://t.me/learnnwin</a> channel will be the winner and entitled to receive the prize. The announcement can happen at any time, therefore players are encouraged to stay tuned on the <a rel='noreferrer' target={'_blank'} href='https://t.me/learnnwin'>https://t.me/learnnwin</a> channel.</List.Item>
                                        <List.Item>&#45; Type /play or choose “START LEARNING” to start playing and collecting points.</List.Item>
                                    </List.List>
                                    </Header>
                                </List.Item>
                            </List.List>
                        </List.Item>
                        <List.Item>
                            <Header as="h4">MVP</Header>
                            <List.List>
                                <List.Item>
                                    <Header as="h5">&#62; EVENT DETAILS
                                    <Header.Subheader>
                                        <p>MVP Event is an event that qualifies one of the top 3 learners (MVP) of each day to win weekly 10GB of internet data. MVP will be selected during the period of Thursday-Wednesday every week.</p>
                                    </Header.Subheader>
                                    </Header>
                                </List.Item>
                                <List.Item>
                                    <Header as="h5">&#62; JOIN EVENT
                                    <List.List bulleted>
                                        <List.Item>&#45; Players need to be on the daily Top 20 leaderboard to join the event</List.Item>
                                        <List.Item>&#45; To win the event, players must earn a place as one of 18 winner candidates. The winner out of the 18 candidates will be determined through voting, and the player with the most votes will win.</List.Item>
                                        <List.Item>&#45; How to become a candidate: every day, the three top players on the leaderboard are selected as candidates.</List.Item>
                                        <List.Item>&#45; Everyday during the MVP period, we choose the Top 3 from the leaderboard. The Top 3 winners cannot be the same for the consecutive days.</List.Item>
                                        <List.Item>&#45; The Top 3 MVP that have been selected each day (18 candidates) should collect as many votes as possible during the voting period to become the real MVP.</List.Item>
                                        <List.Item>&#45; The voting period will be held throughout the weekend.</List.Item>
                                        <List.Item>&#45; Type /play or choose “START LEARNING” to start</List.Item>
                                    </List.List>
                                    </Header>
                                </List.Item>
                            </List.List>
                        </List.Item>
                        <List.Item>
                            <Header as="h4">LAST ONE STANDING (under development, no description yet)</Header>
                            <List.List>
                                <List.Item>
                                    <Header as="h5">&#62; EVENT DETAILS
                                    <Header.Subheader>
                                    <p>Description, time, rules, prize</p>
                                    </Header.Subheader>
                                    </Header>
                                </List.Item>
                                <List.Item>
                                    <Header as="h5">&#62; JOIN EVENT
                                    <Header.Subheader><p>Depending on the event &#45; can be: start playing or redeem ticket to start playing, or else depending on the mechanics</p></Header.Subheader>
                                    </Header>
                                </List.Item>
                            </List.List>
                        </List.Item>
                        <List.Item>
                            <Header as="h4">
                            OTHER ONGOING EVENTS
                            <Header.Subheader><p>Type ed events or /events to see the list of ongoing events</p></Header.Subheader>
                            </Header>
                        </List.Item>
                    </List>
                </Accordion.Content>

                <Accordion.Title active={activeIndex === 4}
                index={4} onClick={handleClick}>
                    <Icon name='dropdown' />
                    HOW TO WIN
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 4}>
                    <p>Play to collect points and exchange them for tickets to participate. At the end of each challenge, 5 candidates are announced on the https://t.me/learnnwin channel. The first person of the 5 candidates to type and send “Solve Education” will be the winner.</p>
                    <p>Check <b>Event</b> to get more information about the event and check <b>Point and Prizes</b> to know more about the prizes</p>
                </Accordion.Content>

                <Accordion.Title active={activeIndex === 5}
                index={5} onClick={handleClick}>
                    <Icon name='dropdown' />
                    POINTS AND PRIZES
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 5}>
                    <Header as="h4">
                    PRIZES
                    <Header.Subheader>
                    We provide different prizes in each period. It can be in the form of Shopee Pay balance, Amazon vouchers, data packages, mobile phone credit, or other types of vouchers. The value of the prizes can also vary.
                    </Header.Subheader>
                    </Header>

                    <Header as="h4">
                    COLLECT POINTS
                    <Header.Subheader>
                    Answer the questions in the chatbot correctly and get points! You can play and collect points from the chatbot by playing in a  chat group with friends, but you will get double points if you play one-on-one with your own AgentBot!
                    </Header.Subheader>
                    </Header>

                    <Header as="h4">
                    REDEEM TICKET
                    <Header.Subheader>
                    Type Ed redeem or /redeem to redeem your ticket
                    </Header.Subheader>
                    </Header>

                    <Header as="h4">
                    CLAIM PRIZE
                    <Header.Subheader>
                    <List ordered>
                        <List.Item>Contact @Ed_Academy_Support</List.Item>
                        <List.Item>There will be a few tasks you need to complete before receiving your prize</List.Item>
                        <List.Item>After you have completed your tasks and our support team has confirmed that your tasks are done, you can receive your prize within 4 working days.</List.Item>
                    </List>
                    </Header.Subheader>
                    </Header>
                    
                </Accordion.Content>

                <Accordion.Title active={activeIndex === 6}
                index={6} onClick={handleClick}>
                    <Icon name='dropdown' />
                    CONTACT ED SUPPORT
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 6}>
                    <p>Link to Ed support account with automatic response: “Hi! Thank you for contacting Ed Academy Support. We will be with you within 2x24 hours”</p>
                </Accordion.Content>
            </Accordion>
            }
            
        </div>
    )
}
