import { useEffect, useState, createRef } from "react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { toast } from "react-toastify";
import { Button, Container, Form, Grid, Icon, Image, Dimmer, Popup, Header, Segment, Divider, Loader, Modal } from "semantic-ui-react";
import "./style.scss";
import moment from 'moment'

const SELC5LeaderboardPage = () =>{
    const [filterDate, setFilterDate] = useState([])
    const [leaderboard, setLeaderboard] = useState([])
    const [eventID, setEventID] = useState(null)
    const [stage, setStage] = useState([])
    const [loading, setLoading] = useState(false)
    const [loadingLB, setLoadingLB] = useState(false)
    const launch = []
    const [timerComponents, setTimerComponents] = useState([])
    const [timerComponents2, setTimerComponents2] = useState([])
    const [timerComponents3, setTimerComponents3] = useState([])
    const [lastUpdated, setLastUpdated] = useState("-")
    const currentEndDate = []
    const [search, setSearch] = useState('')
    const [totalRecord, setTotalRecord] = useState(3)
    const [LSExpanded, setLSExpanded] = useState(false)
    const [BSExpanded, setBSExpanded] = useState(false)
    const [pinnedTeam, setPinnedTeam] = useState([])
    const [isSearch, setIsSearch] = useState(false)
    const myRef = createRef()
    const myRefMobile = createRef()

    const startCountDown = (i) => {
        clearInterval(launch[i].current)
        launch[i].current = setInterval(function() { calculateTimeLeft(i) }, 1000);
    }

    const pauseCountDown = (i=null) => {
        if(i !== null){
            clearInterval(launch[i].current);
        }else{
            for (let i = 0; i < launch.length; i++) {
                clearInterval(launch[i].current);
            }
        }
    }

    const calculateTimeLeft = (i) => {
        let difference = +currentEndDate[i].current - +new Date();
        let timeLeft = {};
        let timerComp = {}
        timerComp[i] = []
    
        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };

            let tc = []

            Object.keys(timeLeft).forEach((interval) => {
                tc.push(
                    <span key={interval}>
                        {timeLeft[interval]}<span>{interval[0]}</span>
                    </span>
                );
            });

            if(i === 0){
                setTimerComponents(tc)
            }else if(i === 1){
                setTimerComponents2(tc)
            }else{
                setTimerComponents3(tc)
            }
        }else{
            pauseCountDown(i)
            if(i === 0){
                setTimerComponents([])
            }else if(i === 1){
                setTimerComponents2([])
            }else{
                setTimerComponents3([])
            }
        }
    }

    useEffect(()=>{
        loadEvent()
        return () => pauseCountDown()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const changeVisible = (data, e) =>{
        let actionObject = [...leaderboard];
        
        let teams = actionObject.filter(e=>e.Team_ID === data.Team_ID)
        teams[0] && (teams[0].Expanded = !teams[0].Expanded)
        setLeaderboard(actionObject);
    }

    const loadEvent = () =>{
        setLoading(true)
        fetch(process.env.REACT_APP_API_URL+'selc/getSELCEvent')
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === 200){
                let selectedEvent = res.data.filter(x=>x.Name.includes('SELC_5'))
                if(selectedEvent.length === 0){
                    toast.warn('No SELC 5 event found!')
                    return false
                }
                setEventID(selectedEvent[0].Record_KEY)

                let defaultDate = [
                    moment.unix(selectedEvent[0].Event_Start).toDate(),
                    moment.unix(selectedEvent[0].Event_End).toDate()
                ]
                
                setFilterDate(defaultDate)
                loadLeaderboard(selectedEvent[0].Record_KEY, defaultDate)

                let data = selectedEvent[0].Stages
                let custom = []
                let k = 0
                data.forEach(x=>{
                    let now = moment().format('X')
                    if(now >= x.Start_Date && now < x.End_Date){
                        custom.push({
                            Key: k,
                            Stage_Name: x.Stage_Name,
                            Stage_Icon: x.Stage_Icon,
                            Period: moment.unix(x.Start_Date).format('DD MMMM YYYY')+' - '+moment.unix(x.End_Date).format('DD MMMM YYYY'),
                            Start_Date: x.Start_Date,
                            End_Date: x.End_Date,
                            Active: now >= x.Start_Date && now < x.End_Date ? true : false,
                            Status: now < x.Start_Date ? 'Coming Soon' : now > x.End_Date ? 'Ended' : '0d - 0h - 0m - 0s'
                        })
                    }
                    k++
                })

                let activeList = custom.filter(x=>x.Active)
                let i = 0
                activeList.forEach(x=>{
                    launch.push(createRef())
                    currentEndDate.push(createRef())
                    pauseCountDown(i)
                    currentEndDate[i].current = new Date(x.End_Date*1000)
                    startCountDown(i)
                    let cs = custom.findIndex(v=>v.Key === x.Key)
                    if(cs !== -1){
                        custom[cs].TimerIndex = i
                    }
                    i++
                })

                setStage(custom)
            }
            setLoading(false)
        })
        .catch((e)=>{
            toast.error('Failed fetching stages data.')
        })
    }

    const loadLeaderboard = (cid, defaultDate = null) =>{
        let param = {
            competition_id: cid,
            start: defaultDate !== null ? moment(defaultDate[0]).format('YYYY-MM-DD') : moment(filterDate[0]).format('YYYY-MM-DD'),
            end: defaultDate !== null ? moment(defaultDate[1]).format('YYYY-MM-DD') : moment(filterDate[1]).format('YYYY-MM-DD'),
            search: search,
            limit: -1
        }

        setLoadingLB(true)
        fetch(process.env.REACT_APP_API_URL+'selc5/getLeaderboard', {
            method: 'POST',
            body: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === 200){
                let pinned_team = localStorage.getItem('pinned_team')
                let data = res.data
                if(pinned_team !== null){
                    let pin = data.filter(x=>x.Team_ID===parseInt(pinned_team))
                    setPinnedTeam(pin)
                }
                setTotalRecord(res.total_record)
                setLeaderboard(data)
                setLastUpdated(res.last_update > 0 ? moment.unix(res.last_update).format('YYYY-MM-DD HH:mm:ss') : '-')
            }

            setLoadingLB(false)
        })
        .catch((e)=>{
            toast.error('Failed fetching leaderboard data.')
        })
    }

    const renderTimer = (e, data) =>{
        let comp = timerComponents

        if(data.TimerIndex === 1){
            comp = timerComponents2
        }else if(data.TimerIndex === 2){
            comp = timerComponents3
        }

        return comp
    }

    const onChangeDate = (event, data) =>{
        if(data.value !== null && event !== undefined && data.value.length === 2){
            setFilterDate(data.value)
        }
    }

    const searchTeam = () =>{
        setIsSearch(true)
        loadLeaderboard(eventID)
    }

    const generateTable = (v, k, is_pinned = false) =>{
        let result = []

        result.push(<Grid.Row stretched className={v.Is_Target_Achieved ? "achieved" : ""} key={'MAIN'+k}>
            <Grid.Column width="6">
                <Grid columns="equal" className="inside">
                    <Grid.Row>
                    <Grid.Column width="3" textAlign="center">#{v.Rank}</Grid.Column>
                    <Grid.Column>{v.Team_Name}</Grid.Column>    
                    </Grid.Row>
                </Grid>
            </Grid.Column>
            <Grid.Column width="4">
                <Grid columns="equal" className="inside">
                    <Grid.Column><Icon name="users"/> {v.Buddies_Total}</Grid.Column>
                    <Grid.Column>{v.Buddies_Score}</Grid.Column>
                </Grid>
            </Grid.Column>
            <Grid.Column>
                <Grid>
                <Grid.Column textAlign="center">{v.Learning_Score}</Grid.Column>
            </Grid>
            </Grid.Column>
            <Grid.Column>
            <Grid>
                <Grid.Column textAlign="center">{v.Video_Score}</Grid.Column>
            </Grid>
            </Grid.Column>
            <Grid.Column>
            <Grid>
                <Grid.Column textAlign="center">{v.Total_Pot_Score}</Grid.Column>
            </Grid>
            </Grid.Column>
            <Grid.Column width={2}>
                <Grid>
                <Grid.Column>
                    <Popup size="mini" content="See member's progress" basic trigger={
                        <Button floated="right" size="mini" icon
                        onClick={changeVisible.bind(null, v)}>
                            <Icon name={v.Expanded ? "chevron up" : "chevron down"}/>
                        </Button>
                    }/>
                    {isSearch || pinnedTeam.length > 0 ? 
                    <>
                    {is_pinned ? 
                    <Popup size="mini" content="Unpin your team progress" basic trigger={
                        <Button size="mini" onClick={handlePinAction.bind(null, [])} floated="right" icon><Icon flipped="vertically" name="pin"/></Button>
                    }/>
                    : 
                    <Popup size="mini" content="Pin your team progress" basic trigger={
                        <Button size="mini" onClick={handlePinAction.bind(null, [v])} floated="right" icon><Icon name="pin"/></Button>
                    }/>
                    }
                    </>
                    : null}
                </Grid.Column>
                </Grid>
            </Grid.Column>
        </Grid.Row>)

        if(v.Expanded){
            if(v.Members.length > 0){
                result.push(
                    v.Members.map((x, y)=>
                    <Grid.Row key={y} stretched className={"details" + (y===0 ? " first" : '') + (y === v.Members.length-1 ? ' last' : '')}>
                        <Grid.Column width="6" key={'A'+y}>
                            <Grid columns="equal" className="inside">
                                <Grid.Row>
                                <Grid.Column width="3" color="red"/>

                                <Grid.Column>
                                    {x.Is_Leader ?
                                    <Popup size="mini" trigger={<Icon name="chess queen" color="yellow"/> } content='Team Leader' basic/>  
                                    : null}
                                    <span className="username">{x.User_Name}</span> {
                                        x.Telegram_Username? <span className="telegram_username">{x.Telegram_Username}</span>:null
                                    }
                                </Grid.Column>

                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                        <Grid.Column width="4" key={'B'+y}>
                            <Grid columns="equal" className="inside">
                                <Grid.Column><Icon name="users"/> {x.Buddies_Total}</Grid.Column>
                                <Grid.Column>{x.Buddies_Score}</Grid.Column>
                            </Grid>
                        </Grid.Column>
                        <Grid.Column key={'C'+y}>
                            <Grid>
                                <Grid.Column textAlign="center">{x.Learning_Score}</Grid.Column>
                            </Grid>
                        </Grid.Column>
                        <Grid.Column key={'D'+y}/>
                        <Grid.Column key={'E'+y}/>
                        <Grid.Column width="2" key={'F'+y}/>
                    </Grid.Row>
                    )
                )
            }else{
                result.push(<Grid.Row className="no-data">
                    <Grid.Column>No team member yet...</Grid.Column>
                </Grid.Row>)
            }
        }

        return result
    }

    const generateTableMobile = (v, k, is_pinned=false) =>{
        let result = []
        result.push(<Grid.Row key={k} className={v.Is_Target_Achieved ? "achieved" : ""}>
            <Grid.Column width="2"><b>#{v.Rank}</b></Grid.Column>
            <Grid.Column>{v.Team_Name}</Grid.Column>
            <Grid.Column textAlign="right" width="4"><b>Total Pot score :</b></Grid.Column>
            <Grid.Column width="2" textAlign="left">{v.Total_Pot_Score}</Grid.Column>
            <Grid.Column width="2">
                {isSearch || pinnedTeam.length > 0 ? 
                <>
                {is_pinned ? 
                <Button icon onClick={handlePinAction.bind(null, [])}><Icon flipped="vertically" name="pin"/></Button>
                : 
                <Button icon onClick={handlePinAction.bind(null, [v])}><Icon name="pin"/></Button>
                }
                </>
                : null}
                <Button size="mini" icon onClick={changeVisible.bind(null, v)}>
                <Icon name={v.Expanded ? "chevron up" : "chevron down"}/>
                </Button>
            </Grid.Column>
        </Grid.Row>)
        
        if(v.Expanded){
            let temp = [
                <Grid.Row className="details" key={'LS'}>
                    <Grid.Column>Learning Score</Grid.Column>
                    <Grid.Column textAlign="center" width="4">{v.Learning_Score}</Grid.Column>
                </Grid.Row>,
                <Grid.Row className="details colored" key={'BS'}>
                    <Grid.Column>Buddies Score (<Icon name="users"/> {v.Buddies_Total})</Grid.Column>
                    <Grid.Column textAlign="center" width="4">{v.Buddies_Score}</Grid.Column>
                </Grid.Row>,
                <Grid.Row className="details" key={'VS'}>
                    <Grid.Column>Video Score</Grid.Column>
                    <Grid.Column textAlign="center" width="4">{v.Video_Score}</Grid.Column>
                </Grid.Row>,
                <Grid.Row className="details" key={'DS'}>
                    <Grid.Column>
                        <Modal className="memberProgress" onClose={()=>{
                            setLSExpanded(false)
                            setBSExpanded(false)
                        }}
                        trigger={<Button primary>View members’s progress</Button>} closeIcon>
                            <Modal.Content>
                                <Header as="h3">
                                {v.Team_Name}
                                <Header.Subheader>
                                Member’s Progress
                                </Header.Subheader>
                                </Header>

                                <Container>
                                    <Grid columns="equal">
                                        <Grid.Row>
                                            <Grid.Column><b>Learning Score</b></Grid.Column>
                                            <Grid.Column width="4">{v.Learning_Score}</Grid.Column>
                                            <Grid.Column width="3">
                                                <Button size="mini" icon onClick={()=>setLSExpanded(!LSExpanded)}>
                                                <Icon name={LSExpanded ? "chevron up" : "chevron down"}/>
                                                </Button>
                                            </Grid.Column>
                                        </Grid.Row>

                                        {LSExpanded ? v.Members.length > 0 ? 
                                        v.Members.map((o,p)=>
                                            <Grid.Row key={p} className="progressTable">
                                                <Grid.Column>
                                                    <span className="username"><b>{o.User_Name}</b></span> {
                                                        o.Telegram_Username? <span className="telegram_username">{o.Telegram_Username}</span>:null
                                                    }
                                                </Grid.Column>
                                                <Grid.Column width="4">{o.Learning_Score}</Grid.Column>
                                                {o.Is_Leader ? 
                                                <Grid.Column width="3">
                                                    <Popup size="mini" trigger={<Icon name="chess queen" size="small" circular color="yellow"/>} basic content="Team Leader"/>
                                                </Grid.Column>
                                                : <Grid.Column width="3"/>}
                                            </Grid.Row>
                                        )
                                        :
                                        <Grid.Row className="progressTable">
                                            <Grid.Column>No team member yet...</Grid.Column>
                                        </Grid.Row>
                                        : null}
                                        

                                        <Grid.Row>
                                            <Grid.Column><b>Buddies Score</b></Grid.Column>
                                            <Grid.Column width="4">{v.Buddies_Score}</Grid.Column>
                                            <Grid.Column width="3">
                                                <Button size="mini" icon onClick={()=>setBSExpanded(!BSExpanded)}>
                                                <Icon name={BSExpanded ? "chevron up" : "chevron down"}/>
                                                </Button>
                                            </Grid.Column>
                                        </Grid.Row>

                                        {BSExpanded ? v.Members.length > 0 ? v.Members.map((o,p)=>
                                            <Grid.Row key={p} className="progressTable">
                                                <Grid.Column><b>{o.User_Name} (<Icon name="users"/> {v.Buddies_Total})</b></Grid.Column>
                                                <Grid.Column width="4">{o.Buddies_Score}</Grid.Column>
                                                {o.Is_Leader ? 
                                                <Grid.Column width="3">
                                                    <Popup size="mini" trigger={<Icon name="chess queen" size="small" circular color="yellow"/>} basic content="Team Leader"/>
                                                </Grid.Column>
                                                : <Grid.Column width="3"/>}
                                            </Grid.Row>
                                        ) : 
                                        <Grid.Row className="progressTable">
                                            <Grid.Column>No team member yet...</Grid.Column>
                                        </Grid.Row>
                                        : null}
                                    </Grid>
                                </Container>
                            </Modal.Content>
                        </Modal>
                    </Grid.Column>
                </Grid.Row>
            ]
            result.push(temp)
        }

        return result
    }

    const handlePinAction = (data, e) =>{
        setPinnedTeam(data)
        if(data.length > 0){
            window.scroll({
                top: 0,
                behavior: 'smooth'
            });
            localStorage.setItem('pinned_team', data[0].Team_ID)
        }else{
            localStorage.removeItem('pinned_team')
        }
    }

    const executeScroll = () => {
        myRef.current.scrollIntoView({
            behavior: 'smooth'
        })

        myRefMobile.current.scrollIntoView({
            behavior: 'smooth'
        })
    }

    return(
        <div id="selc5_leaderboard">

            <section id="banner" style={{ backgroundImage: `url(${require('../../../../assets/images/selc/magic_tree_2.png').default})` }}>
                <Image src={require('../../../../assets/images/selc/s5_logo.png').default} size="small"/>
            </section>
            
            <section id="filter">
                <Container text>
                    <Form size="big">
                    <Grid columns='equal'>
                        <Grid.Column width="7">
                            <Form.Field id="dateWrapper">
                                <SemanticDatepicker clearOnSameDateClick={false} name="filterDate" onChange={onChangeDate}
                                value={filterDate} clearable={false} type="range" loading={loading}
                                placeholder="Start Date..."/>
                            </Form.Field>
                            
                        </Grid.Column>
                        <Grid.Column>
                            <Form.Input action={<Button id="searchInput" loading={loadingLB} disabled={loadingLB} onClick={searchTeam} icon primary><Icon name="search"/></Button>}
                            placeholder='Search team name...' value={search} onChange={(e, data)=> setSearch(data.value)}/>
                        </Grid.Column>
                    </Grid>
                    </Form>
                </Container>
            </section>

            <section id="stage">
                <Container text id="stages">
                    <Dimmer.Dimmable as={Grid} columns='equal' className="stage_grid" dimmed>
                        {loading ? 
                        <>
                        <Segment>
                            <Divider hidden/>
                            <Divider hidden/>
                        </Segment>
                        <Dimmer active inverted>
                            <Loader active>Getting stage info...</Loader>
                        </Dimmer>
                        </>
                        : 
                        <>
                        {stage.length === 0 && !loading ? 
                        <Container textAlign="center"><p>No active stage at the moment...</p></Container>
                        : null}
                        {stage.map((v, k)=>
                            <Grid.Row stretched className={'active'} key={k}>
                                <Grid.Column width={5}>
                                    <Segment basic>
                                        <Header as="h2">{v.Stage_Name}</Header>
                                        <Header as="h2" icon={v.Stage_Icon} content=""/>
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column>
                                    <Segment basic>
                                        <Header as="h2">{v.Period}</Header>
                                        <i className="stage_status">
                                            {v.Active ? renderTimer(null, v) : v.Status}
                                        </i>
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                        )}
                        </>
                        }
                    </Dimmer.Dimmable>
                </Container>
            </section>

            <section id="leaderboard">
                <Container>
                    {/* desktop */}
                    <Grid columns="equal" className="lbTable" key={'TableA'}>
                        <Grid.Row stretched>
                            <Grid.Column width="1" textAlign="center"><b>Rank</b></Grid.Column>
                            <Grid.Column width="5"><b className="center">Team Name</b></Grid.Column>
                            <Grid.Column width="4" textAlign="center"><b>Buddies Score</b></Grid.Column>
                            <Grid.Column textAlign="center"><b>Learning Score</b></Grid.Column>
                            <Grid.Column textAlign="center"><b>Video Score</b></Grid.Column>
                            <Grid.Column textAlign="center" width="2">
                                <b>Total Pot Score <Popup size="mini" trigger={<Icon color="yellow" name="exclamation circle"/>} content="Total Pot Score is a summary of Buddies Score, Learning Score, and Video Score" basic/></b>
                            </Grid.Column>
                            <Grid.Column textAlign="center"/>
                        </Grid.Row>
                        {pinnedTeam.map((v, k)=>{
                            let comp = generateTable(v, k, true).map((l, m)=>{
                                return l
                            })
                            return comp
                        })}
                        {pinnedTeam.length > 0 ? 
                        <Divider/>
                        : null}
                    </Grid>

                    <Dimmer.Dimmable dimmed as={Grid} columns="equal" className="lbTable" key={'TableB'}>
                        <div ref={myRef}/>
                        <Grid.Row stretched style={{display: 'none'}} key="test"/>
                        {leaderboard.map((v, k)=>{
                            let comp = generateTable(v, k).map((l, m)=>{
                                return l
                            })
                            return comp
                        })}

                        {leaderboard.length === 0 && !loadingLB ? 
                        <Grid.Row textAlign="center" className="no-data">
                            <p>{isSearch ? 'Team no found...' : 'No data yet...'}</p>
                        </Grid.Row>
                        : null}

                        {loadingLB ? 
                        <Dimmer active inverted>
                            <Loader active size="large">Getting leaderboard data....</Loader>
                        </Dimmer>
                        : null}
                        
                    </Dimmer.Dimmable>

                    {/* mobile */}
                    <Grid columns="equal" className="lbTableMobile">
                        {pinnedTeam.map((v, k)=>{
                            let comp = generateTableMobile(v, k, true).map((l, m)=>{
                                return l
                            })
                            return comp
                        })}
                        {pinnedTeam.length > 0 ? 
                        <Divider/>
                        : null}
                    </Grid>
                    
                    <Dimmer.Dimmable dimmed as={Grid} columns="equal" className="lbTableMobile" key={'TableC'}>
                        <div ref={myRefMobile}/>
                        
                        {leaderboard.map((v, k)=>{
                            let comp = generateTableMobile(v, k).map((l, m)=>{
                                return l
                            })
                            return comp
                        })}

                        {leaderboard.length === 0 && !loadingLB ? 
                        <Grid.Row textAlign="center" className="no-data">
                            <p>{isSearch ? 'Team no found...' : 'No data yet...'}</p>
                        </Grid.Row>
                        : null}

                        {loadingLB ? 
                        <Dimmer active inverted>
                            <Loader active>Getting leaderboard data....</Loader>
                        </Dimmer>
                        : null}
                    </Dimmer.Dimmable>
                </Container>
            </section>

            <section id="footerLeaderboard">
                <Container>
                    <Grid columns="equal">
                        <Grid.Column>
                            {totalRecord > 10 ?
                            <Button size="large" onClick={executeScroll} icon secondary>
                                <Icon name="arrow up"/>
                                Top 10 
                            </Button>
                            : null}
                            <Button size="large" onClick={()=>window.open('/selc5', '_blank')} primary>See event details</Button>
                        </Grid.Column>
                        <Grid.Column textAlign="right">
                            <p>Data refresh every 5 minutes</p>
                            <p>Last updated: <i>{lastUpdated}</i></p>
                        </Grid.Column>
                    </Grid>
                </Container>
            </section>
        </div>
    )
}

export default SELC5LeaderboardPage