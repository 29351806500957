import { Container, Header, Segment, Image } from "semantic-ui-react"

function SELCRegisterPage(){
    return(
        <div id="selc_page">
            <section id="selc_banner">
                <Container>
                <Segment basic>
                    <Image centered src={require('../../../../assets/images/selc/banner.svg').default} size="massive"/>
                </Segment>
                </Container>
            </section>
            
            <section id="selc_banner_2">
                <Segment basic className="banner">
                    <Container textAlign="center">
                        <Header as="h1" inverted>EVENT REGISTRATION</Header>
                    </Container>
                </Segment>
            </section>
            
            <section id="register_form">
                <Container textAlign="center">
                <iframe id="form-frame" title="SELC Registration Form"
                src="https://docs.google.com/forms/d/e/1FAIpQLSd2iJLp4T8og4zLS464KYbVEr03ArG75t3K900fPUQ5uwScFQ/viewform?embedded=true" 
                width="100%" height="2623" frameBorder="0">
                    Getting the registration form...
                </iframe>
                </Container>
            </section>
        </div>
    )
}

export default SELCRegisterPage