import { Container, Header, Image, Segment } from "semantic-ui-react"
import NotFoundImg from '../assets/images/notfound.svg'

const NotFound = () =>{
    return(
        <Container id="not-found" textAlign="center">
            <Segment basic>
                <Image size="large" centered src={NotFoundImg}/>
                <Header as="h1">The page doesn't exist!</Header>
            </Segment>
        </Container>
    )
}

export default NotFound