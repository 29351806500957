import { Segment, Image, Container, Grid, Header, Table, Divider, Icon, Label, Popup, Button, Dimmer, Loader, Modal, Dropdown, Menu } from "semantic-ui-react"
import banner from '../../../assets/images/ols_banner1.png'
import banner2 from '../../../assets/images/ols_banner2.png'
import Pig from '../../../assets/images/pig_1.svg'
import Pig2 from '../../../assets/images/pig_3.svg'
import Pig3 from '../../../assets/images/pig_5.svg'
import Pig4 from '../../../assets/images/pig_7.svg'
import Pig5 from '../../../assets/images/pig_10.svg'
import People from '../../../assets/images/ols_people.svg'
import { useState, useEffect, useRef } from "react"
import { toast } from "react-toastify"
import LOSRules from "../../../components/LOSRules"
import NotFoundIcon from '../../../assets/images/timeline/No Activity_Illustration.png'

const HOF_DATA = [
    {
        session: 1,
        name: 'Nwokeji Ikenna',
        testimoni: 'Didn’t win the first edition but was determined to win the second edition and by God’s grace, I did and even got a higher prize for it💪. Thanks to @se_nigeria for this contest and for helping me discover a hidden gritty part of me. SE rocks!!! #edthelearningbot'
    },
    {
        session: 2,
        name: 'Favour Imoke Idaka',
        testimoni: 'Honestly, it feels incredible and refreshing to learn consistently for 85 days. The fact that I surpassed my limits means a lot. I am glad to have acquired some amazing skills because of this Last One Standing season, from dedication, strategic thinking, and even perseverance.'
    }
]

const LastOneStandingPage = () =>{
    let url_string = window.location.href
    let url = new URL(url_string);
    let is_rule = url.searchParams.get("rule");  

    const [progressInfo, setProgressInfo] = useState({})
    const [zombie, setZombie] = useState([])
    const [zombieAll, setZombieAll] = useState([])
    const [survival, setSurvival] = useState([])
    const [survivalAll, setSurvivalAll] = useState([])
    const [progressLoading, setProgressLoading] = useState(true)
    const [participantLoading, setParticipantLoading] = useState(true)
    const [timerComponents, setTimerComponents] = useState([])
    const [currentBut, setCurrentBut] = useState('')
    const [isRule, setIsRule] = useState(is_rule !== null ? true : false)
    const [hof, setHof] = useState(HOF_DATA[HOF_DATA.length - 1])
    const [timezone, setTimezone] = useState({
        text: 'Asia/Jakarta',
        time: 'GMT+7'
    })
    const launch = useRef();
    const currentEndDate = useRef()
    const loadingCounter = useRef()

    const startCountDown = () => {
        clearInterval(launch.current)
        launch.current = setInterval(calculateTimeLeft, 1000);
    };

    const pauseCountDown = () => {
        clearInterval(launch.current);
    };


    const calculateTimeLeft = () => {
        loadingCounter.current = false
        let difference = +currentEndDate.current - +new Date();
        let timeLeft = {};
    
        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };

            let tc = []
            Object.keys(timeLeft).forEach((interval) => {
                tc.push(
                    <Header as="h1" key={interval}>
                        {timeLeft[interval]}<span>{interval[0]}</span>
                    </Header>
                );
            });
            setTimerComponents(tc)
        }else{
            pauseCountDown()
            setTimerComponents([])
        }
    }

    useEffect(() => {
        loadProgress()
        loadParticipant()
        return () => pauseCountDown()
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    const loadProgress = () =>{
        loadingCounter.current = true
        fetch(process.env.REACT_APP_API_URL+'challenge/getLastOneStandingPoints')
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === 200){
                if(res.data.reach_minimum){
                    pauseCountDown()
                    currentEndDate.current = new Date(res.data.mission_end*1000)
                    startCountDown()
                }else{
                    loadingCounter.current = false
                }
                setProgressInfo(res.data)
                if(res.timezone !== undefined){
                    setTimezone(res.timezone)
                }
            }else{
                toast.error(res.message)
            }
            setProgressLoading(false)
        })
        .catch((e)=>{
            toast.error('Something wrong with fetching point data, please contact the developer.')
        })
    }

    const loadParticipant = () =>{
        fetch(process.env.REACT_APP_API_URL+'challenge/getLastOneStandingParticipants')
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === 200){
                let zm = res.data.zombies
                let sv = res.data.survivors
                setZombieAll(zm)
                setSurvivalAll(sv)
                setZombie(zm.slice(0, 10))
                setSurvival(sv.slice(0, 10))
            }else{
                toast.error(res.message)
            }
            setParticipantLoading(false)
        })
        .catch((e)=>{
            toast.error('Something wrong with fetching participant data, please contact the developer.')
        })
    }

    const quickButtons = (e, data) =>{
        setCurrentBut(data.name)
        let target = 'banner'
        if(data.name === 'how'){
            target = 'howToPlay'
        }else if(data.name === 'hof'){
            target = 'hof'
        }

        var element = document.getElementById(target);
        var headerOffset = 100;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    
        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });     
    }

    const changeHOF = (e, data) =>{
        setHof(HOF_DATA[data.value-1])
    }

    return(
        <div id="one_last_standing">
            <div className="quickButtons">
                <Menu icon vertical>
                    <Menu.Item name='challenge' onClick={quickButtons} active={currentBut === 'challenge'}>
                        <Icon name='gamepad' />
                        <span>Current Challenge</span>
                    </Menu.Item>

                    <Menu.Item name='how' onClick={quickButtons} active={currentBut === 'how'}>
                        <Icon name='question circle'/>
                        <span>How to Play</span>
                    </Menu.Item>

                    <Menu.Item name='hof' onClick={quickButtons} active={currentBut === 'hof'}>
                        <Icon name='trophy' />
                        <span>Hall of Fame</span>
                    </Menu.Item>
                </Menu>
            </div>
            <section id="banner">
                <Segment basic className="bannerBackground">
                    <Container textAlign="center">
                        <Image src={banner} centered size="medium" id="banner1"/>
                        <Image src={banner2} centered id="banner2"/>
                    </Container>
                </Segment>
            </section>
            <section id="main_content">
                <Grid columns="equal">
                    <Grid.Row>
                    <Grid.Column width={3} className="sidePattern">
                    </Grid.Column>
                    <Grid.Column textAlign="center" id="mainColumn">
                        <Dimmer.Dimmable padded id="counterSegment" textAlign="center" as={Segment} blurring dimmed={progressLoading || loadingCounter.current}>
                            <Dimmer active={progressLoading || loadingCounter.current} inverted>
                                <Loader size="large" active content="Fetching progress data..."/>
                            </Dimmer>
                            {progressInfo.reach_minimum ? 
                            <div>
                                <Header as="h1">Daily Mission</Header>
                                <div className="countdownWrap">
                                    {timerComponents.length > 0 ? timerComponents : <Header as="h3">Event has ended.</Header>}
                                </div>
                                <Header as="h5" color="grey"> <sup>*</sup> Daily missions reset every day on {progressInfo.date_start.split(' ')[1]} {timezone.text} ({timezone.time}) time.</Header>
                            </div>
                            : 
                            <><Header as="h1">{progressInfo.total_user_minimum}</Header>
                            <Header as="p">Participants needed to unlock the event</Header></>
                            }
                        </Dimmer.Dimmable>
                        <Segment basic textAlign="center" id="progressPig">
                            <span></span>
                            <Popup trigger={
                                <div className={
                                    progressInfo.percentage >= 25 && progressInfo.percentage < 100 ? "ui active small blue progress passed":
                                    progressInfo.percentage === 100 ? "ui small blue progress":
                                    "ui active small blue progress"
                                } data-percent={progressInfo.percentage}>
                                    <span className="checkPointText">US${progressInfo.starting_prize}</span>
                                    <Icon name="check circle" size="big" color={progressInfo.reach_minimum ? "yellow" : "grey"}/>
                                    <span className="endPointText">US${progressInfo.maximum_prize}</span>
                                    {progressInfo.percentage < 100 ? 
                                        <Image src={
                                            progressInfo.percentage < 25 ? Pig :
                                            progressInfo.percentage < 50 && progressInfo.percentage >= 25 ? Pig2 :
                                            progressInfo.percentage < 75 && progressInfo.percentage >= 50 ? Pig3 :
                                            progressInfo.percentage < 100 && progressInfo.percentage >= 75 ? Pig4 :
                                            progressInfo.percentage === 100 ? Pig5 : null
                                        } size="tiny" id="pigProgress" style={{left: (progressInfo.percentage-6)+'%'}}/>
                                    : null}
                                    <div className="bar" style={{width: progressInfo.percentage+'%'}}>
                                        {progressInfo.percentage < 100 ? 
                                            <Image src={
                                                progressInfo.percentage < 25 ? Pig :
                                                progressInfo.percentage < 50 && progressInfo.percentage >= 25 ? Pig2 :
                                                progressInfo.percentage < 75 && progressInfo.percentage >= 50 ? Pig3 :
                                                progressInfo.percentage < 100 && progressInfo.percentage >= 75 ? Pig4 :
                                                progressInfo.percentage === 100 ? Pig5 : null
                                            } size="tiny" id="pigProgressMobile"/>
                                        : null}
                                        {!progressLoading ? <Popup pinned id="PopUpMaster" open={true} trigger={<Label circular empty color="yellow" size="big"/>} content={
                                        <div id="popUpPeople">
                                            <Image src={People} size="mini"/> 
                                            <Header as="p">
                                                <Header.Content>{progressInfo.total_participants} (US${progressInfo.total_participants > progressInfo.total_user_maximum ? progressInfo.maximum_prize : progressInfo.current_prize})</Header.Content>
                                                <Header.Subheader>People Joined</Header.Subheader>
                                            </Header>
                                        </div>
                                        } position="bottom center"/> : null}
                                    </div>
                                </div>
                            } content={
                                <div id="refreshText">
                                    <p>One user will add US${progressInfo.prize_per_user} to the money pot</p>
                                    <i>Please refresh the page to see new updates</i>
                                </div>
                            } position="top center" size="large"/>

                            {progressInfo.percentage < 100 ? 
                            <Popup trigger={<Image src={Pig5} size="tiny" id="fullPig"/>} content={progressInfo.total_user_maximum+" people to unlock!"}/>
                            : <Image src={Pig5} size="tiny" id="fullPig"/>}
                            
                            <Dimmer active={progressLoading} inverted>
                                <Loader size="large" active content="Fetching progress data..."/>
                            </Dimmer>
                        </Segment>

                        <Dimmer.Dimmable basic textAlign="center" blurring as={Segment} dimmed={participantLoading}>
                            <Header as="h1">Ed Troops Size</Header>
                            <Divider hidden/>
                            <Divider hidden/>
                            <Grid columns="equal">
                                <Grid.Column id="survivalColumn">
                                    <Header as="h2">Survivors: {survivalAll.length}</Header>
                                    <Segment className="olsTable">
                                        {survival.length > 0 ? 
                                        <Table basic='very'>
                                            <Table.Body>
                                                {survival.map((v, k)=>
                                                <Table.Row key={k}>
                                                    <Table.Cell>{v.Name}</Table.Cell>
                                                </Table.Row>
                                                )}
                                            </Table.Body>
                                        </Table>
                                        : <>
                                        <Image src={NotFoundIcon} size='small' centered/>
                                        <Header as="h3" textAlign="center">No survival found...</Header>  
                                        </>}
                                        
                                        {survivalAll.length > 10 ? 
                                        <Modal closeIcon size="tiny" trigger={<Button><Icon name="external"/> SEE FULL LIST</Button>}>
                                            <Modal.Header>Survivors: {survivalAll.length}</Modal.Header>
                                            <Modal.Content scrolling>
                                                <Table basic='very'>
                                                    <Table.Body>
                                                        {survivalAll.map((v, k)=>
                                                        <Table.Row key={k}>
                                                            <Table.Cell>{v.Name}</Table.Cell>
                                                        </Table.Row>
                                                        )}
                                                    </Table.Body>
                                                </Table>
                                            </Modal.Content>
                                        </Modal>
                                        : null}
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column id="zombieColumn">
                                    <Header as="h2">Zombies: {zombieAll.length}</Header>
                                    <Segment className="olsTable">
                                        {zombie.length > 0? 
                                        <Table basic='very'>
                                            <Table.Body>
                                                {zombie.map((v, k)=>
                                                    <Table.Row key={k}>
                                                        <Table.Cell>{v.Name}</Table.Cell>
                                                    </Table.Row>
                                                )}
                                            </Table.Body>
                                        </Table>
                                        :<>
                                        <Image src={NotFoundIcon} size='small' centered/>
                                        <Header as="h3" textAlign="center">No zombie found...</Header>  
                                        </>}
                                        
                                        {zombieAll.length > 10 ? 
                                        <Modal closeIcon size="tiny" trigger={<Button><Icon name="external"/> SEE FULL LIST</Button>}>
                                            <Modal.Header>Zombies: {zombieAll.length}</Modal.Header>
                                            <Modal.Content scrolling>
                                                <Table basic='very'>
                                                    <Table.Body>
                                                        {zombieAll.map((v, k)=>
                                                        <Table.Row key={k}>
                                                            <Table.Cell>{v.Name}</Table.Cell>
                                                        </Table.Row>
                                                        )}
                                                    </Table.Body>
                                                </Table>
                                            </Modal.Content>
                                        </Modal>    
                                        : null}
                                    </Segment>
                                </Grid.Column>
                            </Grid>
                            <Divider hidden/>
                            <a className="ui big basic button" href="/lastonestandingjourney">Click here to see your journey of last one standing</a>
                            <Dimmer active={participantLoading} inverted>
                                <Loader size="large" active content="Fetching participant data..."/>
                            </Dimmer>
                        </Dimmer.Dimmable>

                        <Segment textAlign="center" basic id="howToPlay">
                            <iframe width="100%" height="300" src="https://www.youtube.com/embed/vijAgWDlvcA" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            <Divider hidden/>
                            <Header as="h1">Let’s Join the Competition Win the Prize up to US$ 1000!</Header>
                            <Divider hidden/>
                            <a href="https://t.me/Ed_Academy_Support" target="_blank" className="ui massive button joinBtn" rel="noreferrer" id="joinBtn">Click here to join!</a>
                            <Divider hidden/>
                            <Modal trigger={<Button basic size="big">Click here for the game rules</Button>} closeIcon size="small">
                                <Modal.Header>Game Rules</Modal.Header>
                                <Modal.Content scrolling>
                                    <LOSRules/>
                                </Modal.Content>
                            </Modal>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column width={3} className="sidePattern_R">
                    </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Segment basic textAlign="center" className="hof" id="hof">
                                <div className="headerHof">
                                    <Header as="h1">
                                        <Icon name="trophy"/> Hall of Fame
                                    </Header>
                                    <Dropdown onChange={changeHOF} selection defaultValue={HOF_DATA.length} options={[
                                        {key: 1, text: "Season 1", value: 1},
                                        {key: 2, text: "Season 2", value: 2}
                                    ]}/>
                                </div>

                                <div className="hofItem">
                                    <div className="profile">
                                    <Image centered circular src={require(`../../../assets/images/hof/${hof.session}.png`).default} size="small"/>
                                    <Header as="h3">{hof.name}</Header>
                                    </div>
                                    <div className="testimoni">
                                        <p>“{hof.testimoni}”</p>
                                    </div>
                                </div>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={3} className="sidePattern"></Grid.Column>
                        <Grid.Column>
                            <Segment basic textAlign="center">
                                <Header as="h1">Contact us here</Header>
                                <Header as="a" href="https://t.me/Ed_Academy_Support" target="_blank" textAlign="center">
                                    <Icon name="telegram"/>
                                    <Header.Content>Ed_Academy_Support</Header.Content>
                                </Header>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column width={3} className="sidePattern_R"></Grid.Column>
                    </Grid.Row>
                </Grid>

                <Modal closeIcon size="small" open={isRule} onClose={()=>setIsRule(false)}>
                    <Modal.Header>Game Rules</Modal.Header>
                    <Modal.Content scrolling>
                        <LOSRules/>
                    </Modal.Content>
                </Modal>
            </section>
        </div>
    )
}

export default LastOneStandingPage