import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Container, Header, Segment, Image, Button, Divider, Grid, Form, Modal, Input, Label, Loader, Dimmer, Popup, Icon, Checkbox } from "semantic-ui-react"
import "../../assets/styles/register.scss";
import LANGS from '../../data/lang.json'
import { GoogleSaveEvent } from "../../helpers/googleAnalytics";
import moment from 'moment-timezone'

function RegisterPage(){
    const [step, setStep] = useState(1)
    const [isTeam, setIsTeam] = useState(false)
    const [modalOpened, setModalOpened] = useState(false)
    const [loading, setLoading] = useState(false)
    const [teamName, setTeamName] = useState('')
    const [fName, setFName] = useState('')
    const [lName, setLName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [country, setCountry] = useState('')
    const [age, setAge] = useState('')
    const [hear, setHear] = useState('')
    const [selectedTeam, setSelectedTeam] = useState(null)
    const [isDone, setIsDone] = useState(false)
    const [teamLoading, setTeamLoading] = useState(false)
    const [oldTeamList, setOldTeamList] = useState([])
    const [teamList, setTeamList] = useState([])
    const [eventLoading, setEventLoading] = useState(false)
    const [userDetail, setUserDetail] = useState(null)
    const [disableButton, setDisableButton] = useState(false)
    const [eventDetail, setEventDetail] = useState(null)
    const [TeacherLink, setTeacherLink] = useState('')
    const [TelegramInstalled, setTelegramInstalled] = useState(false)
    const [eventStatus, setEventStatus] = useState('')
    const [modalLoading, setModalLoading] = useState(true)
    const [cid, setCid] = useState(null)
    const [uid, setUid] = useState(null)
    const [category, setCategory] = useState('selc')
    const [regTitle, setRegTitle] = useState("The registration period is over! 😢")

    useEffect(()=>{
        generatePseudoID(10)
        loadParams()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(()=>{
        getUserDetail()
    }, [cid, uid, category]) // eslint-disable-line react-hooks/exhaustive-deps
    
    let lang = []
    LANGS.forEach(x=>{
        lang.push({ key: x.code, value: x.name, flag: x.code.toLocaleLowerCase(), text: x.name })
    })
    const _languages = lang

    const stepConstant = [
        {action: 'next', is_team: true},
        {action: 'next', is_team: false},
        {action: 'prev'}
    ]

    const loadParams = () => {
        let queryString = window.location.search;
        let urlParam = new URLSearchParams(queryString);
        let cid = urlParam.get('cid')
        let category = urlParam.get('category')
        let uid = urlParam.get('uid')
        if(cid === null || cid === -1 || category === null){
            loadNextEvent()
        }else{
            setUid(uid)
            setCid(cid)
            if(category !== null)setCategory(category)
            setModalLoading(false)
        }
    }

    const loadNextEvent = () =>{
        fetch(process.env.REACT_APP_API_URL+'selc/getNextEvent')
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === 200 || res.status === 400){
                let cid = res.data.Record_KEY
                window.history.replaceState(
                    null,
                    document.title,
                    `${window.location.pathname}?cid=${encodeURIComponent(cid)}&category=${encodeURIComponent(category)}`
                )
                setCid(cid)
                setModalLoading(false)
            }else{
                throw res
            }
        })
        .catch((e)=>{
            window.location.href = '/'
        })
    }

    const goToNextEvent = () =>{
        fetch(process.env.REACT_APP_API_URL+'selc/getNextEvent')
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === 200 || res.status === 400){
                let cid = res.data.Record_KEY
                let searchParams = new URLSearchParams(window.location.search)
                searchParams.set('cid', cid)
                let search = '?'+searchParams.toString()
                window.location = `${window.location.pathname}${search}`
            }
        })
        .catch((e)=>{
            window.location.href = '/'
        })
    }

    const generatePseudoID = (length) =>{
        let localPseudoID = localStorage.getItem('pseudo_id')
        let pseudoID = Math.round((Math.pow(36, length + 1) - Math.random() * Math.pow(36, length))).toString(36).slice(1)
        if(uid === null && localPseudoID === null){
            localStorage.setItem('pseudo_id', pseudoID)
        }

        if(uid === null){
            // [non-chatbot] save event on google analytics
            GoogleSaveEvent("Register_Now", {
                name: "Visited from landing page",
                pseudo_id: localPseudoID === null ? pseudoID : localPseudoID,
                event_id: cid,
                source: 'Landing Page'
            }, localPseudoID === null ? pseudoID : localPseudoID)
        }
    }

    const getPseudoID = () =>{
        return localStorage.getItem('pseudo_id')
    }

    const getEventDetail = () =>{
        if(cid == null){
            return false;
        }
        if(cid === -1){
            // no upcoming event
            setRegTitle("Let's create your team for the next season of SELC!")
            return false;
        }
        setEventLoading(true)
        fetch(process.env.REACT_APP_API_URL+'selc/getSELCEvent?id='+cid)
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.data.length > 0){
                let eventData = res.data[0]
                setEventDetail(eventData)
                let regEnd = eventData.Event_Start
                let today = moment(new Date()).tz(eventData.Timezone).format('X')
                let status = ''
                if(today >= regEnd){
                    // event has been ended
                    status = 'end'
                    setRegTitle("The registration period is over! 😢")
                    goToNextEvent()
                }else{
                    // event found

                    let arr_selc = []
                    if(eventData.Category.toLowerCase() === 'selc'){
                        arr_selc = Array.from(eventData.Name.matchAll(/selc[\s_]([0-9]+)/gi))[0]
                    }
                    if(arr_selc && arr_selc.length === 2){
                        setRegTitle(`Let's create your team for SELC Season ${arr_selc[1]}!`)
                    }else{
                        setRegTitle("Let's create your team for the next season of SELC!")
                    }
                }

                setEventStatus(status)
            }else{
                // event is not valid
                goToNextEvent()
            }
            setEventLoading(false)
        })
        .catch((e)=>{
            toast.error('Failed to get event detail.')
        })
    }

    const getUserDetail = () =>{
        if(uid === null){
            getEventDetail()
            return false
        }
        setEventLoading(true)

        fetch(process.env.REACT_APP_API_URL+'competition/getUserInfo?uid='+uid)
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === 200){
                // // [chatbot] save event on google analytics
                GoogleSaveEvent("Register_Now", {
                    name: "Visited from landing page",
                    user_id: res.data.User_ID,
                    event_id: cid,
                    source: 'Landing Page'
                }, res.data.User_ID)

                setUserDetail(res.data)
                setDisableButton(false)
                getEventDetail()
            }else{
                setDisableButton(true)
                toast.warn('The user detail not found, please try generate another link!')
            }
        })
        .catch((e)=>{
            toast.error('Failed to get user detail.')
        })
    }

    const stepAction = (param, e) =>{
        let pseudo_id = getPseudoID()
        window.onbeforeunload = function(e) {
            return "Changes you made may not be saved.";
        };
        
        let _step = step

        // create team button clicked
        if(_step === 1 && param.is_team){
            if(pseudo_id !== null){
                // [non-chatbot] save event on google analytics
                GoogleSaveEvent("Create_Team", {
                    name: "Create Team button clicked",
                    pseudo_id: pseudo_id,
                    event_id: cid,
                    source: 'Landing Page',
                    condition: 'Creating Team'
                }, pseudo_id)
            }else{
                // [chatbot] save event on google analytics
                GoogleSaveEvent("Create_Team", {
                    name: "Create Team button clicked",
                    user_id: userDetail.User_ID,
                    event_id: cid,
                    source: 'Chatbot',
                    condition: 'Creating Team'
                }, userDetail.User_ID)
            }
        }else if(_step === 1 && !param.is_team){
            if(pseudo_id !== null){
                // [non-chatbot] save event on google analytics
                GoogleSaveEvent("Join_Team", {
                    name: "Joining Team button clicked",
                    pseudo_id: pseudo_id,
                    event_id: cid,
                    source: 'Landing Page',
                    condition: 'Join Team'
                }, pseudo_id)
            }else{
                // [chatbot] save event on google analytics
                GoogleSaveEvent("Join_Team", {
                    name: "Create Team button clicked",
                    user_id: userDetail.User_ID,
                    event_id: cid,
                    source: 'Chatbot',
                    condition: 'Join Team'
                }, userDetail.User_ID)
            }
        }

        // team name created
        if(_step === 2 && param.is_team){
            if(pseudo_id !== null){
                // [non-chatbot] save event on google analytics
                GoogleSaveEvent("Team_Name_Create", {
                    name: "Team name creating step",
                    pseudo_id: pseudo_id,
                    event_id: cid,
                    source: 'Landing Page',
                    condition: 'Creating Team'
                }, pseudo_id)
            }else{
                // [chatbot] save event on google analytics
                GoogleSaveEvent("Team_Name_Create", {
                    name: "Team name creating step",
                    user_id: userDetail.User_ID,
                    event_id: cid,
                    source: 'Chatbot',
                    condition: 'Creating Team'
                }, userDetail.User_ID)
            }
        }

        if(param.action === 'next'){
            _step = _step+1
        }else{
            _step = _step-1
            setTeamName('')
        }

        if(_step === 2){
            if(param.is_team !== undefined && param.is_team){
                setIsTeam(true)
            }else{
                setIsTeam(false)
                getTeamList()
            }
        }

        setStep(_step)
    }

    const checkTeamName = () =>{
        if(teamName === ''){
            toast.warn('Please input team name first.')
            return false
        }
        setLoading(true)
        fetch(process.env.REACT_APP_API_URL+'competition/checkTeamNameValidity?team_name='+teamName+'&competition_id='+cid)
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === 200){
                stepAction(stepConstant[0], null)
            }else{
                toast.warn(res.messages)
            }
            setLoading(false)
        })
        .catch((e)=>{
            toast.error('Failed checking team name!')
        })
    }

    const register = () =>{
        if(fName === '' || lName === '' || email === '' || phone === '' || age === '' || hear === ''){
            return false
        }

        // eslint-disable-next-line
        let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; 
        if(!email.match(mailformat)){
            toast.warn('Your email format is invalid')
            return true;
        }

        // eslint-disable-next-line
        let phoneFormat = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g
        if(!phone.match(phoneFormat)){
            toast.warn('Your phone format is invalid')
            return true;
        }

        if(country === ''){
            toast.warn('Please select your country.')
            return false
        }

        let param = {
            competition_id: cid,
            extra_info: JSON.stringify({
                First_Name: fName,
                Last_Name: lName,
                Email: email,
                Phone: phone,
                Country: country,
                Age: age,
                Hear: hear
            }),
            team_name: teamName
        }

        if(userDetail !== null){
            param.user_id = userDetail.User_ID
        }else{
            param.pseudo_id = getPseudoID()
        }

        if(selectedTeam !== null){
            param.team_id = selectedTeam.Team_ID
        }

        if(param.pseudo_id !== undefined){
            if(selectedTeam !== null){
                // [non-chatbot] save event on google analytics
                GoogleSaveEvent("Registration_Save", {
                    name: "Saving registration info",
                    pseudo_id: param.pseudo_id,
                    event_id: cid,
                    source: 'Landing Page',
                    condition: 'Join Team',
                    team_id: selectedTeam.Team_ID
                }, param.pseudo_id)
            }else{
                // [non-chatbot] save event on google analytics
                GoogleSaveEvent("Registration_Save", {
                    name: "Saving registration info",
                    pseudo_id: param.pseudo_id,
                    event_id: cid,
                    source: 'Landing Page',
                    condition: 'Creating Team',
                    team_name: teamName
                }, param.pseudo_id)
            }
        }else{
            if(selectedTeam !== null){
                // [chatbot] save event on google analytics
                GoogleSaveEvent("Registration_Save", {
                    name: "Saving registration info",
                    user_id: userDetail.User_ID,
                    event_id: cid,
                    source: 'Chatbot',
                    condition: 'Join Team',
                    team_id: selectedTeam.Team_ID
                }, userDetail.User_ID)
            }else{
                // [chatbot] save event on google analytics
                GoogleSaveEvent("Registration_Save", {
                    name: "Saving registration info",
                    user_id: userDetail.User_ID,
                    event_id: cid,
                    source: 'Chatbot',
                    condition: 'Creating Team',
                    team_name: teamName
                }, userDetail.User_ID)
            }
        }

        setLoading(true)
        fetch(process.env.REACT_APP_API_URL+'competition/register', {
            method: 'POST',
            body: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === 200){
                stepAction(stepConstant[0], null)
                window.onbeforeunload = null
                setTeacherLink(res.data.Teacher_Link)
                setIsDone(true)
            }else{
                toast.warn(res.messages)
            }
            setLoading(false)
        })
        .catch((e)=>{
            toast.error('Failed to register!')
        })
    }

    const handleFormChange = (e, data) =>{
        switch (data.name) {
            case 'f_name':
                setFName(data.value)
                break;
            case 'l_name':
                setLName(data.value)
                break;
            case 'email':
                setEmail(data.value)
                break;
            case 'phone':
                setPhone(data.value)
                break;
            case 'country':
                setCountry(data.value)
                break;
            case 'age':
                setAge(data.value)
                break;
            default:
                setHear(data.value)
                break;
        }
    }

    const getTeamList = () =>{
        setTeamLoading(true)
        fetch(process.env.REACT_APP_API_URL+'competition/getTeams?competition_id='+cid)
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            setOldTeamList(res.data)
            setTeamList(res.data)
            setTeamLoading(false)
        })
        .catch((e)=>{
            toast.error('Failed get team list!')
        })
    }

    const searchTeam = (e, data) =>{
        let team = oldTeamList
        setTeamList(team.filter(x=>x.Team_Name.toLocaleLowerCase().includes(data.value.toLocaleLowerCase())))
    }

    return(
        <div id="register_page">
            <section id="register_banner_2">
                <Segment basic className="banner">
                    <Container textAlign="center">
                        <Header as="h1" inverted>EVENT REGISTRATION</Header>
                    </Container>
                </Segment>
            </section>
            <Divider hidden/>
            {category === 'selc' ? 
                <section id="register_form">
                <Container textAlign="center">
                    {eventLoading ? 
                    <Dimmer active>
                        <Loader active size="large">Loading...</Loader>
                    </Dimmer>
                    : null}
                    
                    {/* step 1 */}
                    
                    {step === 1 ? 
                    <>
                    {userDetail !== null ? 
                    <>
                    <Header as="h1">
                        Hi, {userDetail.Name}!
                    </Header>
                    </>
                    : null}
                    
                    {modalLoading?
                        <Dimmer active>
                            <Loader>Getting event info...</Loader>
                        </Dimmer>
                        : null
                    }
                    <Header as="h2">
                        {regTitle}
                    </Header>
                    
                    <Divider hidden/>
                    <Grid columns={'equal'} id="stepOne">
                        {eventStatus === '' ? 
                        <>
                        <Grid.Row>
                            <Grid.Column/>
                            <Grid.Column>
                                <Button fluid primary size="huge" onClick={stepAction.bind(null, stepConstant[0])}
                                disabled={disableButton}>Create Team</Button>
                            </Grid.Column>
                            <Grid.Column/>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column/>
                            <Grid.Column>
                                <Button fluid primary size="huge" onClick={stepAction.bind(null, stepConstant[1])}
                                disabled={disableButton}>Join Team</Button>
                            </Grid.Column>
                            <Grid.Column/>
                        </Grid.Row>
                        </>
                        :
                        <Grid.Row>
                            <Grid.Column/>
                            <Grid.Column>
                                <Button fluid primary size="huge" onClick={()=>window.open('/selc5', '_blank')}>See event details</Button>
                            </Grid.Column>
                            <Grid.Column/>
                        </Grid.Row>
                        }
                    </Grid>
                    </>
                    : null}
                    {/* end of step 1 */}

                    {/* step 2 */}
                    {step === 2 ? 
                        isTeam ? 
                        <Grid columns='equal' id="stepTwo">
                            <Grid.Column/>
                            <Grid.Column>
                                <Header as="h1">What is your team name?</Header>
                                <Form size="large">
                                    <Popup position="bottom right" trigger={<Form.Input value={teamName} 
                                    onChange={(e, data)=>setTeamName(data.value)} maxLength="20" placeholder="Type your team name (max 20 char long)"/>} on="focus"
                                    content="By creating a team, you will be the Team Leader"
                                    />
                                    <Button primary size="large" onClick={checkTeamName}
                                    loading={loading} disabled={loading}>Create</Button>
                                    <Button secondary size="large" onClick={stepAction.bind(null, stepConstant[2])}><span>Back</span></Button>
                                </Form>
                            </Grid.Column>
                            <Grid.Column/>
                        </Grid>
                        :
                        <>
                        <Header as="h1">Choose your team!</Header>
                        <Input onChange={searchTeam} size="large" placeholder="Type here to search..." icon='search'/>
                        <br/>
                        <Divider hidden/>
                        <Grid columns='equal' id="stepTwoTeam">
                            <Grid.Column/>
                            <Grid.Column width="10">
                                {teamLoading ? <Segment basic id="teamLoadingWrapper">
                                    <Loader active size="large">Getting team info...</Loader>
                                </Segment> : 
                                <Grid columns='equal' className="teamList">
                                    {teamList.length > 0 ? 
                                    teamList.map((v, k)=>
                                    <Grid.Row key={k} className={v.Is_Team_Full ? "full" : ""}> 
                                        <Grid.Column width="3">
                                            {v.Is_Team_Full ? <Label>Full</Label> : 
                                            <Button fluid primary onClick={()=>{
                                                setSelectedTeam(v)
                                                setModalOpened(true)
                                                let pseudo_id = getPseudoID()
                                                if(pseudo_id !== null){
                                                    // [non-chatbot] save event on google analytics
                                                    GoogleSaveEvent("Team_List_Join", {
                                                        name: "Selecting team to join",
                                                        pseudo_id: pseudo_id,
                                                        event_id: cid,
                                                        source: 'Landing Page',
                                                        condition: 'Join Team'
                                                    }, pseudo_id)
                                                }else{
                                                    // [chatbot] save event on google analytics
                                                    GoogleSaveEvent("Team_List_Join", {
                                                        name: "Selecting team to join",
                                                        user_id: userDetail.User_ID,
                                                        event_id: cid,
                                                        source: 'Chatbot',
                                                        condition: 'Join Team'
                                                    }, userDetail.User_ID)
                                                }
                                            }}>Join</Button>}
                                        </Grid.Column>
                                        <Grid.Column textAlign="left">
                                            <Header as="h3">{v.Team_Name}</Header>
                                        </Grid.Column>
                                    </Grid.Row>
                                    )
                                    : <Grid.Column><Header as="h3" textAlign="center">No team found.</Header></Grid.Column>}
                                </Grid>
                                }
                                
                                <Divider hidden/>
                                <Button secondary size="large" onClick={stepAction.bind(null, stepConstant[2])}><span>Back</span></Button>

                            </Grid.Column>
                            <Grid.Column/>
                        </Grid>
                        </>
                    : null}
                    {/* end of step 2 */}

                    {/* step 3 */}
                    {step === 3 ? 
                    <Grid columns='equal' id="stepThree">
                        <Grid.Column/>
                        <Grid.Column width="10">
                            {isTeam ? 
                            <Header as="h2">You create team <b>{teamName}</b>.</Header>
                            : 
                            <Header as="h2">You are joining team <b>{teamName}</b>.</Header>
                            }
                            <Header as="p">Please fill in this form to complete your action.</Header>
                            <Form size="large">
                                <Form.Group widths='equal'>
                                    <Form.Input required placeholder='First name' name='f_name' value={fName} onChange={handleFormChange}/>
                                    <Form.Input required placeholder='Last name' name='l_name' value={lName} onChange={handleFormChange}/>
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Input required type='email' placeholder='Email address' name='email' value={email} onChange={handleFormChange}/>
                                    <Form.Input required placeholder='Phone number' name='phone' value={phone} onChange={handleFormChange}/>
                                </Form.Group>
                                <Form.Input type="number" required placeholder='Age' name='age' value={age} onChange={handleFormChange}/>
                                <Form.Select required
                                    search
                                    fluid
                                    name='country'
                                    value={country}
                                    options={_languages}
                                    placeholder='Select country'
                                    onChange={handleFormChange}
                                />
                                <Form.TextArea required placeholder='How did you hear about us?' name='hear' value={hear} onChange={handleFormChange}/>
                                <Button primary size="large" onClick={register} disabled={loading} loading={loading}>Save</Button>
                            </Form>
                        </Grid.Column>
                        <Grid.Column/>
                    </Grid>
                    : null}
                    {/* end of step 3 */}
                    
                    {/* success message */}
                    {isDone ? 
                    <div className="successStep">
                        {uid !== null ? 
                        <>
                        <Image src={require('../../assets/images/trumpet.svg').default} size="tiny" centered/>
                        <Header as="h1">
                            Congratulations! 
                        </Header>
                        <Header as="h3">
                            {regTitle.replace("Let's create your team", "You have been registered")}
                        </Header>

                        <Divider hidden/>
                        <Divider hidden/>
                        <Button primary size="massive" onClick={()=>{
                            if(eventDetail.Extra_Info === null){
                                toast.warn('No whatsapp url provided!')
                                return false    
                            }
                            if(selectedTeam !== null){
                                // [chatbot] save event on google analytics
                                GoogleSaveEvent("Registration_Enter_WA", {
                                    name: "clicking Enter WA button",
                                    user_id: userDetail.User_ID,
                                    event_id: cid,
                                    source: 'Chatbot',
                                    condition: 'Join Team'
                                }, userDetail.User_ID)
                            }else{
                                // [chatbot] save event on google analytics
                                GoogleSaveEvent("Registration_Enter_WA", {
                                    name: "clicking Enter WA button",
                                    user_id: userDetail.User_ID,
                                    event_id: cid,
                                    source: 'Chatbot',
                                    condition: 'Creating Team'
                                }, userDetail.User_ID)
                            }
                            window.open(eventDetail.Extra_Info.Whatsapp_URL, '_blank')
                        }}>Enter SELC arena</Button>
                        </>
                        :
                        <>
                        <Header as="h2" color="red">One more step to complete the registration!</Header>
                        <Divider hidden/>
                        <Header as="h3">Install <b>Telegram</b> first & Click <b>Enter Chatbot</b> button below</Header>
                        <Divider hidden/>
                        <Image src={require('../../assets/images/tel.svg').default} centered size="medium"/>
                        <Divider hidden/>
                        <Checkbox label="I have installed the Telegram" onChange={(e, data)=>setTelegramInstalled(data.checked)}/>
                        <Divider hidden/>
                        <Button primary size="massive" onClick={()=>{
                            if(selectedTeam !== null){
                                // [non-chatbot] save event on google analytics
                                GoogleSaveEvent("Registration_Enter_Chatbot", {
                                    name: "Saving registration info",
                                    pseudo_id: getPseudoID(),
                                    event_id: cid,
                                    source: 'Landing Page',
                                    condition: 'Join Team'
                                }, getPseudoID())
                            }else{
                                // [non-chatbot] save event on google analytics
                                GoogleSaveEvent("Registration_Enter_Chatbot", {
                                    name: "Saving registration info",
                                    pseudo_id: getPseudoID(),
                                    event_id: cid,
                                    source: 'Landing Page',
                                    condition: 'Creating Team'
                                }, getPseudoID())
                            }
                            window.open(TeacherLink, '_blank')
                        }} disabled={!TelegramInstalled}>Enter Chatbot</Button>
                        </>
                        }
                    </div>  
                    : null}
                    {/* end of success message */}

                </Container>

                {/* pop up confirmation */}
                <Modal size="tiny" open={modalOpened} className="modalRegister">
                    <Modal.Content>
                    <Container textAlign="center">
                        <Segment basic>
                        <Header as="h2">Do you want to join team <b>{selectedTeam !== null ? selectedTeam.Team_Name : ''}</b>?</Header>
                        <Divider hidden/>
                        <Button secondary size="large" onClick={()=>setModalOpened(false)}><span>Back</span></Button>
                        <Button primary size="large" onClick={()=>{
                            stepAction(stepConstant[1], null)
                            setTeamName(selectedTeam.Team_Name)
                            setModalOpened(false)
                            let pseudo_id = getPseudoID()
                            if(pseudo_id !== null){
                                // [non-chatbot] save event on google analytics
                                GoogleSaveEvent("Join_Confirmation_Join", {
                                    name: "Confirm team join",
                                    pseudo_id: getPseudoID(),
                                    event_id: cid,
                                    source: 'Landing Page',
                                    condition: 'Join Team'
                                }, getPseudoID())
                            }else{
                                // [chatbot] save event on google analytics
                                GoogleSaveEvent("Join_Confirmation_Join", {
                                    name: "Confirm team join",
                                    user_id: userDetail.User_ID,
                                    event_id: cid,
                                    source: 'Chatbot',
                                    condition: 'Join Team'
                                }, userDetail.User_ID)
                            }
                        }}>Join</Button>
                        </Segment>
                    </Container>
                    </Modal.Content>
                </Modal>
            </section>
            : <Header textAlign="center" as="h1">Coming Soon!</Header>}
            
        </div>
    )
}

export default RegisterPage