import {Divider, Header, List} from 'semantic-ui-react';

const LOSRules = () =>{
    return(
        <div>
            <Header as="h3">Backstory - Last One Standing</Header>
            <p>In a big city lives a lazy teenager. This excessive laziness damages the brain function caused by a virus that turns humans into zombies. This virus is contagious, the transmission is fast, and attacks lazy people with weak brain function.</p>
            <p>This virus spreads rapidly. People must form the Ed Troops as zombie hunters to whack the zombies. A crazy professor provides a total of USD1000 cumulatively for every human who joins the Ed Troops. To reach the total reward from the crazy professor, more humans need to join the troop.</p>
            <p>The Last One Standing will win the prize!</p>
            
            <Divider/>
            
            <Header as="h3">Players and Prize</Header>
            <p>The more people join, the more money will be added to the accumulated pot. 2 types of players on Last One Standing:</p>
            <div style={{marginLeft: 25}}>
                <List bulleted>
                    <List.Item>Players that complete the L1S daily mission and survive in the competitions will be called <b>‘Survivors’</b>.</List.Item>
                    <List.Item>Players that didn’t complete the L1S daily mission will get eliminated and turn into <b>‘Zombies’</b>.</List.Item>
                </List>
            </div>

            <Header as="h3">How to Survive and Eliminate the Zombies</Header>
            <div style={{marginLeft: 25}}>
                <List ordered>
                    <List.Item>All the players need to complete the L1S daily mission every day to survive in the competition.</List.Item>
                    <List.Item>Once the players fail to complete the L1S daily mission, they will be eliminated and get eaten by the zombies.</List.Item>
                    <List.Item>Players that missed the L1S daily mission have a chance to resurrect themselves with an extra life and rejoin the competitions by buying the Resurrection Tickets.</List.Item>
                    <List.Item>Stay in the game and be the last one standing to win.</List.Item>
                </List>
            </div>

            <Header as="h3">How to Join</Header>
            <p>Collect 10.000 points and exchange them for a ticket to join Ed Troop. How to buy a ticket for Last One Standing:</p>
            <div style={{marginLeft: 25}}>
                <List ordered>
                    <List.Item>Send "L1S" to @Ed_Academy_Support using <a rel="noreferrer" target="_blank" href='https://t.me/Ed_Academy_Support'>this link</a> to register.</List.Item>
                    <List.Item>The admin will give you instructions on how to buy the ticket.</List.Item>
                </List>
            </div>

            <Header as="h3">How to Buy L1S entry Ticket:</Header>
            <div style={{marginLeft: 25}}>
                <List ordered>
                    <List.Item>Contact Ed the learning bot <a rel="noreferrer" target="_blank" href='/join'>here</a>.</List.Item>
                    <List.Item>Click "Start Learning" to be assigned an Agent bot.</List.Item>
                    <List.Item>Type /redeem on your agent bot.</List.Item>
                    <List.Item>Choose no. 1 (Join Last One Standing).</List.Item>
                    <List.Item>Make sure you have 10.000 points to buy the ticket.</List.Item>
                </List>
            </div>
            <br/>
            <div>
                <b>Important Note:</b> <span>Ticket price up to 1K participants is 10,000 points. Afterwards, the ticket price will increase accordingly.</span>
            </div>

            <Header as="h3">How to Resurrect</Header>
            <p>Players can get Extra Life and rejoin the Ed Troops by exchanging points to get a Resurrection Ticket. How to get an Extra Live:</p>
            <div style={{marginLeft: 25}}>
                <List bulleted>
                    <List.Item>Referral: Get 10 people to register for L1S. Every time user refers their referral code to their friends, they will get points rewards that can be collected to buy resurrection tickets. Referrer and Referee will get 500 points after entering the referral code and get 20,000 points after the referee buys the L1S entry ticket.</List.Item>
                    <List.Item>Points: Exchange 200.000 points to buy one resurrection ticket.</List.Item>
                    <List.Item>Extra live expires after each resurrection period</List.Item>
                </List>
            </div>
            <br/>
            <b>Important Note:</b>
            <div style={{marginLeft: 25}}>
                <List bulleted>
                    <List.Item>The points needed for the resurrection will depend on the ticket price at that time and will increase throughout the game.</List.Item>
                    <List.Item>If you buy the L1S ticket without inputting the referral code, and input the referral code afterward, you will not get the 20.000 points for buying the L1S entry ticket because you didn't register through referral. You will only get 500 points for inputting the referral code.</List.Item>
                </List>
            </div>

            <Header as="h3">How to Win</Header>
            <div style={{marginLeft: 25}}>
                <List ordered>
                    <List.Item>Complete the L1S Daily Mission every day</List.Item>
                    <List.Item>Be the Last One Standing and Win Up to US$1000</List.Item>
                </List>
            </div>
            <br/>
            <div>
                <b>Important Note:</b> <span>The alternative ending of the game is if we still have more than 1 survivor, a tournament will be held to find the best Ed Troop as the zombie hunter in the world from all the survivors left.</span>
            </div>
        </div>
    )
}

export default LOSRules