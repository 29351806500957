import {Divider, Header, Icon} from 'semantic-ui-react'

export const TOC = () =>{
    return(
        <div id="toc">
            <Header as="h1" dividing>
                <Icon name="list" color="yellow"/> Terms and Condition
            </Header>

            <p>This web page represents a legal document that serves as our Terms of Service
                and it governs the legal terms of our website, <a href="https://solveeducation.org/"><strong>Solve Education!</strong></a>,
                sub-domains, and any associated web-based and mobile applications, including our application <strong><u>Dawn of Civilization</u></strong> and our telegram chatbot <b><u>Ed the Learning Bot</u></b> (collectively, "Website"), as owned and operated by Solve Education!.</p>

            <p>Capitalized terms, unless otherwise defined, have the meaning specified within
                the Definitions section below. This Terms of Service, along with our Privacy
                Policy, any mobile license agreement, and other posted guidelines within our
                Website, collectively "Legal Terms", constitute the entire and only agreement
                between you and Solve Education!, and supersede all other agreements,
                representations, warranties and understandings with respect to our Website and
                the subject matter contained herein. We may amend our Legal Terms at any
                time without specific notice to you. The latest copies of our Legal Terms will
                be posted on our Website, and you should review all Legal Terms prior to using
                our Website. After any revisions to our Legal Terms are posted, you agree to be
                bound to any such changes to them. Therefore, it is important for you to
                periodically review our Legal Terms to make sure you still agree to them.</p>

            <p>By accessing this web site, you are agreeing to be bound by these Website
                Terms and Conditions of Use, all applicable laws and regulations, and agree
                that you are responsible for compliance with any applicable local laws. If you
                do not agree with any of these terms, you are prohibited from using or
                accessing this site. The materials contained in this Website are protected by
                applicable copyright and trademark law.</p>

            <p>This T&C is an electronic record in terms of the Information Technology Act, 2000 (as amended / re-enacted) and rules thereunder, and is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries Guidelines and Digital Media Ethics code) Rules, 2021, which mandates publishing of rules and regulations, privacy policy and terms of use for access or usage of the App. This electronic record is generated by a computer system and does not require any physical or digital signature.</p>

            <p>Notice to Users of the Republic of India (Bhārat Gaṇarājya) Under G.S.R. 139(E):</p>

            <p>Intermediary Guidelines and Digital Media Ethics Code, users located in the Republic of India are entitled to the following Grievance Redressal Mechanism. If user's issue is not resolved even after contacting various complaint resolution channels, he/she can reach out to our grievance officer by contacting our grievance officer at the below email ID, address, and number. In your mail you must describe the issue in detail and provide any supporting document including screenshots that would assist with the resolution of your concern.</p>

            <p>Users from India can file their complaint on the below email ID and address: No 21 1st floor 3rd E Main Road, 9th Cross Ramaiah Layout Kammanahalli, Bangalore. Email ID -  <b>support@solveeducation.org</b></p>

            <p>If you have any questions about these Terms of Service or your account, please contact us at <b>support@solveeducation.org</b></p>

            <p>Our Terms of Service was last updated and posted on April 4, 2022.</p>

            <Divider hidden/>
            <Divider hidden/>

            <Header as="h2">Definitions</Header>
            <ul>
                <li>The terms "us" or "we" or "our" refers to Solve Education!, the owner of the
                    Website</li>

                <li>A "Visitor" is someone who merely browses our Website, but has not
                    registered as Member.</li>

                <li>A "Member" is an individual that has registered with us to use our Service.</li>

                <li>Our "Service" represents the collective functionality and features as offered
                    through our Website to our Members.</li>

                <li>A "User" is a collective identifier that refers to either a Visitor or a Member.</li>

                <li>All text, information, graphics, audio, video, and data offered through our
                    Website are collectively known as our "Content".</li>
            </ul>
            <Divider hidden/>
            <Divider hidden/>

            <Header as="h2">Use License</Header>
            <ol>
                <li>Permission is granted to temporarily download one copy of the materials
                    (information or software) on Solve Education!’s Website for personal,
                    non-commercial transitory viewing only. This is the grant of a license,
                    not a transfer of title, and under this license you may not:
                    <ol>
                        <li>modify or copy the materials;</li>
                        <li>use the materials for any commercial purpose, or for any public
                            display (commercial or non-commercial);</li>
                        <li>attempt to decompile or reverse engineer any software contained
                            on Solve Education!’s web site;</li>
                        <li>remove any copyright or other proprietary notations from the
                            materials; or</li>
                        <li>transfer the materials to another person or “mirror” the materials
                            on any other server.</li>
                    </ol>
                </li>
                <li>This license shall automatically terminate if you violate any of these
                    restrictions and may be terminated by Solve Education! at any time.
                    Upon terminating your viewing of these materials or upon the
                    termination of this license, you must destroy any downloaded materials
                    in your possession whether in electronic or printed format.</li>
            </ol>
            <Divider hidden/>
            <Divider hidden/>

            <Header as="h2">Restricted Uses</Header>
            <p>Listing of offered products on the Website could be used only for lawful
                purposes by Users of the Website. You could not frame or utilize framing
                techniques to enclose any hallmark, logo, copyrighted image, or most
                proprietary details (consisting of images, text, page layout, or type) of Solve
                Education! without express composed consent. You might not use any meta
                tags or any various other "unseen text" utilizing Solve Education!'s name or
                trademarks without the express written consent of Solve Education!. You agree
                not to offer or modify any content found on the Website consisting of, however
                not limited to, names of Users and Content, or to recreate, display, openly
                perform, distribute, or otherwise make use of the Material, in any way for any
                public function, in connection with services or products that are not those of
                Solve Education!, in other way that is likely to trigger confusion among
                consumers, that disparages or challenges Solve Education! or its licensors, that
                dilutes the strength of Solve Education!'s or its licensor's residential property,
                or that otherwise infringes Solve Education!'s or its licensor's copyright rights.You also agree to abstain from abusing any of the material that appears on the
                Site. The use of the Material on any other website or in a networked computer
                system environment for any purpose is prohibited. Any code that Solve
                Education! develops to generate or show any Material of the pages making up
                the Website is likewise secured by Solve Education!'s copyright, and you may
                not copy or adjust such code.</p>

            <p>Solve Education! has no duty to keep track of any products published,
                transferred, or connected to or with the Site. If you think that something on the
                Website breaches these Terms please contact our marked representative as set
                forth below.</p>

            <p>If alerted by a User of any products which allegedly do not conform to these
                Terms, Solve Education! could in its single discernment explore the allegation
                and figure out whether to take other actions or ask for the removal or get rid of
                the Content. Solve Education! has no liability or duty to Individuals for
                efficiency or nonperformance of such activities.</p>
            <Divider hidden/>
            <Divider hidden/>

            <Header as="h2">Electronic Communication</Header>
            <p>You are connecting with us electronically when you go to the Website or send
                out emails to us. You consent to get interactions from us online. We will
                connect with you by email or by uploading notifications on the Site. You
                concur that all contracts notifications, disclosure, and various other
                communicates that we provide to you digitally please any legal requirements
                that such communications be in writing.</p>
            <Divider hidden/>
            <Divider hidden/>

            <Header as="h2">Your Account</Header>
            <p>If you utilize the Website, you are accountable for maintaining the
                confidentiality of your account and password and you accept responsibility for
                all activities that happen under your account and password. You also accept not
                to reveal any personally identifiable information, consisting of, however not
                limited to, first and last names, credentials, or various other details of a
                personal nature ("Personal Data") from the Site. Your disclosure of any
                Personal Data on the website might result in the immediate termination of your
                account. Solve Education! additionally reserves the right to refuse service,
                terminate accounts, and remove or edit Content at it's sole discernment.</p>

            <p>Solve Education! does not guarantee the truthfulness or represent, precision, or
                dependability of Content on the site, consisting of Personal Data. Each
                Individual is accountable for upgrading and changing any pertinent account
                info when essential to preserve the truthfulness, precision, or reliability of the
                details.</p>
            <Divider hidden/>
            <Divider hidden/>

            <Header as="h2">Reviews, Comments, and Other Material</Header>
            <p>Registered Users of the Website might post evaluations and remarks of a
                product and services purchased by means of the Website, so long as the
                Material is not unlawful, profane, threatening, defamatory, an invasive of
                privacy, infringing of intellectual property rights, or otherwise injurious to third
                parties or objectionable and does not include or include industrial solicitation,
                mass mailings, or any type of "spam." You may not use another User's account
                to impersonate a User or entity, or otherwise deceived as to the origin of the
                opinions. Solve Education! books the right (however is not bound) to eliminate
                or modify such Material, but does not regularly examine posted Material.</p>

            <p>If you post an evaluation or send comments, and unless Solve Education!
                suggests otherwise, you grant Solve Education! a nonexclusive, royalty-free,
                permanent, irrevocable, and completely sublicensable right to utilize, recreate,
                modify, adjust, release, equate, create derivative works from, distribute, and
                screen such content throughout the world, in any media. You grant Solve
                Education! and sublicenses the right to utilize your name in connection with
                such Material, if they choose. You represent and require that You own or
                otherwise control all the rights to the content that You post; that the content is
                accurate; that use of the content You supply does not violate this policy and
                will not trigger injury to anyone or entity; which You will indemnify Solve
                Education! for all claims resulting from Content You supply. Solve Education!
                has the right but not the commitment to edit and keep track of or eliminate any
                task or Material. Solve Education! takes no duty and assumes no liability for
                any content published by You or any 3rd party.</p>
            <Divider hidden/>
            <Divider hidden/>

            <Header as="h2">Legal Compliance</Header>
            <p>You agree to comply with all applicable domestic and international laws,
                statutes, ordinances, and regulations regarding your use of our Website. Solve
                Education! reserves the right to investigate complaints or reported violations of
                our Legal Terms and to take any action we deem appropriate, including but not
                limited to canceling your Member account, reporting any suspected unlawful
                activity to law enforcement officials, regulators, or other third parties and
                disclosing any information necessary or appropriate to such persons or entities
                relating to your profile, email addresses, usage history, posted materials, IP
                addresses and traffic information, as allowed under our Privacy Policy.</p>
            <Divider hidden/>
            <Divider hidden/>

            <Header as="h2">Intellectual Property</Header>
            <p>Our Website may contain our service marks or trademarks as well as those of
                our affiliates or other companies, in the form of words, graphics, and logos.
                Your use of our Website does not constitute any right or license for you to use
                such service marks/trademarks, without the prior written permission of thecorresponding service mark/trademark owner. Our Website is also protected
                under international copyright laws. The copying, redistribution, use or
                publication by you of any portion of our Website is strictly prohibited. Your
                use of our Website does not grant you ownership rights of any kind in our
                Website.</p>
            <Divider hidden/>
            <Divider hidden/>

            <Header as="h2">Revisions and Errata</Header>
            <p>The materials appearing on Solve Education!’s Website could include
                technical, typographical, or photographic errors. Solve Education! does not
                warrant that any of the materials on its Website are accurate, complete, or
                current. Solve Education! may make changes to the materials contained on its
                Website at any time without notice. Solve Education! does not, however, make
                any commitment to update the materials.</p>
            <Divider hidden/>
            <Divider hidden/>

            <Header as="h2">Disclaimer</Header>
            <p>The materials on <strong>Solve Education!</strong>'s Website are provided "as is" <strong>Solve
                Education!</strong> makes no warranties, expressed or implied, and hereby disclaims
                and negates all other warranties, including without limitation, implied
                warranties or conditions of merchantability, fitness for a particular purpose, or
                non-infringement of intellectual property or other violation of rights.
                Further, <strong>Solve Education!</strong> does not warrant or make any representations
                concerning the accuracy, likely results, or reliability of the use of the materials
                on its Internet Website or otherwise relating to such materials or on any sites
                linked to this site. The Website serves as a venue for Individuals to purchase
                distinct service or products. Neither Solve Education! nor the Website has
                control over the quality or fitness for a particular function of a product. Solve
                Education! likewise has no control over the accuracy, reliability, completeness,
                or timeliness of the User-submitted details and makes no representations or
                warranties about any info on the Site.</p>

            <p>THE WEBSITE AND ALL DETAILS, CONTENT, MATERIALS,
                PRODUCTS (INCLUDING SOFTWARE APPLICATION) AND SERVICES
                CONSISTED OF ON OR OTHERWISE MADE AVAILABLE TO YOU
                THROUGH THIS WEBSITE ARE PROVIDED BY Solve Education! ON AN
                "AS IS" AND "AS AVAILABLE" BASIS, UNLESS OTHERWISE
                SPECIFIED IN WRITING. Solve Education! MAKES NO
                REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR
                IMPLIED, ABOUT THE OPERATION OF THIS Website OR THE INFO,
                MATERIAL, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR
                SERVICES CONSISTED OF ON OR OTHERWISE MADE AVAILABLE
                TO YOU THROUGH THIS SITE, UNLESS OTHERWISE POINTED OUTIN WRITING. YOU EXPRESSLY AGREE THAT YOUR USE OF THIS
                WEBSITE IS AT YOUR OWN RISK.
                TO THE COMPLETE EXTENT PERMISSIBLE BY APPLICABLE LAW,
                Solve Education! DISCLAIMS ALL WARRANTIES, EXPRESS OR
                IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED
                WARRANTIES OF MERCHANTABILITY AND PHYSICAL FITNESS FOR
                A PARTICULAR PURPOSE. Solve Education! DOES NOT WARRANT
                THAT THIS WEBSITE; DETAILS, CONTENT, MATERIALS, PRODUCTS
                (INCLUDING SOFTWARE APPLICATION) OR SERVICES CONSISTED
                OF ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE
                SITE; ITS SERVERS; OR EMAIL SENT FROM Solve Education! ARE
                WITHOUT VIRUSES OR OTHER HARMFUL ELEMENTS. Solve
                Education! WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND
                ARISING FROM THE USE OF THE Website OR FROM ANY DETAILS,
                CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE
                APPLICATION) OR SERVICES CONSISTED OF ON OR OTHERWISE
                MADE AVAILABLE TO YOU WITH THIS SITE, INCLUDING, BUT NOT
                LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND
                CONSEQUENTIAL DAMAGES, UNLESS OTHERWISE POINTED OUT IN
                WRITING. UNDER NO SCENARIO SHALL Solve Education!'S LIABILITY
                DEVELOPING FROM OR IN CONNECTION WITH THE WEBSITE OR
                YOUR USE OF THE WEBSITE, DESPITE THE REASON FOR ACTION
                (WHETHER IN AGREEMENT, TORT, BREACH OF SERVICE
                WARRANTY OR OTHERWISE), GO BEYOND $100.</p>
            <Divider hidden/>
            <Divider hidden/>

            <Header as="h2">Links to Other Websites</Header>
            <p>Our Website may contain links to third party websites. These links are provided
                solely as a convenience to you. By linking to these websites, we do not create
                or have an affiliation with, or sponsor such third party websites. The inclusion
                of links within our Website does not constitute any endorsement, guarantee,
                warranty, or recommendation of such third party websites. Solve Education!
                has no control over the legal documents and privacy practices of third party
                websites; as such, you access any such third party websites at your own risk.</p>
            <Divider hidden/>
            <Divider hidden/>

            <Header as="h2">Site Terms of Service Modifications</Header>
            <p>Solve Education! may revise these Terms of Service for its Website at any time
                without notice. By using this Website you are agreeing to be bound by the then
                current version of these Terms and Conditions of Use.</p>
            <Divider hidden/>
            <Divider hidden/>

            <Header as="h2">Governing Law</Header>
            <p>Any claim relating to Solve Education!’s Website shall be governed by thelaws of Singapore without regard to its conflict of law provisions, and You
                consent to exclusive jurisdiction and venue in such courts.</p>
            <Divider hidden/>
            <Divider hidden/>

            <Header as="h2">Indemnity</Header>
            <p>You accept defend, indemnify, and hold safe Solve Education!, its affiliates,
                and their corresponding officers, directors, agents and workers, from and
                against any claims, actions or demands, including without limitation affordable
                legal, accounting, and other provider charges, affirming or resulting from (i)
                any Content of most material You offer to the Site, (ii) Your use of any
                Content, or (iii) Your breach of the terms of these Terms. Solve Education! will
                provide notice to You promptly of any such claim, match, or case.</p>
            <Divider hidden/>
            <Divider hidden/>

            <Header as="h2">General Terms</Header>
            <p>Our Legal Terms shall be treated as though it were executed and performed in
                Singapore and shall be governed by and construed in accordance with the laws
                of Singapore without regard to conflict of law principles. In addition, you agree
                to submit to the personal jurisdiction and venue of such courts. Any cause of
                action by you with respect to our Website, must be instituted within one (1)
                year after the cause of action arose or be forever waived and barred. Should any
                part of our Legal Terms be held invalid or unenforceable, that portion shall be
                construed consistent with applicable law and the remaining portions shall
                remain in full force and effect. To the extent that any Content in our Website
                conflicts or is inconsistent with our Legal Terms, our Legal Terms shall take
                precedence. Our failure to enforce any provision of our Legal Terms shall not
                be deemed a waiver of such provision nor of the right to enforce such
                provision. The rights of Solve Education! under our Legal Terms shall survive
                the termination of our Legal Terms</p>

            <Divider hidden/>
            <Divider hidden/>

            <Header as="h2">General Terms for using our chatbots:</Header>
            <Header as="h3">The way of using our chatbots:</Header>
            <ul>
                <li>Ed the Learning Bot provides users with access to learning content materials.</li>
                <li>You must access and use our Services only for legal, authorized, and acceptable purposes.</li>
                <li>You will not use (or assist others in using) our Services in ways that: (a) violate, misappropriate our users, or others, including privacy, publicity, intellectual property, or other proprietary rights; (b) are illegal, obscene, defamatory, threatening, intimidating, harassing, hateful, racially or ethnically offensive, or instigate or encourage conduct that would be illegal or otherwise inappropriate, such as promoting violent crimes, endangering or exploiting children or others, or coordinating harm; (c) involve publishing falsehoods, misrepresentations, or misleading statements; (d) impersonate someone; (e) involve sending illegal or impermissible communications such as bulk messaging, auto-messaging, auto-dialing, and the like; or (f) involve any non-personal use of our Services unless otherwise authorized by us.</li>
                <li>The user is fully responsible for any and all contents, phrases, and entries (“Input”) added to the chatbot with the use of offered Services.</li>
                <li>Ed the Learning Bot team reserves the right to ban the user if we detect any illegal activity of the users and any activity that is not acceptable under our Terms and Services.</li>
                <li>The user fully understands that they will not misuse Ed the Learning Bot by sending bulk messaging, auto-messaging, auto-dialing, and the like to spam the bot. We will give warnings and may ban the user if we detect these behaviors.</li>
                <li>The user is prohibited to promote or advertise other products or services inside Ed the Learning Bot.</li>
                <li>Users are responsible for maintaining the privacy and security of their accounts.</li>
                <li>Ed the Learning Bot reserve the right to deny access to chatbots and remove Content without undertaking any obligation in this respect</li>
                <li>Ed the Learning Bot reserves the right to update the Terms of Services and all applicable policies at any time by amending this page as our Chatbot Services are still evolving, and so we need flexibility to occasionally make changes to our chatbot solutions in order to expand, improve and develop our services. Ed the Learning Bot will try to give notice of these changes, but please check this page and visit the most current versions of the other pages that are related from time to time to take notice of any changes, as they are binding on you. It is also worth following our social media accounts and pages for updates;</li>
            </ul>
        </div>
    )
}
