import { useEffect, useState } from "react"
import { Dimmer, Header } from "semantic-ui-react"
import ReactGA from 'react-ga'

const TrackingPage = () =>{
    var ct = 3
    const [counter, setCounter] = useState(3)
    const queryString = window.location.search
    let urlParam = new URLSearchParams(queryString);
    const link = urlParam.get('link')
    const id = urlParam.get('id')
    const category = urlParam.get('category')

    useEffect(()=>{
        if(link === null || id === null || category === null){
            window.location.href = '/'
            return false
        }

        goToLink()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const goToLink = () =>{
        let time = setInterval(()=>{
            ct = ct-1
            setCounter(ct)
        }, 1000)

        
        setTimeout(()=>{
            clearInterval(time)

            ReactGA.event({
                category: category,
                action: JSON.stringify({
                    link: link,
                    id: id
                }),
                value: id
            });

            let convertedLink = link.replace(/~/g, "&")
            window.location.href = convertedLink
        }, 3000)
    }

    return(
        <Dimmer active id="joinLoader">
            <Header as="h1">You will be redirected in {counter} second(s).</Header>
        </Dimmer>
    )
}

export default TrackingPage