import {Segment, Image, Container, Grid, Search, Form, Header, Divider, Table, Icon, Popup, Dimmer, Loader, Message} from 'semantic-ui-react'
import banner from '../../../assets/images/timeline/Logo.png'
import banner2 from '../../../assets/images/timeline/Zombie_1.png'
import banner3 from '../../../assets/images/timeline/Survivor_1.png'
import { toast } from 'react-toastify'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import moment from 'moment'
import { useEffect, useState } from 'react'
import JoinIcon from '../../../assets/images/timeline/Join_Icon.png'
import ExtraLifeIcon from '../../../assets/images/timeline/ExtraLife_Icon.png'
import ResurrectIcon from '../../../assets/images/timeline/Resurrect_Icon.png'
import SurvivorIcon from '../../../assets/images/timeline/Survivor_Icon.png'
import ZombieIcon from '../../../assets/images/timeline/Zombie_Icon.png'
import NotFoundIcon from '../../../assets/images/timeline/No Activity_Illustration.png'

const LastOneStandingJourney = () =>{
    const categories = [
        {
            key: 'all',
            text: 'All',
            value: 'all'
        },
        {
            key: 'join',
            text: 'Join',
            value: 'join'
        },
        {
            key: 'survive',
            text: 'Survivor',
            value: 'survive'
        },
        {
            key: 'deceased',
            text: 'Zombie',
            value: 'deceased'
        },
        {
            key: 'resurrect',
            text: 'Resurrect',
            value: 'resurrect'
        },
        {
            key: 'gain_extra_live',
            text: 'Extra Life',
            value: 'gain_extra_live'
        }
    ]

    const [isLoading, setIsLoading] = useState(false)
    const [searchVal, setSearchVal] = useState('')
    const [searchResult, setSearchResult] = useState([])
    const [isSelected, setIsSelected] = useState(false)
    const [idSelected, setIdSelected] = useState(null)
    const [dateSelected, setDateSelected] = useState(new Date())
    const [filterDate, setFilterDate] = useState(moment().format('YYYY-MM-DD'))
    const [filterType, setFilterType] = useState('all')
    const [applyLoading, setApplyLoading] = useState(true)
    const [timelines, setTimelines] = useState([])
    const [timezone, setTimezone] = useState({
        text: 'Asia/Jakarta',
        time: 'GMT+7'
    })

    useEffect(() => {
        loadTimeline()
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    const handleResultSelect = (e, { result }) =>{
        setIsSelected(true)
        setSearchVal(result.title+' ('+result.description+')')
        setIdSelected(result.id)
    }

    const handleSearchChange = (e, { value })=>{
        if(value === ''){
            setIdSelected(null)
        }
        setIsSelected(false)
        setSearchVal(value)
        setIsLoading(true)
        fetch(process.env.REACT_APP_API_URL+'challenge/searchLastOneStandingParticipants',{
            method: 'post',
            body: JSON.stringify({query: value}),
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        }) 
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === 200){
                let temp = []
                res.data.forEach(x=>{
                    temp.push({
                        id: x.User_ID,
                        title: x.Name,
                        description: x.Username
                    })
                })

                setSearchResult(temp)
            }else{
                setSearchResult([])
            }
            setIsLoading(false)
        })
        .catch((e)=>{
            toast.error('Something wrong on searching username, please contact developer!')
        })
    }

    const onChangeDate = (event, data) =>{
        setDateSelected(data.value)
        setFilterDate(moment(data.value).format('YYYY-MM-DD'))
    }

    const onChangeType = (e, data) =>{
        setFilterType(data.value)
    }

    const applyFilter = () =>{
        setApplyLoading(true)
        loadTimeline()
    }

    const loadTimeline = () =>{
        let param = {}
        if(idSelected !== null){
            param.user_id = idSelected
        }
        if(filterType !== 'all'){
            param.category = filterType
        }
        param.date = filterDate

        fetch(process.env.REACT_APP_API_URL+'challenge/getLastOneStandingTimeline', {
            method: 'post',
            body: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === 200){
                setTimelines(res.data)
                setTimezone(res.timezone)
            }else{
                setTimelines([])
            }
            setApplyLoading(false)
        })
        .catch((e)=>{
            toast.error('Something wrong when fetching timeline data, please contact developer')
        })
    }

    const generateTableTimeline = (param) =>{
        let logo = null
        let typeText = 'Join'
        let template = null
        switch (param.Category) {
            case 'gain_extra_live':
                logo = ExtraLifeIcon
                typeText = 'Extra Life'
                template = <Table basic>
                    <Table.Body>
                    <Table.Row>
                        <Table.Cell>Name</Table.Cell>
                        <Table.Cell>{param.Name}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Referral Code</Table.Cell>
                        <Table.Cell>{param.Referral_Code}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Referral This Week</Table.Cell>
                        <Table.Cell>
                            {param.Referral_This_Week.Person !== undefined ? <>{param.Referral_This_Week.Person} <Icon name='user' color='blue'/>, </> : null}
                            {param.Referral_This_Week.Ticket !== undefined ? <>{param.Referral_This_Week.Ticket} <Icon name='ticket' color='pink'/></> : null}
                            <Popup trigger={<Icon link name='info circle'/>} content='Total number of users who used your referral code this week' basic/>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Referral Total</Table.Cell>
                        <Table.Cell>
                            {param.Referral_Total.Person !== undefined ? <>{param.Referral_Total.Person} <Icon name='user' color='blue'/> <Popup trigger={<Icon link name='info circle'/>} content='Total number of users who used your referral code in the last one standing event' basic/>, </> : null}
                            {param.Referral_Total.Ticket !== undefined ? <>{param.Referral_Total.Ticket} <Icon name='ticket' color='pink'/> <Popup trigger={<Icon link name='info circle'/>} content='Total number of users who used your referral code and buy the tickets to join last one standing event' basic/></> : null}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Referral List</Table.Cell>
                        <Table.Cell>{param.Referral_List}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Resurrect Ticket</Table.Cell>
                        <Table.Cell>
                            {param.Resurrect_Ticket.map((v, k)=>
                                <p key={k}>{v.Amount} <Icon name='ticket' color='pink'/>, (Buy on {v.Period_Start} - {v.Period_End})</p>
                            )}
                            {param.Resurrect_Ticket.length === 0 ? 'N/A' : null}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Expiry Date</Table.Cell>
                        <Table.Cell>
                            {param.Expiry_Date.map((v, k)=>
                                <p key={k}>{v.Amount} <Icon name='ticket' color='pink'/>, {v.Expiry_Date} <Popup trigger={<Icon link name='info circle'/>} content={v.Amount+' ticket(s) will be expired on '+v.Expiry_Date} basic/></p>
                            )}
                            {param.Expiry_Date.length === 0 ? 'N/A' : null}
                        </Table.Cell>
                    </Table.Row>
                    </Table.Body>
                </Table>
                break;  
            case 'resurrect':
                logo = ResurrectIcon
                typeText = 'Resurrect'
                template = <Table basic>
                    <Table.Body>
                    <Table.Row>
                        <Table.Cell>Name</Table.Cell>
                        <Table.Cell>{param.Name}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Zombie Days</Table.Cell>
                        <Table.Cell>{param.Zombie_Days}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Ticket needed for Resurrection</Table.Cell>
                        <Table.Cell>{param.Ticket_Needed_For_Resurrection} <Icon name='ticket' color='pink'/></Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Purchase Ticket</Table.Cell>
                        <Table.Cell>
                            {param.Purchase_Ticket.Amount !== undefined && param.Purchase_Ticket.Datetime ? <>{param.Purchase_Ticket.Amount} <Icon name='ticket' color='pink'/> {param.Purchase_Ticket.Datetime}</> : null}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Resurrect Time</Table.Cell>
                        <Table.Cell>{param.Resurrect_Time}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Remaining Ticket</Table.Cell>
                        <Table.Cell>
                            {param.Remaining_Ticket.map((v, k)=>
                                <p key={k}>{v.Amount} <Icon name='ticket' color='pink'/> ({v.Period_Start} - {v.Period_End}) <Popup trigger={<Icon link name='info circle'/>} content='The number of tickets for resurrection will increase each time you miss completing the mission. 1 ticket for each day missed.' basic/></p>
                            )}
                            {param.Remaining_Ticket.length === 0 ? 'N/A' : null}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Expiry Date</Table.Cell>
                        <Table.Cell>
                            {param.Expiry_Date.map((v, k)=>
                                <p key={k}>{v.Amount} <Icon name='ticket' color='pink'/>, {v.Expiry_Date}</p>
                            )}
                            {param.Expiry_Date.length === 0 ? 'N/A' : null}
                        </Table.Cell>
                    </Table.Row>
                    </Table.Body>
                </Table>
                break;
            case 'survive':
                logo = SurvivorIcon
                typeText = 'Survivor'
                template = <Table basic>
                    <Table.Body>
                    <Table.Row>
                        <Table.Cell>Name</Table.Cell>
                        <Table.Cell>{param.Name}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Complete Mission</Table.Cell>
                        <Table.Cell>{param.Mission}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Date & Time</Table.Cell>
                        <Table.Cell>{param.Datetime}</Table.Cell>
                    </Table.Row>
                    </Table.Body>
                </Table>
                break;
            case 'deceased':
                logo = ZombieIcon
                typeText = 'Zombie'
                template = <Table basic>
                    <Table.Body>
                    <Table.Row>
                        <Table.Cell>Name</Table.Cell>
                        <Table.Cell>{param.Name}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Failed Mission</Table.Cell>
                        <Table.Cell>{param.Mission}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Date & Time</Table.Cell>
                        <Table.Cell>{param.Datetime}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Day(s) Failed Mission</Table.Cell>
                        <Table.Cell>{param.Failed_Mission} <Popup trigger={<Icon link name='info circle'/>} content='The total number of daily missions you failed to complete. You need 1 ticket to resurrect for each failed mission' basic/></Table.Cell>
                    </Table.Row>
                    </Table.Body>
                </Table>
                break;
            // join
            default:
                logo = JoinIcon
                template = <Table basic>
                    <Table.Body>
                    <Table.Row>
                        <Table.Cell>Name</Table.Cell>
                        <Table.Cell>{param.Name}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Referral Code</Table.Cell>
                        <Table.Cell>{param.Referral_Code}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Join Date & Time</Table.Cell>
                        <Table.Cell>{param.Join_Date}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Friend’s Referral Code</Table.Cell>
                        <Table.Cell>{param.Friend_Referral_Code}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Friend’s Referral Code Applied</Table.Cell>
                        <Table.Cell>{param.Friend_Referral_Code_Applied}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Purchase Ticket</Table.Cell>
                        <Table.Cell>{param.Purchase_Ticket}</Table.Cell>
                    </Table.Row>
                    </Table.Body>
                </Table>
                break;
        }

        let html = <>
        <div className='timelineLogo'>
            <Image src={logo}/>
        </div>
        <Segment.Group>
            <Segment className={param.Category+'_title'}>
                <Header as="h2" textAlign='center'>{typeText}</Header>
            </Segment>
            <Segment className={param.Category+'_content'}>
            {template}
            </Segment>
        </Segment.Group>
        </>
        return html
    }

    return(
        <div id="one_last_standing_j">
            <section id="banner_j">
                <Segment basic className="bannerBackground_j">
                    <Container textAlign="center">
                        <Image src={banner} centered size="large" id="banner1"/>
                        <div className='bottomImg'>
                        <Image src={banner3} size='medium' centered id="banner3"/>
                        <Image src={banner2} size='small' centered id="banner2"/>
                        </div>
                    </Container>
                </Segment>
            </section>
            <section id="content_j">
                <Grid columns="equal">
                    <Grid.Column width={3} className="sidePattern">
                    </Grid.Column>
                    <Grid.Column id="main_column_j">
                        <Segment basic textAlign='center'>
                            <Header as="p">Search your name here to see your journey of last one standing</Header>
                            <Form>
                                <Form.Field>
                                <Search
                                    id="search-username"
                                    size='big'
                                    fluid
                                    input={{ icon: 'search' }}
                                    loading={isLoading}
                                    onResultSelect={handleResultSelect}
                                    onSearchChange={handleSearchChange}
                                    results={searchResult}
                                    value={searchVal}
                                    placeholder="Type username..."
                                    onBlur={()=>{
                                        if(!isSelected){
                                            setSearchVal('')
                                            setIdSelected(null)
                                        }
                                    }}
                                />
                                </Form.Field>
                                <Form.Group widths='equal'>
                                    <SemanticDatepicker onChange={onChangeDate} datePickerOnly
                                    id="filter-date" size='big' value={dateSelected} clearable={false} placeholder="Select date..."/>
                                    <Form.Dropdown size="big" id="filter-type" fluid selection onChange={onChangeType}
                                    options={categories} value={filterType}/>
                                    
                                </Form.Group>
                                <Form.Button floated='right' size='big' id="filter-apply" onClick={applyFilter} loading={applyLoading} 
                                    disabled={applyLoading} primary content='Apply'/>
                            </Form>
                            <Divider hidden/>
                            <Divider hidden/>
                            <Divider hidden/>
                            <Header as="p">Maximum 25 activities will be shown here</Header>
                            <Message info size='mini'>
                            All times shown on this page are on <b>{timezone.text} ({timezone.time})</b> time.
                            </Message>
                        </Segment>
                        <Divider hidden/>
                        <Divider hidden/>
                        <Divider hidden/>
                        {applyLoading ? <Segment style={{minHeight: '50vh'}} textAlign='center' basic padded>
                        <Dimmer active inverted>
                            <Loader size='huge' active content="Fetching timeline, please wait..."/>
                        </Dimmer>
                        </Segment> : timelines.length > 0 ? 
                        <div id="timelineWrapper">
                            {timelines.map((v, k)=>{
                                k = k+1
                                let templates = <div className="t_container right" key={k}>
                                    {generateTableTimeline(v)}
                                </div>
                                if(k%2){
                                    templates = <div className="t_container left" key={k}>
                                        {generateTableTimeline(v)}
                                    </div>
                                }

                                return templates
                            })}
                        </div>
                        : <Segment basic textAlign='center'>
                        <Image src={NotFoundIcon} size='large' centered/>
                        <Header as="h2">There is no activity yet! Let's complete the mission to avoid becoming a zombie!</Header>    
                        </Segment>}
                    </Grid.Column>
                    <Grid.Column width={3} className="sidePattern_R">
                    </Grid.Column>
                </Grid>
            </section>
        </div>
    )
}

export default LastOneStandingJourney