import { createRef, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { Container, Header, Segment, Image, Grid, Divider, List, 
    Button, Dimmer, Menu, Modal, Icon, Loader, Accordion } from "semantic-ui-react"
import moment from 'moment'
import instagram from '../../../../assets/images/Instagram.svg'
import "./style.scss";
import SlideShowComponent from "../../../../components/ImageSlider"


function SELC5Page(){
    const joinImages = [
        "selc/how_to_join/1.svg",
        "selc/how_to_join/2.svg",
        "selc/how_to_join/3.svg",
        "selc/how_to_join/4.svg",
        "selc/how_to_join/5.svg",
        "selc/how_to_join/6.svg"
    ]
    const joinLabels = [
        "",
        "Create your own team or join a team",
        "Fill-in the form",
        "Install Telegram & enter the chatbot",
        "Click <b>START</b>",
        "Click <b>Enter SELC Arena</b>"
    ]
    const rulesData = [
        {
            header: 'Stage 1 - Team video',
            desc: 'To show your optimism to win and excitement to participate',
            contents: [
                'Create a TikTok video and tag Solve Education!',
                'Solve Education!’s account will repost the video. Get 100 likes (organically) on the reposted video for 50 points!',
                'Theme: Team introduction, tagline, what have you prepared for the competition, and ask people to sign up, and visit Solve Education! page for updates, and like this video.'
            ]
        },
        {
            header: 'Stage 2 - Learning Pot',
            contents: [
                'Participating teams must fill their team pot to a <b>minimum of 3100 points</b> to qualify for any prize. The pot has no maximum size. The team with the largest pot will win the final prize. Any team that hits the minimum pot size will qualify for the runners-up and consolation prizes;',
                'You must complete the daily mission to add points to the learning pot. A <b>team member can contribute only 1 point per day</b>, while <b>each new buddies</b> (new Ed users who use your “buddies link” to play with Ed)  you get to complete the daily mission will contribute 2 points to your team pot. To get the “buddies link”, you need to complete your registration for this event.',
                'You can get <b>existing Ed users</b> to use your buddies link. If you get them to complete the daily mission, they will contribute 1 point to your team pot.',
                'A team of 5 can add a maximum of 5 points to the pot daily. If they stay consistent, they can add 100 points to the pot over 20 days without getting new <b>buddies</b> to learn',
                'If a team of 5 members each gets 300 new learning buddies and earns 100 points per day for 20 days, they can collectively achieve the minimum 3100 points.',
                'The duration of the competition is <b>20 days</b>, within which you can fill your team pot early'
            ]
        },
        {
            header: 'Stage 2 - Learning Pot',
            second_header: 'Example Table',
            contents: [],
            tables: [
                {
                    table_class: 'double',
                    table_header_class: 'yellow-colored',
                    tables: [
                        ['', 'Day 1'],
                        ['', 'Team Score', 'Buddies Score'],
                        ['Team A', '5', '0'],
                        ['Team B', '5', '40'],
                        ['Team C', '3', '100']
                    ]
                },
                {
                    table_class: 'double',
                    table_header_class: '',
                    tables: [
                        ['', 'Day 2'],
                        ['', 'Team Score', 'Buddies Score'],
                        ['Team A', '5', '0'],
                        ['Team B', '2', '200'],
                        ['Team C', '4', '160']
                    ]
                },
                {
                    table_class: '',
                    table_header_class: 'grey-colored',
                    tables: [
                        ['', 'Total (after Day 2)'],
                        ['Team A', '10'],
                        ['Team B', '247'],
                        ['Team C', '267']
                    ]
                },
                {
                    table_class: '',
                    table_header_class: 'orange-colored',
                    tables: [
                        ['', 'Progress to minimum pot size'],
                        ['Team A', '0.32%'],
                        ['Team B', '7.97%'],
                        ['Team C', '8.61%']
                    ]
                }
            ]
        },
        {
            header: 'Stage 3 - Team Video',
            desc: 'To share your learning experience & the skills you have developed in the process',
            contents: [
                'Stitch the 1st video on TikTok',
                'Solve Education!’s account will repost the video. Get 100 likes (organically) on the reposted video for another 50 points!',
                'Theme: Stitch the 1st video, then record your sharing about the learning experience & the skills you have developed in the process, and ask people to like this video, and visit Solve Education! page to see the result, and sign up for another round of SELC.'
            ]
        }
    ]
    const faqData = [
        {
            header: 'What is SELC?',
            content: 'Solve Education! Learning Competition (SELC) is an intensive team-focused international learning competition for people who want to <b>significantly</b> improve their ability to work with others, communicate effectively, and build perseverance and grit. The best team <b>stands a chance</b> to win the <b>top prize of $2,000</b>.'
        },
        {
            header: 'What is the duration of SELC?',
            content: 'SELC Season 5 will run for 20 days. The timeline is subject to change by the competition host.'
        },
        {
            header: 'How many stages exist in the competition?',
            content: 'SELC Season 5 has two stages: Learning Pot and Team Video. Visit the “rule” section to learn more about the scoring system.'
        },
        {
            header: 'When does the leaderboard refresh?',
            content: 'The leaderboard refreshes at 8.00 AM GMT+7.'
        },
        {
            header: 'Will mission difficulty increase during the Learning Pot phase?',
            content: 'No. The mission will remain a Level 1 difficulty throughout the 20-day learning period.'
        },
        {
            header: 'Who Should Register?',
            content: 'SELC is intended for high school and college/university students aged 14 and above. You should sign up for this competition to build essential soft skills that will set you up for a meaningful life.'
        },
        {
            header: 'Who is a Learning Buddy?',
            content: 'A learning buddy is a new user whom you have referred to learn English on Ed the Learning Bot by completing the daily mission during the Learning stage of the SELC. These new buddies will contribute 2 points to your pot.<br/><br/>An existing Ed user can also be your buddy. These buddies will contribute 1 point to your pot if they completed the daily mission during the Learning stage of the SELC.'
        },
        {
            header: 'How to remove team members?',
            content: 'In the chatbot, use the <b>/removemember</b> command. There will appear the list of the team member. Complete the steps by selecting one of them and confirming the removal. This command only works for the Team Leader.'
        },
        {
            header: 'How to get my buddy link?',
            content: 'In the chatbot, use the <b>/buddieslink</b> command. Ed the bot will give the buddy link. This command only works when the event is running.'
        },
        {
            header: 'What is the minimum score required to be considered for a prize?',
            content: 'The minimum score a team can collect to be considered for a prize is 3100 points. Kindly proceed to our website’s “rule” section for more information.'
        },
        {
            header: 'What is the prize amount?',
            content: 'For the SELC Season 5, the top 3 winners can get up to USD2,000, USD175 and USD120, respectively. The size of winning is determined by the Ed Wheel of Prizes.'
        },
        {
            header: 'How are winners selected?',
            content: 'The 1st prize: The team with the highest team score will automatically win SELC Season 5. However, they will need to decide how much they will win in prize money using the Ed Wheel of Prize. Competition winners can win a <b>minimum of USD500</b> and a <b>maximum of USD2,000</b>. <br/><br/>The runners-up (2nd and 3rd prize): Any team with a <b>minimum of 3,100</b> team score will automatically be considered for the runners-up placement. All teams in this category are listed on the Ed Wheel of Prizes. The number of times the wheel is spun is dependent on the number of teams that made the minimum score of 50. For example, if 7 teams made an average score, the teams would be listed on the Ed Wheel of Prize, and the wheel will be spun 7 times. The last two teams on the Ed Wheel of Prize will be awarded 2nd and 3rd positions.'
        }
    ]

    const [rules, setRules] = useState(rulesData[0])
    const [activeRules, setActiveRules] = useState(0)
    const [stages, setStages] = useState([])
    const [stageLoading, setStageLoading] = useState(true)
    const launch = []
    const [timerComponents, setTimerComponents] = useState([])
    const [timerComponents2, setTimerComponents2] = useState([])
    const [timerComponents3, setTimerComponents3] = useState([])
    const currentEndDate = []
    const [currentBut, setCurrentBut] = useState('')
    const [faqActive, setFaqActive] = useState(true)
    const [eventID, setEventID] = useState(-1)



    const startCountDown = (i) => {
        clearInterval(launch[i].current)
        launch[i].current = setInterval(function() { calculateTimeLeft(i) }, 1000);
    }

    const pauseCountDown = (i=null) => {
        if(i !== null){
            clearInterval(launch[i].current);
        }else{
            for (let i = 0; i < launch.length; i++) {
                clearInterval(launch[i].current);
            }
        }
    }

    const calculateTimeLeft = (i) => {
        let difference = +currentEndDate[i].current - +new Date();
        let timeLeft = {};
        let timerComp = {}
        timerComp[i] = []
    
        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };

            let tc = []

            Object.keys(timeLeft).forEach((interval) => {
                tc.push(
                    <span key={interval}>
                        {timeLeft[interval]}<span>{interval[0]}</span>
                    </span>
                );
            });

            if(i === 0){
                setTimerComponents(tc)
            }else if(i === 1){
                setTimerComponents2(tc)
            }else{
                setTimerComponents3(tc)
            }
        }else{
            pauseCountDown(i)
            if(i === 0){
                setTimerComponents([])
            }else if(i === 1){
                setTimerComponents2([])
            }else{
                setTimerComponents3([])
            }
        }
    }


    useEffect(()=>{
        loadStages()
        loadNextEvent()
        return () => pauseCountDown()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const loadNextEvent = () =>{
        fetch(process.env.REACT_APP_API_URL+'selc/getNextEvent')
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === 200){
                setEventID(res.data.Record_KEY)
            }else
            if(res.status === 400){
                setEventID(res.data.Record_KEY)
            }
        })
        .catch((e)=>{
            toast.error('Failed fetching next event data.')
        })
    }

    const loadStages = () =>{
        fetch(process.env.REACT_APP_API_URL+'selc/getSELCEvent')
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === 200){
                let now = (new Date()).getTime()/1000|0;
                let selectedEvent = res.data.filter(x=>x.Event_Start <= now && x.Event_End > now).sort((a,b)=>a.Event_Start - b.Event_Start)
                if(selectedEvent.length === 0){
                    toast.warn('No ongoing event found!')
                    setStageLoading(false)
                    return false
                }
                let data = selectedEvent[0].Stages
                let custom = []
                let k = 0
                data.forEach(x=>{
                    let now = moment().format('X')
                    custom.push({
                        Key: k,
                        Stage_Name: x.Stage_Name,
                        Stage_Icon: x.Stage_Icon,
                        Period: moment.unix(x.Start_Date).format('DD MMMM YYYY')+' - '+moment.unix(x.End_Date).format('DD MMMM YYYY'),
                        Start_Date: x.Start_Date,
                        End_Date: x.End_Date,
                        Active: now >= x.Start_Date && now < x.End_Date ? true : false,
                        Status: now < x.Start_Date ? 'Coming Soon' : now > x.End_Date ? 'Ended' : '0d - 0h - 0m - 0s'
                    })
                    k++
                })

                let activeList = custom.filter(x=>x.Active)
                let i = 0
                activeList.forEach(x=>{
                    launch.push(createRef())
                    currentEndDate.push(createRef())
                    pauseCountDown(i)
                    currentEndDate[i].current = new Date(x.End_Date*1000)
                    startCountDown(i)
                    let cs = custom.findIndex(v=>v.Key === x.Key)
                    if(cs !== -1){
                        custom[cs].TimerIndex = i
                    }
                    i++
                })

                setStages(custom)
            }
            setStageLoading(false)
        })
        .catch((e)=>{
            toast.error('Failed fetching stages data.')
        })
    }

    const changeRule = (e, data) =>{
        let idx = activeRules
        if(data.name === 'angle right'){
            idx++
        }else{
            idx--
        }

        setRules(rulesData[idx])
        setActiveRules(idx)
    }

    const quickButtons = (e, data) =>{
        setCurrentBut(data.name)
        let target = 'selc_banner_2'
        if(data.name === 'join'){
            target = 'selc_join'
        }else if(data.name === 'faq'){
            target = 'selc_faq'
        }

        var element = document.getElementById(target);
        var headerOffset = 100;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    
        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
    }

    const renderTimer = (e, data) =>{
        let comp = timerComponents

        if(data.TimerIndex === 1){
            comp = timerComponents2
        }else if(data.TimerIndex === 2){
            comp = timerComponents3
        }

        return comp
    }

    return(
        <div id="selc5_page">
            <div className="quickButtons">
                <Menu icon vertical>
                    <Menu.Item name='events' onClick={quickButtons} active={currentBut === 'events'}>
                        <Icon name='calendar'/>
                        <span>Events</span>
                    </Menu.Item>

                    <Menu.Item name='join' onClick={quickButtons} active={currentBut === 'join'}>
                        <Icon name='question circle outline'/>
                        <span>How to join</span>
                    </Menu.Item>

                    <Menu.Item name='faq' onClick={quickButtons} active={currentBut === 'faq'}>
                        {/* <Icon name='chat' /> */}
                        <Image src={require('../../../../assets/images/selc/faq_icon.svg').default} centered size="mini"/>
                        <span>FAQ</span>
                    </Menu.Item>
                </Menu>
            </div>
            <section id="selc_banner">
                <Container>
                <Segment basic>
                    <Image centered src={require('../../../../assets/images/selc/banner_s5.svg').default} size="massive"/>
                </Segment>
                </Container>
            </section>
            
            <section id="selc_banner_2">
                <Segment basic className="banner">
                    <Container>
                        <Grid columns={'equal'} className="banner_grid">
                            <Grid.Column width={2}/>
                            <Grid.Column>
                                <Header as="h1" className="headerSelc">What is SELC?</Header>
                                <p className="descSelc">Solve Education! Learning Competition (SELC) is an intensive team-focused international learning competition for people who want to <b>significantly</b> improve their ability to work with others, communicate effectively, and build perseverance and grit. The best team <b>stands a chance</b> to win the top prize of <b>$2,000</b>.</p>
                            </Grid.Column>
                            <Grid.Column>
                            </Grid.Column>
                        </Grid>
                    </Container>
                    <Image className="banner_5_d" src={require('../../../../assets/images/selc/banner_5_d.png').default} size="large"/>
                </Segment>
            </section>

            <section id="selc_info_m">
                {/* <Container textAlign="center"> */}
                    <Segment basic textAlign="center">
                    <Button onClick={()=>window.open('/register?cid='+eventID+'&category=selc', '_blank')} primary size="massive">Register Now!</Button>
                    <Image src={require('../../../../assets/images/selc/banner_5_m.png').default} centered/>
                    </Segment>
                {/* </Container> */}
            </section>
            
            <section id="selc_info">
                <Segment basic className="info">
                    <Container text textAlign="center">
                        <Header as="h1" className="primary">
                        Who Should Register?
                        </Header>
                        <p className="descSelc">SELC is intended for high school and university students aged 13 years old and above. You should sign up for this event to build essential soft skills that will set you up for a meaningful life.</p>
                    </Container>
                </Segment>
            </section>

            <section id="selc_join">
                <Container textAlign="center" text>
                    <Header as="h1" className="primary">How To Join?</Header>
                    <Divider hidden/>
                    <SlideShowComponent id={"slide-about-how-to-join"} images={joinImages} labels={joinLabels}/>
                </Container>
            </section>

            <section id="selc_stages">
                <Container textAlign="center">
                    <Button onClick={()=>window.open('/register?cid='+eventID+'&category=selc', '_blank')} primary size="massive">Register Now!</Button>
                    <br/>
                    <br/>
                    <a href="https://wa.me/6285173146265" target={'_blank'} rel="noreferrer">Customer support</a>
                </Container>
                <Container text id="stages">
                    <Header as="h1" textAlign="center" className="primary">
                        Stages
                    </Header>
                    <Divider hidden/>
                    <Dimmer.Dimmable as={Grid} columns='equal' className="stage_grid" dimmed>
                        {stageLoading ? 
                        <>
                        <Segment>
                            <Divider hidden/>
                            <Divider hidden/>
                        </Segment>
                        <Dimmer active>
                            <Loader>Getting stage info...</Loader>
                        </Dimmer>
                        </>
                        : 
                        stages.map((v, k)=>
                            <Grid.Row stretched key={k} className={v.Active ? 'active' : ''}>
                                <Grid.Column width={5}>
                                    <Segment basic>
                                        <Header as="h2">{v.Stage_Name}</Header>
                                        <Header as="h2" icon={v.Stage_Icon} content=""/>
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column>
                                    <Segment basic>
                                        <Header as="h2">{v.Period}</Header>
                                        <i className="stage_status">
                                            {v.Active ? renderTimer(null, v) : v.Status}
                                        </i>
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                        )
                        }
                    </Dimmer.Dimmable>
                </Container>
            </section>

            <section id="selc_rules">
                <Container text textAlign="center">
                    <Header as="h1" className="primary">
                    Mechanics of the Learning Pot
                    </Header>
                    <p className="descSelc">
                    Your mission is to accumulate at least <b>3100 points</b> to win the prize. You get points for <b>Learning English</b> and <b>introducing your friends</b> to Learn Engish on Ed the learning bot. The team with the biggest pot will win up to <b>$2,000!</b>
                    </p>
                    <Divider hidden/>
                    <Modal trigger={<Button primary size="large">Rules</Button>} closeIcon id="selec5_rule_modal"
                        onClose={()=>{
                            setActiveRules(0)
                            setRules(rulesData[0])
                        }}>
                        <Modal.Content>
                            <Container text>
                                <Header as="h1" textAlign="center">{rules.header}</Header>
                                {rules.second_header !== undefined ? <Header as="h1" textAlign="center">{rules.second_header}</Header> : null}
                                {rules.desc !== undefined ? <p className="descSelc">{rules.desc}</p> : null}
                                <List ordered className="selc5_list">
                                    {rules.contents.map((v, k)=>
                                        <List.Item key={k}><div dangerouslySetInnerHTML={{__html: v}}/></List.Item>
                                    )}
                                </List>

                                <Divider hidden/>
                                
                                {rules.tables !== undefined ? 
                                rules.tables.map((a, b)=>
                                    <Grid columns='equal' className={'rule_grid '+a.table_class} key={b}>
                                        {a.tables.map((v, k) => {
                                            let compParent = <Grid.Row key={k} stretched className={k === 0 ? a.table_header_class : ''}>
                                            {v.map((i, j)=>
                                                {
                                                    let comp = <Grid.Column key={j}>
                                                        {i !== '' ? <Segment>{i}</Segment> : null}
                                                    </Grid.Column>
                                                    if(j!==0){
                                                        comp = <Grid.Column key={j} width={v.length === 2 ? 8 : 4}>
                                                        <Segment>{i}</Segment>
                                                    </Grid.Column>
                                                    }

                                                    return comp
                                                }
                                            )}
                                            </Grid.Row>

                                            if(a.table_class !== ''){
                                                compParent = <Grid.Row key={k} stretched className={k === 0 || k === 1 ? a.table_header_class : ''}>
                                                {v.map((i, j)=>
                                                    {
                                                        let comp = <Grid.Column key={j}>
                                                            {i !== '' ? <Segment>{i}</Segment> : null}
                                                        </Grid.Column>
                                                        if(j!==0){
                                                            comp = <Grid.Column key={j} width={v.length === 2 ? 10 : 5}>
                                                            <Segment>{i}</Segment>
                                                        </Grid.Column>
                                                        }

                                                        return comp
                                                    }
                                                )}
                                                </Grid.Row>                                                
                                            }

                                            return compParent
                                        })}
                                    </Grid>
                                )
                                : null}
                            </Container>
                        </Modal.Content>
                        {activeRules > 0 ? 
                            <Icon link size="huge" onClick={changeRule} name="angle left"/>
                        : null}
                        {activeRules < rulesData.length - 1 ? 
                            <Icon link size="huge" onClick={changeRule} name="angle right"/>
                        : null}
                    </Modal>
                </Container>
            </section>
            
            <section id="selc_gallery">
                <Container textAlign="center">
                    <Header as="h1" className="primary">Event Gallery</Header>
                    <Image src={require('../../../../assets/images/selc/gallery.png').default} centered/>
                </Container>
                <Divider hidden/>
                <Divider hidden/>
                <Divider hidden/>
                <Container text textAlign="center" id="selc_video_winner">
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/3tyS5VOV6wY" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </Container>
            </section>

            <section id="selc_sponsor">
                <Container textAlign="center">
                    <Header as="h1" className="primary">Our Sponsor</Header>
                    <Image.Group className="selc_sponsor_images">
                        <Image src={require('../../../../assets/images/selc/sponsor/logo_aria.png').default}/>
                        <Image src={require('../../../../assets/images/selc/sponsor/logo_quantus.png').default}/>
                        <Image src={require('../../../../assets/images/selc/sponsor/logo_jafra.png').default}/>
                        <Image src={require('../../../../assets/images/selc/sponsor/logo_screamous.png').default}/>
                    </Image.Group>
                </Container>
            </section>

            <section id="selc_faq">
                <Container text>
                    <Accordion fluid styled id="faq_content">
                        <Accordion.Title
                        active={faqActive}
                        onClick={()=>setFaqActive(!faqActive)}
                        >
                            <Header as="h1" className="primary">
                                F.A.Q
                                <Icon name='dropdown' />
                            </Header>
                        </Accordion.Title>
                        <Accordion.Content active={faqActive}>
                            {faqData.map((v, k)=>
                            <div key={k}>
                            <Header as="h3">
                                {v.header}
                                <Header.Subheader dangerouslySetInnerHTML={{__html: v.content}}/>
                            </Header>
                            {k < faqData.length-1 ? <Divider/> : null}
                            </div>
                            )}
                        </Accordion.Content>
                    </Accordion>
                </Container>
                <Divider hidden/>
                <Divider hidden/>
                <Divider hidden/>
                <Container textAlign="center" text>
                    <Header as="h1" className="primary">For more information</Header>
                    <Grid id="ed_socialmedia">
                        <Grid.Column>
                        <div className="ed_social">
                            <Image src={instagram} size="tiny"/>
                            <div className="ed_social_text">
                                <p onClick={()=>window.open("https://www.instagram.com/solveeducation/", "_blank")}>@solveeducation</p>
                                <p onClick={()=>window.open("https://www.instagram.com/edthelearningbot/", "_blank")}>@edthelearningbot</p>
                            </div>
                        </div>
                        </Grid.Column>
                    </Grid>
                </Container>
            </section>
        </div>
    )
}

export default SELC5Page