import { createRef, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import SemanticDatepicker from "react-semantic-ui-datepickers"
import { toast } from "react-toastify"
import { Container, Header, Segment, Image, Grid, Divider, List, 
    Button, Dimmer, Table, Menu, Modal, Icon, Loader, Form, Popup, Accordion } from "semantic-ui-react"
import moment from 'moment'
import instagram from '../../../../assets/images/Instagram.svg'

function SELCPage(){
    const rulesData = [
        {
            header: 'General Rules',
            contents: [
                '5 people make a team',
                'A team needs a minimum total score of 50 to qualify for any prize'
            ],
            tables: [
                ['Stage', 'Activities', 'Duration', 'Total Score'],
                [
                    'Learning Streak',
                    'Get your team to complete the daily mission. Achieve bonus score daily when your team ranks TOP 10',
                    '14 days',
                    '35'
                ],
                [
                    'Learning Buddies',
                    'Refer new users to learn on Ed the Learning Bot',
                    '14 days (together with Learning Streak)',
                    '60'
                ],
                [
                    'Team video',
                    'Upload the team video and tag Solve Education. The min number of 100 likes on the reposted video will get a score of 5',
                    '9 days',
                    '5'
                ],
                [
                    'Total', '', '', '100'
                ]
            ]
        },
        {
            header: 'Stage 1 - Learning Streak',
            contents: [
                'All team members must complete a daily mission to score 1.5 per day',
                'If only 1,2,3, or 4 members finish the mission, the team will not get any score',
                'A team gets 1 bonus score for any day their team ranks TOP 10 for questions attempted.',
                'The question attempted will reset every day at 08:00 GMT+7',
                'In one day, attempting the same question will not count again on the leaderboard',
                'Only questions answered correctly will count on the leaderboard',
                'Only playing in private bot will count. Playing in a group will not be counted'
            ],
            tables: [
                ['', 'Daily Score', 'After 14 Days'],
                ['All 5 team members learn on that day', '1.5', '21'],
                ['Total team score is top 10 of the daily team leaderboard', '1', '14'],
                ['TOTAL', '', '35']
            ]
        },
        {
            header: 'Stage 2 - Learning Buddies',
            contents: [
                'Refer new users to learn on Ed the Learning Bot. The new users need to play any games (Fix the Mix, Type Ticking, Brain Battle, Word Snap, Hello Cafe, or See Stuff)',
                'New users are the users who contact the Bot for the first time within the time period of Learning Buddies',
                'Any referral done before or after the Learning Buddies period will not be counted'
            ],
            tables: [
                ['New Learners through referrals', 'Score'],
                ['100 - 299', '1.2'],
                ['300 - 499', '4.8'],
                ['500 - 1499', '9'],
                ['1500 - 1999', '24'],
                ['2000 - 2999', '48'],
                ['> 3000', '60']
            ]
        },
        {
            header: 'Stage 3 - Team video',
            contents: [
                'Upload the team video and tag Solve Education. The min number of 100 likes on the reposted video will get a score of 5'
            ]
        },
        {
            header: 'Prizes',
            contents: [
                'Winner: $500 - $1000',
                'First Runner Up: $175',
                'Second Runner Up: $120'
            ]
        }
    ]

    const faqData = [
        {
            header: 'What is SELC',
            content: 'SELC is an initialism for <b>Solve Education! Learning Competition</b>—an international team competition where students learn English and build skills that they need to thrive in the future. The competition rewards up to USD1500 in prize money to winners and other consolation prizes.'
        },
        {
            header: 'What is the duration of SELC?',
            content: 'The 2022 SELC will run for <b>14 days</b>.'
        },
        {
            header: 'How many stages exist in the competition?',
            content: 'SELC 2022 has three stages: Learning Streak, Learning Buddies, and Team Video. Visit the “rule” section to learn more about the scoring system.'
        },
        {
            header: 'What is Learning Streak?',
            content: 'Learning Streak is the stage where your team must complete the daily learning mission on Ed the Learning Bot to collect the score of 1.5 for each day every member of your team completes the daily mission.'
        },
        {
            header: 'When does Streak refresh?',
            content: 'Learning Streak refreshes at <b>8.00 AM GMT+7</b>.'
        },
        {
            header: 'Will mission difficulty increase daily in Learning Streak?',
            content: 'No, mission difficulty will not increase daily. Mission will remain a Level 1 difficulty throughout the 14-day Learning Streak period.'
        },
        {
            header: 'Who is a Learning Buddy?',
            content: 'A Learning Buddy is a new user whom you have referred to learn English on Ed the Learning Bot during the Learning Buddies stage on the SELC 2022'
        },
        {
            header: 'What is the minimum score required to be considered for a prize?',
            content: 'The minimum score a team can collect to be considered for a prize is 50. Kindly proceed to the “rule” section of our website for more information.'
        },
        {
            header: 'What is the prize amount?',
            content: 'For the SELC 2022, the top 3 winners will get up to <b>USD1000</b>, <b>USD175</b> and <b>USD120</b>, respectively.'
        },
        {
            header: 'How are winners selected?',
            content: '<b>The 1st prize</b>: The team with the highest team score will automatically win SELC 2022. However, they will need to decide how much they will win in prize money using the Ed Wheel of Prize. Competition winners can win a minimum of USD500 and a maximum of USD1000. <br/><br/><b>The runners-up (2nd and 3rd prize)</b>: Any team with a minimum of 50 team score will automatically be considered for the runners-up placement. All teams in this category are listed on the Ed Wheel of Prizes. The number of times the wheel is spun is dependent on the number of teams that made the minimum score of 50. For example, if 7 teams made an average score, the teams would be listed on the Ed Wheel of Prize, and the wheel will be spun 7 times. The last two teams on the Ed Wheel of Prize will be awarded 2nd and 3rd positions.'
        }
    ]

    const defaultDate = [new Date('2022-10-24'), new Date()]

    const [rules, setRules] = useState(rulesData[0])
    const [activeRules, setActiveRules] = useState(0)
    const [leaderActive, setLeaderActive] = useState(1)
    const [leaderboard, setLeaderboard] = useState({})
    const [leaderboardTopTen, setLeaderboardTopTen] = useState([])
    const [questions, setQuestions] = useState({})
    const [stages, setStages] = useState([])
    const [lbLoading, setLbloading] = useState(true)
    const [qsLoading, setQsloading] = useState(true)
    const [stageLoading, setStageLoading] = useState(true)
    const [startDate, setStartDate] = useState(new Date())
    const [progressDate, setProgressDate] = useState(defaultDate)
    const launch = []
    const [timerComponents, setTimerComponents] = useState([])
    const [timerComponents2, setTimerComponents2] = useState([])
    const [timerComponents3, setTimerComponents3] = useState([])
    const currentEndDate = []
    const [currentBut, setCurrentBut] = useState('')
    const [faqActive, setFaqActive] = useState(false)
    const [activeListLb, setActiveListLb] = useState([])
    const [progressShow, setProgressShow] = useState(false)
    const [selectedProgress, setSelectedProgress] = useState([])
    const [selectedTeam, setSelectedTeam] = useState({})
    const [loadingDataProgress, setLoadingDataProgress] = useState(false)


    const startCountDown = (i) => {
        clearInterval(launch[i].current)
        launch[i].current = setInterval(function() { calculateTimeLeft(i) }, 1000);
    }

    const pauseCountDown = (i=null) => {
        if(i !== null){
            clearInterval(launch[i].current);
        }else{
            for (let i = 0; i < launch.length; i++) {
                clearInterval(launch[i].current);
            }
        }
    }

    const calculateTimeLeft = (i) => {
        let difference = +currentEndDate[i].current - +new Date();
        let timeLeft = {};
        let timerComp = {}
        timerComp[i] = []
    
        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };

            let tc = []

            Object.keys(timeLeft).forEach((interval) => {
                tc.push(
                    <span key={interval}>
                        {timeLeft[interval]}<span>{interval[0]}</span>
                    </span>
                );
            });

            if(i === 0){
                setTimerComponents(tc)
            }else if(i === 1){
                setTimerComponents2(tc)
            }else{
                setTimerComponents3(tc)
            }
        }else{
            pauseCountDown(i)
            if(i === 0){
                setTimerComponents([])
            }else if(i === 1){
                setTimerComponents2([])
            }else{
                setTimerComponents3([])
            }
        }
    }


    useEffect(()=>{
        loadStages()
        loadLeaderboard()
        loadQuestionAttempted()

        return () => pauseCountDown()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const loadStages = () =>{
        fetch(process.env.REACT_APP_API_URL+'selc/getStages')
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === 200){
                let data = res.data
                let custom = []
                let k = 0
                data.forEach(x=>{
                    let now = moment().format('X')
                    custom.push({
                        Key: k,
                        Stage_Name: x.Stage_Name,
                        Stage_Icon: x.Stage_Icon,
                        Period: moment.unix(x.Start_Date).format('DD MMMM YYYY')+' - '+moment.unix(x.End_Date).format('DD MMMM YYYY'),
                        Start_Date: x.Start_Date,
                        End_Date: x.End_Date,
                        Active: now >= x.Start_Date && now < x.End_Date ? true : false,
                        Status: now < x.Start_Date ? 'Coming Soon' : now > x.End_Date ? 'Ended' : '0d - 0h - 0m - 0s'
                    })
                    k++
                })

                let activeList = custom.filter(x=>x.Active)
                let i = 0
                activeList.forEach(x=>{
                    launch.push(createRef())
                    currentEndDate.push(createRef())
                    pauseCountDown(i)
                    currentEndDate[i].current = new Date(x.End_Date*1000)
                    startCountDown(i)
                    let cs = custom.findIndex(v=>v.Key === x.Key)
                    if(cs !== -1){
                        custom[cs].TimerIndex = i
                    }
                    i++
                })

                setStages(custom)
            }
            setStageLoading(false)
        })
        .catch((e)=>{
            toast.error('Failed fetching stages data.')
        })
    }

    const loadLeaderboard = () =>{
        fetch(process.env.REACT_APP_API_URL+'selc/getLeaderboard')
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === 200){
                let data = res.data
                let top10 = data.slice(0, 10)
                setLeaderboardTopTen(top10)
                setLeaderboard(res)
            }
            setLbloading(false)
        })
        .catch((e)=>{
            toast.error('Failed fetching leaderboard data.')
        })
    }

    const loadQuestionAttempted = (date=null) =>{
        setQsloading(true)

        let query = ''

        if(date !== null){
            let start = moment(date).format('YYYY-MM-DD')
            query = '?start='+start
        }

        fetch(process.env.REACT_APP_API_URL+'selc/getAttemptedQuestions'+query)
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === 200){
                setQuestions({})
                setQuestions(res)
            }
            setQsloading(false)
        })
        .catch((e)=>{
            toast.error('Failed fetching question attempt data.')
        })
    }

    const loadProgressData = (team, date = null) =>{
        setLoadingDataProgress(true)
        let start = date === null ? progressDate[0] : date[0]
        let end = date === null ? progressDate[1] : date[1]

        start = moment(start).format('YYYY-MM-DD')
        end = moment(end).format('YYYY-MM-DD')

        let query = '?start='+start+'&end='+end+'&la_code='+team.LA_Code

        fetch(process.env.REACT_APP_API_URL+'selc/getProgress'+query)
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === 200){
                setSelectedProgress(res.data)
                setSelectedTeam(team)
            }
            setLoadingDataProgress(false)
        })
        .catch((e)=>{
            toast.error('Failed fetching contribution data.')
        })
    }

    const changeRule = (e, data) =>{
        let idx = activeRules
        if(data.name === 'angle right'){
            idx++
        }else{
            idx--
        }

        setRules(rulesData[idx])
        setActiveRules(idx)
    }

    const onChangeDate = (event, data) =>{
        if(data.value !== null && event !== undefined){
            setStartDate(data.value)
            loadQuestionAttempted(data.value)
        }
    }

    const onChangeDateProgress = (event, data) =>{
        if(data.value !== null && event !== undefined && data.value.length === 2){
            setProgressDate(data.value)
            loadProgressData(selectedTeam, data.value)
        }
    }

    const quickButtons = (e, data) =>{
        setCurrentBut(data.name)
        let target = 'selc_banner'
        if(data.name === 'leaderboard'){
            target = 'selc_lb_btn'
        }else if(data.name === 'how_to_play'){
            target = 'btn_rules'
        }

        var element = document.getElementById(target);
        var headerOffset = 100;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    
        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
    }

    const renderTimer = (e, data) =>{
        let comp = timerComponents

        if(data.TimerIndex === 1){
            comp = timerComponents2
        }else if(data.TimerIndex === 2){
            comp = timerComponents3
        }

        return comp
    }

    const expandTable = (e, titleProps) =>{
        const { index } = titleProps
        let list = activeListLb
        if(list.includes(index)){
            list = list.filter(function(item) {
                return item !== index
            })
        }else{
            list.push(index)
        }

        setActiveListLb([])
        setTimeout(()=>{
            setActiveListLb(list)
        }, 0)
    }

    return(
        <div id="selc_page">
            <div className="quickButtons">
                <Menu icon vertical>
                    <Menu.Item name='events' onClick={quickButtons} active={currentBut === 'events'}>
                        <Icon name='calendar'/>
                        <span>Events</span>
                    </Menu.Item>

                    <Menu.Item name='leaderboard' onClick={quickButtons} active={currentBut === 'leaderboard'}>
                        <Icon name='sitemap'/>
                        <span>Leaderboards</span>
                    </Menu.Item>

                    <Menu.Item name='how_to_play' onClick={quickButtons} active={currentBut === 'how_to_play'}>
                        <Icon name='question circle outline' />
                        <span>How to play</span>
                    </Menu.Item>
                </Menu>
            </div>
            <section id="selc_banner">
                <Container>
                <Segment basic>
                    <Image centered src={require('../../../../assets/images/selc/banner.svg').default} size="massive"/>
                </Segment>
                </Container>
            </section>
            
            <section id="selc_banner_2">
                <Segment basic className="banner">
                    <Container>
                        <Grid columns={'equal'} className="banner_grid">
                            <Grid.Column width={2}/>
                            <Grid.Column width={4}>
                                <Header as="h1">STAND A CHANCE TO WIN UP TO</Header>
                                <Link to='/selc/signup' id="d_join" className="ui huge secondary button">Sign Up</Link>
                            </Grid.Column>
                            <Grid.Column>
                                <Image src={require('../../../../assets/images/selc/banner2.svg').default} size="large"/>
                            </Grid.Column>
                            <Grid.Column id="m_join">
                                <Link to='/selc/signup' className="ui large fluid secondary button">Sign Up</Link>
                            </Grid.Column>
                        </Grid>
                    </Container>
                </Segment>
            </section>
            
            <section id="selc_info">
                <Segment basic className="info">
                    <Container text>
                        <Header as="h2">
                        WHAT IS SELC?
                        </Header>
                        <p>Solve Education! Learning Competition (SELC) is an international team learning competition. Stand a chance to win the top prize of <b>$1000</b>!</p>
                        
                        <Divider hidden/>

                        <Header as="h2">
                        HOW TO JOIN?
                        </Header>
                        <List bulleted>
                            <List.Item><p>Go to this link to join SELC WA group: <a target={'_blank'} rel='noreferrer' href="https://rebrand.ly/SELCGROUP"><i>rebrand.ly/SELCGROUP</i></a> for more information</p></List.Item>
                            <List.Item><p>Register your team via this from: <a target={'_blank'} rel='noreferrer' href="https://rebrand.ly/selc22"><i>rebrand.ly/selc22</i></a> or click "<b>Sign up</b>" button below!</p></List.Item>
                            <List.Item><p>This competition is <b>FREE</b></p></List.Item>
                        </List>

                        <Link to='/selc/signup' className="ui large secondary button">Sign Up</Link>

                    </Container>
                </Segment>
            </section>

            <section id="selc_comp">
                <Container textAlign="center" text>
                    <Header as="h1" className="comp_header">Let’s Join the Competition to Win the Prize up to US$1000!</Header>
                    <div className="selc_btn_group_div">
                        <Link to='/selc/signup' className="ui massive button joinBtn" id="joinBtn">Sign Up</Link>
                        <Divider hidden/>
                        <Modal trigger={<Button id="btn_rules" basic color="black" size="large">Rules</Button>} closeIcon id="rule_modal"
                        onClose={()=>{
                            setActiveRules(0)
                            setRules(rulesData[0])
                        }}>
                            <Modal.Content>
                                <Container text>
                                    <Header as="h1">{rules.header}</Header>
                                    <List bulleted>
                                        {rules.contents.map((v, k)=>
                                            <List.Item key={k}>{v}</List.Item>
                                        )}
                                    </List>
                                    
                                    {rules.header === 'General Rules' ? <Header as="h2">Stages Information</Header> : null}
                                    
                                    {rules.tables !== undefined ? 
                                    <>
                                    <Divider hidden/>
                                    <Grid columns='equal' className='rule_grid'>
                                        {rules.tables.map((v, k)=>
                                            <Grid.Row key={k} stretched>
                                                {v.map((i, j)=>
                                                    {
                                                        let comp = <Grid.Column key={j}>
                                                            <Segment>{i}</Segment>
                                                        </Grid.Column>
                                                        if(j===0){
                                                            comp = <Grid.Column key={j} width={v.length === 3 ? 8 : v.length === 4 ? 3 : 10}>
                                                            <Segment>{i}</Segment>
                                                        </Grid.Column>
                                                        }

                                                        return comp
                                                    }
                                                )}
                                            </Grid.Row>
                                        )}
                                    </Grid>
                                    </>
                                    : null}
                                </Container>
                            </Modal.Content>
                            {activeRules > 0 ? 
                                <Icon link size="huge" onClick={changeRule} name="angle left"/>
                            : null}
                            {activeRules < rulesData.length - 1 ? 
                                <Icon link size="huge" onClick={changeRule} name="angle right"/>
                            : null}
                        </Modal>
                    </div>
                </Container>
                <Container text id="stages">
                    <Header as="h2">
                        STAGES
                    </Header>

                    <Dimmer.Dimmable as={Grid} columns='equal' className="stage_grid" dimmed>
                        {stageLoading ? 
                        <>
                        <Segment>
                            <Divider hidden/>
                            <Divider hidden/>
                        </Segment>
                        <Dimmer active>
                            <Loader>Getting stage info...</Loader>
                        </Dimmer>
                        </>
                        : 
                        stages.map((v, k)=>
                            <Grid.Row stretched key={k} className={v.Active ? 'active' : ''}>
                                <Grid.Column width={5}>
                                    <Segment basic>
                                        <Header as="h2">{v.Stage_Name}</Header>
                                        <Header as="h2" icon={v.Stage_Icon} content=""/>
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column>
                                    <Segment basic>
                                        <Header as="h2">{v.Period}</Header>
                                        <i className="stage_status">
                                            {v.Active ? renderTimer(null, v) : v.Status}
                                        </i>
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                        )
                        }
                    </Dimmer.Dimmable>
                </Container>

                <Container text textAlign="center" id="winners">
                        <Header as="h1">1st Runner Up</Header>
                        <Header as="h2">(AZAZ Team)</Header>
                        <Header as="h3">Won $175!</Header>
                        <Image src={require('../../../../assets/images/selc/winner/selc4_1.png').default} centered/>

                        <Divider hidden/>
                        <Divider hidden/>

                        <Header as="h1">2nd Runner Up</Header>
                        <Header as="h2">(5 Guerilla Naim)</Header>
                        <Header as="h3">Won $120!</Header>
                        <Image src={require('../../../../assets/images/selc/winner/selc4_2.png').default} centered/>
                </Container>
                
                <Divider hidden/>
                <Divider hidden/>

                <Container text textAlign="center" id="selc_video">
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/ykfev6hyw1M" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </Container>

                <Container text textAlign="center" id="selc_video_winner">
                    <Header as="h1" className="comp_header">Check out our past SELC winners!</Header>
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/3tyS5VOV6wY" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </Container>
            </section>

            <section id="selc_leaderboard">
                <Segment basic className="lb_wrapper">
                    <Container text>
                        <div className="btn_lb_wrapper">
                            <Button size="big" id="selc_lb_btn" attached="left"
                            active={leaderActive === 1} onClick={()=>setLeaderActive(1)}>SELC Leaderboard &nbsp;&nbsp;&nbsp;<Popup basic content="Click the team name (on computer) or 'Contribution' button (on phone) to see member's progress" trigger={<Icon name="exclamation circle"/>}/></Button>
                            <Button size="big" id="selc_qs_btn" attached="right"
                            active={leaderActive>1} onClick={()=>setLeaderActive(2)}>Questions Attempted Leaderboard &nbsp;&nbsp;&nbsp;<Popup basic trigger={<Icon name="exclamation circle"/>}
                            content='Questions Attempted: “Unique” questions attempted correctly by the user from any subject. Click “Rule” button for more details.”'/></Button>
                        </div>

                        <Divider hidden/>
                        <Divider hidden/>

                        {leaderActive === 1 ? 
                        <div>
                            <Dimmer active={lbLoading}>
                                <Loader>Fetching leaderboard data...</Loader>
                            </Dimmer>
                            <div id="lb_table_mobile">
                                <Accordion styled>
                                    {leaderboardTopTen.map((v, k)=>
                                    <div key={k}>
                                    <Accordion.Title index={k} active={activeListLb.includes(k)} onClick={expandTable}>
                                        <Grid columns='equal'>
                                            <Grid.Column width='2'>#{k+1}</Grid.Column>
                                            <Grid.Column>{v.Team_Name}</Grid.Column>
                                            <Grid.Column><b>Total :</b> {v.Total}</Grid.Column>
                                            <Grid.Column width='2'>
                                                <Icon name="dropdown"/>
                                            </Grid.Column>
                                        </Grid>
                                    </Accordion.Title>
                                    <Accordion.Content active={activeListLb.includes(k)}>
                                        <Grid columns='equal'>
                                            <Grid.Column width='2'/>
                                            <Grid.Column textAlign='right'>
                                                <div><b>Learning Streak:</b>&nbsp;&nbsp;&nbsp;{v.Learning_Streak}</div>
                                                <List as='ol'>
                                                    <List.Item as='ol'>
                                                        <b>Learning Buddies</b>
                                                        <List.Item>
                                                        Total : {v.Total_Buddies}
                                                        </List.Item>    
                                                        <List.Item>
                                                        Score : {v.Learning_Buddies}
                                                        </List.Item>    
                                                    </List.Item>
                                                </List>
                                                <div><b>Team Video:</b>&nbsp;&nbsp;&nbsp;{v.Team_Video}</div>
                                                <br/>
                                                <Button size="tiny" onClick={()=>{
                                                    setProgressShow(true)
                                                    loadProgressData(v)
                                                }} secondary>Contribution</Button>
                                            </Grid.Column>
                                            <Grid.Column width='2'/>
                                        </Grid>
                                    </Accordion.Content>
                                    </div>
                                    )}
                                </Accordion>
                            </div>
                            <Table basic='very' textAlign="center" id="lb_table">
                                <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell rowSpan={2}>Rank</Table.HeaderCell>
                                    <Table.HeaderCell rowSpan={2}>Team Name</Table.HeaderCell>
                                    <Table.HeaderCell rowSpan={2}>Learning Streak</Table.HeaderCell>
                                    <Table.HeaderCell colSpan={2}>Learning Buddies</Table.HeaderCell>
                                    <Table.HeaderCell rowSpan={2}>Team Video</Table.HeaderCell>
                                    <Table.HeaderCell rowSpan={2}>Total</Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell>Total</Table.HeaderCell>
                                    <Table.HeaderCell>Score</Table.HeaderCell>
                                </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                {leaderboardTopTen.length > 0 ? 
                                    leaderboardTopTen.map((v, k)=>
                                    <Table.Row key={k}>
                                        <Table.Cell>#{k+1}</Table.Cell>
                                        <Table.Cell>
                                            <Button onClick={()=>{
                                                setProgressShow(true)
                                                loadProgressData(v)
                                            }}>{v.Team_Name}</Button>
                                        </Table.Cell>
                                        <Table.Cell>{v.Learning_Streak}</Table.Cell>
                                        <Table.Cell>{v.Total_Buddies}</Table.Cell>
                                        <Table.Cell>{v.Learning_Buddies}</Table.Cell>
                                        <Table.Cell>{v.Team_Video}</Table.Cell>
                                        <Table.Cell>{v.Total}</Table.Cell>
                                    </Table.Row>
                                    )
                                : <Table.Row><Table.Cell colSpan="6">NO DATA YET...</Table.Cell></Table.Row>}
                                </Table.Body>
                            </Table>
                            
                            {leaderboard.data !== undefined && leaderboard.data.length > 10 ?
                            <>
                            <Modal size="small" closeIcon trigger={<Button><Icon name="external share"/> SEE FULL LIST</Button>}>
                                <Modal.Content scrolling>
                                    <Table basic='very' textAlign="center" unstackable id="all_lb_table">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell rowSpan={2}>Rank</Table.HeaderCell>
                                            <Table.HeaderCell rowSpan={2}>Team Name</Table.HeaderCell>
                                            <Table.HeaderCell rowSpan={2}>Learning Streak</Table.HeaderCell>
                                            <Table.HeaderCell colSpan={2}>Learning Buddies</Table.HeaderCell>
                                            <Table.HeaderCell rowSpan={2}>Team Video</Table.HeaderCell>
                                            <Table.HeaderCell rowSpan={2}>Total</Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell>Total</Table.HeaderCell>
                                            <Table.HeaderCell>Score</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header> 
                                    <Table.Body>
                                        {leaderboard.data.map((v, k)=>
                                            <Table.Row key={k}>
                                                <Table.Cell>#{k+1}</Table.Cell>
                                                <Table.Cell>
                                                    <Button onClick={()=>{
                                                        setProgressShow(true)
                                                        loadProgressData(v)
                                                    }}>{v.Team_Name}</Button>
                                                </Table.Cell>
                                                <Table.Cell>{v.Learning_Streak}</Table.Cell>
                                                <Table.Cell>{v.Total_Buddies}</Table.Cell>
                                                <Table.Cell>{v.Learning_Buddies}</Table.Cell>
                                                <Table.Cell>{v.Team_Video}</Table.Cell>
                                                <Table.Cell>{v.Total}</Table.Cell>
                                            </Table.Row>
                                            )
                                        }
                                    </Table.Body>
                                </Table>
                                </Modal.Content>
                            </Modal>
                            <Divider hidden/>
                            </>
                            : null}
                            
                            <i>Data refresh every 5 minutes</i><br/>
                            <i>Last updated : <b>{leaderboard.last_updated !== undefined ? leaderboard.last_updated : '-'}</b></i>
                        </div>
                        : 
                        <div>
                            <Dimmer active={qsLoading}>
                                <Loader>Fetching question attempted data...</Loader>
                            </Dimmer>
                            <Container textAlign='center'>
                                <i>Get 1 point if your team is at the 10 top in this daily leaderboard</i>
                                <Divider hidden/>
                                <Form id="qs_filter_form">
                                    <Form.Group>
                                        <SemanticDatepicker clearOnSameDateClick={false} name="startDate" onChange={onChangeDate} datePickerOnly
                                        id="filter-date" size='small' value={startDate} clearable={false} 
                                        placeholder="Start Date..."/>
                                    </Form.Group>
                                </Form>
                            </Container>
                            
                            <Table basic='very' textAlign="center" id="lb_qs_table">
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Rank</Table.HeaderCell>
                                        <Table.HeaderCell>Team Name</Table.HeaderCell>
                                        <Table.HeaderCell width={4}>Questions Attempted</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {questions.topten !== undefined && questions.topten.length > 0 ?
                                     questions.topten.map((v, k)=>
                                     <Table.Row key={k}>
                                        <Table.Cell>#{k+1}</Table.Cell>
                                        <Table.Cell>{v.Team_Name}</Table.Cell>
                                        <Table.Cell>{v.Total}</Table.Cell>
                                    </Table.Row>
                                     )
                                    : <Table.Row><Table.Cell colSpan="3">NO DATA YET...</Table.Cell></Table.Row>}
                                </Table.Body>
                            </Table>

                            {questions.data !== undefined && questions.data.length > 0 ? 
                            <>
                            <Modal size="small" trigger={<Button><Icon name="external share"/> SEE FULL LIST</Button>}>
                                <Modal.Content scrolling>
                                    <Table basic='very' textAlign="center">
                                    <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Rank</Table.HeaderCell>
                                        <Table.HeaderCell>Team Name</Table.HeaderCell>
                                        <Table.HeaderCell>Total</Table.HeaderCell>
                                    </Table.Row>
                                    </Table.Header>
                                        <Table.Body>
                                            {questions.data.map((v, k)=>
                                            <Table.Row key={k}>
                                                <Table.Cell>#{k+1}</Table.Cell>
                                                <Table.Cell>{v.Team_Name}</Table.Cell>
                                                <Table.Cell>{v.Total}</Table.Cell>
                                            </Table.Row>
                                            )}
                                        </Table.Body>
                                    </Table>
                                </Modal.Content>
                            </Modal>
                            <Divider hidden/>
                            </>
                            : null}
                            <i>Data refresh every 5 minutes</i><br/>
                            <i>Last updated : <b>{leaderboard.last_updated !== undefined ? leaderboard.last_updated : '-'}</b></i>
                        </div>
                        }
                        
                    </Container>
                </Segment>
            </section>

            <section id='sponsored_by'>
                <Container text textAlign="center">
                    <p>Sponsored by</p>
                    <Image src={require('../../../../assets/images/selc/sponsor_logo_1.png').default} centered size="tiny"/>
                </Container>
            </section>

            <section id="selc_faq">
                <Container text>
                    <Accordion fluid styled id="faq_content">
                        <Accordion.Title
                        active={faqActive}
                        onClick={()=>setFaqActive(!faqActive)}
                        >
                            <Header as="h1">
                                F.A.Q
                                <Icon name='dropdown' />
                            </Header>
                        </Accordion.Title>
                        <Accordion.Content active={faqActive}>
                            {faqData.map((v, k)=>
                            <div key={k}>
                            <Header as="h3">
                                {v.header}
                                <Header.Subheader dangerouslySetInnerHTML={{__html: v.content}}/>
                            </Header>
                            {k < faqData.length-1 ? <Divider/> : null}
                            </div>
                            )}
                        </Accordion.Content>
                    </Accordion>
                </Container>
                <Divider hidden/>
                <Divider hidden/>
                <Divider hidden/>
                <Container textAlign="center" text>
                    <Header as="h1">For more information</Header>
                    <Grid id="ed_socialmedia">
                        <Grid.Column>
                        <div className="ed_social">
                            <Image src={instagram} size="tiny"/>
                            <div className="ed_social_text">
                                <p onClick={()=>window.open("https://www.instagram.com/solveeducation/", "_blank")}>@solveeducation</p>
                                <p onClick={()=>window.open("https://www.instagram.com/edthelearningbot/", "_blank")}>@edthelearningbot</p>
                            </div>
                        </div>
                        </Grid.Column>
                    </Grid>
                    <Divider hidden/>
                    <b>Sign up:</b> <a href="https://rebrand.ly/selc22" target={'_blank'} rel='noreferrer'>rebrand.ly/selc22</a>
                </Container>
            </section>

            {selectedProgress !== null ? 
            <Modal open={progressShow} onClose={()=>{
                setProgressShow(false)
                setSelectedProgress([])
                setSelectedTeam({})
                setProgressDate(defaultDate)
            }} closeIcon size="small">
                <Modal.Content>
                    <Header as="h1" textAlign="center">{selectedTeam.Team_Name}</Header>
                    <Form id="progressForm">
                        <Form.Group widths='equal'>
                            <Form.Field/>
                            <SemanticDatepicker clearOnSameDateClick={false} name="progressDate" onChange={onChangeDateProgress} datePickerOnly
                            size='small' value={progressDate} clearable={false} type="range"
                            placeholder="Progress Date..."/>
                            <Form.Field/>
                        </Form.Group>
                    </Form>
                    <Divider/>
                    <Dimmer.Dimmable dimmed as={Segment} basic>
                        <Table basic='very' textAlign='center' unstackable className="detail_lb_table">
                            <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Members</Table.HeaderCell>
                                <Table.HeaderCell>Completed Missions</Table.HeaderCell>
                                <Table.HeaderCell>Learning Buddies</Table.HeaderCell>
                            </Table.Row>
                            </Table.Header>
                            <Table.Body>
                            {selectedProgress.length > 0 ? 
                                selectedProgress.map((x, y)=>
                                <Table.Row key={y}>
                                    <Table.Cell>{x.Chat_Name !== null ? x.Chat_Name : x.User_ID}</Table.Cell>
                                    <Table.Cell>{x.Completed_Missions === null ? 0 : x.Completed_Missions}</Table.Cell>
                                    <Table.Cell>{x.Total_Buddies === null ? 0 : x.Total_Buddies}</Table.Cell>
                                </Table.Row>
                                )
                            : <Table.Row><Table.Cell colSpan="3">NO DATA YET...</Table.Cell></Table.Row>}
                            </Table.Body>
                        </Table>
                        <Dimmer active={loadingDataProgress}>
                            <Loader active>Fetching Data...</Loader>
                        </Dimmer>
                    </Dimmer.Dimmable>
                </Modal.Content>
            </Modal>
            : null}
        </div>
    )
}

export default SELCPage